import { withNamespaces } from "react-i18next";
import UserInfo from "./UserInfo";
import { twJoin } from "tailwind-merge";
import CustomDropdownListForFilter from "../EmployeeTable/CustomDropdownListForFilter";
import { useEffect, useState } from "react";
import { FilterIcon } from "../common/Icons";
import AnnoucementItem from "./AnnoucementItem";
import DateFilter from "../common/DateFilter";
import {
  useGetNoticeBoardDataWithPaginationQuery,
  useReadNoticeBoardDetailMutation,
} from "../../app/services/notice-board";
import {
  generateYear,
  useGetCategoryAllListQuery,
} from "../../app/services/category";
import { CircularProgress } from "@mui/material";
export interface INoticeBoard {}
const years: Number[] = generateYear();
const NoticeBoard = (props: any) => {
  const [noticesData, setNoticesData] = useState<any>([]);
  const [categoriesData, setCategories] = useState<any>([]);
  const [filter, setFilter] = useState<string>("");
  const [filterYear, setFilterYear] = useState<string>("");
  const { data: categories } = useGetCategoryAllListQuery();
  const { data: notices, isLoading } = useGetNoticeBoardDataWithPaginationQuery(
    {
      category: filter,
      year: filterYear,
    }
  );
  const [readNoticeBoard] = useReadNoticeBoardDetailMutation();
  useEffect(() => {
    if (notices) {
      setNoticesData(notices?.noticeBoardList);
    }
  }, [notices]);
  useEffect(() => {
    if (categories) {
      setCategories([{ id: 0, name: "All" }, ...categories?.categoryList]);
    }
  }, [categories]);
  const filterByDate = (desc: boolean) => {
    let sortedEvents = [...noticesData].sort(
      (a, b) =>
        new Date(a.expired_at).getTime() - new Date(b.expired_at).getTime()
    );
    if (desc) {
      sortedEvents = [...noticesData].sort(
        (a, b) =>
          new Date(b.expired_at).getTime() - new Date(a.expired_at).getTime()
      );
    }
    setNoticesData([...sortedEvents]);
  };
  const readNoticeBoardDetail = (id: number) => {
    readNoticeBoard(id).then((res: any) => {});
  };
  return (
    <UserInfo activeTab="notice_board">
      <DateFilter
        filter={filter}
        setFilter={setFilter}
        years={years}
        setFilterYear={setFilterYear}
        filterYear={filterYear}
        filterData={categoriesData}
        filterByDate={filterByDate}
      />
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div className="mt-4">
          <ul className="mb-[65px]">
            {noticesData?.map((anno: any, key: number) => {
              return (
                <li key={key}>
                  <AnnoucementItem
                    link={"/profile/notice_board/" + (key + 1)}
                    date={anno.published_at}
                    time={""}
                    title={anno.name}
                    description={anno.description}
                    tags={anno.categories}
                    url={anno.file_url}
                    filesize={anno.file_size}
                    readStatus={anno.readStatus}
                    id={anno.id}
                    isFavourite={anno.isFavourite}
                    readNoticeBoardDetail={() => 1}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </UserInfo>
  );
};
export default withNamespaces((props) => props.namespaces)(NoticeBoard);
