import { NavLink } from "react-router-dom";
import logo from "../img/sat-logo.svg";
import menu from "../img/solar_qr-code-line-duotone.svg";
import { useEffect } from "react";
const Sat_Landing = (props:any) => {  
  const {logoData}=props;  
  useEffect(()=>{

  },[])
  return (
    <div className="max-w-full h-[92vh] overflow-hidden">
      <div className="max-w-[640px] mx-auto h-full flex flex-col justify-center pb-[200px] relative">
        <div className="">
          <img
            src={logoData?.logo?logoData?.logo:logo}
            alt="logo"
            className="max-w-full object-contain mx-auto relative"
          />
          <div className="mt-7">
            <h1 className="heading1 font-secondary max-w-[180px] mx-auto">
              Navigating Your Path to Greatness
            </h1>
          </div>
        </div>
        <div className="mt-7 absolute bottom-[60px] left-1/2 -translate-x-1/2">
          <NavLink to={{pathname:"/profile/qr"}}>
            <div className="inline-block rounded-full p-4 shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)]">
              <img
                src={menu}
                alt="menu"
                className="max-w-[48px] object-contain mx-auto relative"
              />
            </div>
            <p className="heading2 font-secondary mt-5 text-center text-sat_darkblackv2">
              Show QR Code
            </p>
          </NavLink>
        </div>
      </div>
    </div>
  );
};
export default Sat_Landing;
