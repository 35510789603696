import home_icon from './img/home-icon.svg';
import manual_icon from './img/manual-icon.png';
import manual_icon_active from './img/manual_icon_active.png';
import calendar_icon from './img/calendar-icon.svg';
import calendar_icon_active from './img/calendar-icon-active.svg';
import profile_icon from './img/user-icon.svg';
import profile_icon_active from './img/user-icon-active.svg';
import { twJoin } from 'tailwind-merge';
import { withNamespaces } from 'react-i18next';
import { NavLink } from 'react-router-dom';
export const button_navigation=[
    {
        link:'/profile/notice_board',
        text:'home',
        icon:home_icon,
        activeicon:home_icon
    },    
    {
        link:'/profile/manuals',
        text:'manual',
        icon:manual_icon,
        activeicon:manual_icon_active
    },
    {
        link:'/profile/calendar',
        text:'calendar',
        icon:calendar_icon,
        activeicon:calendar_icon_active
    },
    {
        link:'/profile',
        text:'profile',
        icon:profile_icon,
        activeicon:profile_icon_active
    },
]
const FixedBottomNavigation=(props:any)=>{
    const {t}=props;
    return <div className='w-full flex justify-center bottom_navigation fixed left-0 bottom-0'>
        <div className='py-3 bg-white flex justify-around items-center w-full max-w-[360px]'>        
        {button_navigation?.map((nav,key)=>{
            let active='inactive';
            if((window.location.pathname==nav.link)){
                active='active'
            }
                
            //console.log('window.location.pathname ',window.location.pathname,active)
                return <NavLink to={{pathname:nav.link}} 
                className={twJoin(active,'inline-block')} 
                key={key}>
                    <img src={active=='active'?nav.activeicon:nav.icon} alt={"button icon "+key} className='mx-auto'/>
                    <p className='text-[9px] text-center text-sat_darkblackv2 mt-1'>{t(nav.text)}</p>
                </NavLink>
            })
        }
    </div>
    </div>
}
export default withNamespaces((props) => props.namespaces)(FixedBottomNavigation);