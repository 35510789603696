import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

const CustomPdfViewer = () => {
  const userStr = localStorage.getItem("userData");
  let user = null;
  if (userStr)
    user = JSON.parse(userStr);
  return (
    <div>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <Viewer defaultScale={0.5} fileUrl={window.location.search?.split('?')[1]} 
       
    />
      </Worker>
    </div>
  );
};
export default CustomPdfViewer;
