import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
// import MinimalTable from "../../Tables/MinimalTable";
import {
  ColumnDef,
  PaginationState,
  RowSelectionState,
  SortingState,
  createColumnHelper,
} from "@tanstack/react-table";
// import { PasswordCell } from "./PasswordCell";
import CopyToClipboardBtn from "./CopyToClipboardBtn";
// import { Modal, Tooltip, makeStyles } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import { Modal, Tooltip } from "@mui/material";
import { JSEncrypt } from "jsencrypt";
import Style_Css from "./MyPasswords.module.scss";
import eyeshow from "../../../login/img/eye-show.svg";
import eyehide from "../../../login/img/eye-open.svg";
import styles from "../../../login/components/LogInComponent.module.scss";
import styles1 from "./MyPasswords.module.scss";
import { MODALS } from "../../../passwords/views/PasswordList";
import { tagsColor } from "../../Announcements/AnouncementsTable";
import { baseObj } from "../../../utils/constants";
import EditableCell from "../../Tables/EditableCell";
import {
  PasswordProject,
  useCreateAuthPasswordMutation,
  useDeleteMyPwdMutation,
  // useDeletePwdMutation,
  useUpdatedPasswordMutation,
} from "../../../app/services/password";
import AssignPopup from "../../Passwords/AssignPopup";
import {
  CancelIcon,
  EditIcon,
  TableSaveIcon,
  TrashIcon,
} from "../../common/Icons";
import Table from "../../../components/Tables/Tables.module.scss";
import LayoutAdjustableTable from "../../Tables/LayoutAdjustableTable";
import ErrorPopup from "../../ErrorPopup/ErrorPopup";
import { ToastContainer, toast } from "react-toastify";
import TablePaginator from "../../Tables/TablePaginator";
import DeletePopup from "../../DeletePopup/DeletePopup";
import DOMPurify from "dompurify";
import CustomDropdownWithAddButton from "../../EmployeeTable/CustomDropdownWithAddButton";

var classNames = require("classnames");

interface IPasswordsTable {
  showAllPassword: boolean;
  setEyeClick?: any;
  eyeClick?: boolean;
  setModalRow?: Dispatch<React.SetStateAction<number>>;
  setOnePwd?: any;
  setShowAllPassword?: any;
  modalStateHandler?: any;
  isSuccess?: any;
  setIsSuccess?: any;
  id: number;
  createTagData: any;
  data: baseObj[];
  setData: React.Dispatch<React.SetStateAction<baseObj[]>>;
  isEditing?: boolean;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  filterTagData?: any;
  changePage?: any;
  setCurrentPage: any;
  currentPage: number;
  total: number;
  publicKey?: string;
  handleClickBtn: () => void;
  handleOneGetKey: (id: number) => void;
  hiddenFields: string[];
  managePassword: boolean;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  // setItemCount: Dispatch<React.SetStateAction<number>>
  createMyPassword: boolean;
  projects: PasswordProject[];
}

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    margin: 0,
    marginRight: 8,
    padding: 0,
  },
}));

const PasswordsTable: FC<IPasswordsTable> = ({
  showAllPassword,
  setSorting,
  sorting,
  setEyeClick,
  eyeClick,
  setModalRow,
  setOnePwd,
  setShowAllPassword,
  modalStateHandler,
  isSuccess,
  setIsSuccess,
  id,
  createTagData,
  data,
  setData,
  isEditing,
  setIsEdit,
  pagination,
  setPagination,
  filterTagData,
  changePage,
  setCurrentPage,
  currentPage,
  total,
  handleClickBtn,
  publicKey,
  handleOneGetKey,
  hiddenFields,
  managePassword,
  createMyPassword,
  projects
}) => {
  var encrypt: any = new JSEncrypt();
  const columnHelper = useMemo(() => createColumnHelper<any>(), []); // TODO add type definition when api binding

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [itemCount, setItemCount] = useState<number>(data.length ?? 0);
  const [createAuthPassword] = useCreateAuthPasswordMutation();
  const [openDeletePopup, setOpenDeleteopup] = useState<boolean>(false);
  const [deleteID, setDeleteID] = useState<number | 0>();
  const [successPopup, setSuccessPopup] = useState<boolean>(false);

  const classes = useStyles();
  const [cellData, setCellData] = useState<any>();
  const [isShow, setIsShow] = useState<boolean>(false);
  const [editedTagData, setEditedTagData] = useState<any>();
  const [showTagPopup, setShowTagPopup] = useState<boolean>(false);
  const [updatePassword] = useUpdatedPasswordMutation();
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  const [deletePwds] = useDeleteMyPwdMutation();
  useEffect(() => {
    if (hiddenFields.length > 0) {
      setColumnOrder(["selection", ...hiddenFields]);
    }
  }, [hiddenFields]);
  if (publicKey !== undefined) {
    encrypt.setPublicKey(publicKey);
  }

  const savePassword = useCallback(
    (id: number) => {
      setData((prev: baseObj[]) => {
        return prev.map((data, index: number) => {
          if (data.id === id) {
            return {
              ...data,
              isNew: false,
            };
          }
          return data;
        });
      });
    },
    [setData]
  );

  const cancelRow = (id: number) => {
    if (isEditing) {
      setIsEdit(false);
      setData((prev: baseObj[]) => {
        return prev.map((data, index: number) => {
          if (data.id === id) {
            return { ...data, isNew: false };
          }
          return data;
        });
      });
    } else {
      const filterData = data?.filter((d: any) => d?.id !== id);
      setData(filterData);
    }
  };

  const editRow = useCallback(
    (id: number) =>
      setData((prev: baseObj[]) => {
        if (document.getElementsByClassName(Table.horizontalScrollBar)[0]) {
          document.getElementsByClassName(
            Table.horizontalScrollBar
          )[0].scrollLeft = 0;
        }
        return prev.map((data, index: number) => {
          if (data.id == id) {
            return { ...data, isNew: true, password: "" };
          } else {
            return { ...data, password: "********", is_show: 0 };
          }
          return data;
        });
      }),
    [setData]
  );

  const confirmDeleteData = () => {
    let id = deleteID;
    deleteItem(id);
  };

  const deleteItem = (id?: number) => {
    if (id !== undefined) {
      deletePwds(id)
        .unwrap()
        .then((payload: any) => {
          if (payload) {
            setSuccessPopup(true);
            if (setIsSuccess) {
              setIsSuccess(false);
            }
            setMessage([payload?.message]);
            setOpenDeleteopup(false);
            toast("Delete Successfully!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              type: "success",
            });
            const filterData = data?.filter((d: any) => d?.id !== id);
            setItemCount(filterData.length);
            setData(filterData);
            setTimeout(() => {
              setSuccessPopup(false);
            }, 2000);
          }
        })
        .catch((error: any) => {
          setErrorPopup(true);
          setMessage(
            error
              ? [error.data.message]
              : ["Something went wrong!Trying again."]
          );
          setOpenDeleteopup(true);
        });
    }
  };

  const deletePwdFun = useCallback(
    (id?: number) => {
      setDeleteID(id);
      setOpenDeleteopup(true);
    },
    [setData, rowSelection]
  );

  const [isResponseSuccess, setResponseSuccess] = useState<boolean>(false);

  const checkIsValidate = (editedData: any) => {
    var msg: any = [];

    if (editedData.account === "") {
      msg.push("The account field is required!");
    }
    // if (editedData.links === "") {
    //   msg.push("The links field is required!");
    // }
    if (editedData.password === "") {
      msg.push("The password field is required!");
    }
    if (editedData.platform === "") {
      msg.push("The platform field is required!");
    }
    return msg;
  };

  const savePasswordForm = (id: number) => {
    var editedData = data.find((x) => x.id == id);
    if (editedData) {
      if (editedData.id == id) {
        var message: any = checkIsValidate(editedData);
        if (message.length > 0 && !isEditing) {
          setErrorPopup(true);
          setMessage(message);
          return;
        } else {
          savePasswordData(editedData);
          if (isResponseSuccess) {
            savePassword(id);
          }
        }
      }
    }
  };

  const savePasswordData = (data: any) => {
    const platform = data.platform ? DOMPurify.sanitize(data.platform) : "";
    const company = data.company ? DOMPurify.sanitize(data.company) : "";
    const account = data.account ? DOMPurify.sanitize(data.account) : "";
    const links = data.links ? DOMPurify.sanitize(data.links) : "";
    const pwdData =
      data.isChanged == true
        ? data.password
        : !data.password?.includes("********") && data.isChanged == false
          ? data.password
          : "";
    var encrypted = encrypt.encrypt(pwdData); //data.password

    if (data.id == 0) {
      setResponseSuccess(false);
      var hrPasswordCreated = {
        account: account,
        links: links,
        password:
          data.password != "********"
            ? data.password.length === 0
              ? null
              : encrypted
            : "",
        platform: platform,
        company: company,
        tags: data.tags?.map((t: any) => t.id),
        project_id: typeof(data.project)=="object"?data.project?.project_id:data.project
      };

      createAuthPassword(hrPasswordCreated)
        .then((payload: any) => {
          setResponseSuccess(true);
          toast("Create Successfully!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
          savePassword(data.id);
        })
        .catch((error: any) => {
          setResponseSuccess(false);

          setErrorPopup(true);
          setMessage(
            error
              ? [error?.data?.message]
              : ["Something went wrong!Trying again."]
          );
        });
    } else {
      setResponseSuccess(false);
      var hrPassword = {
        id: data.id,
        account: data.account,
        links: data.links,
        password: pwdData.length === 0 ? null : encrypted,
        company: data.company,
        platform: data.platform,
        tags: data.tags?.map((t: any) => t.id),
        project_id: typeof(data.project)=="object"?data.project?.project_id:data.project
      };

      updatePassword(hrPassword)
        .unwrap()
        .then((payload: any) => {
          setResponseSuccess(true);
          toast("Update Successfully!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
          savePassword(Number(payload.password.id));
        })
        .catch((error: any) => {
          setResponseSuccess(false);
          setErrorPopup(true);
          setMessage(
            error
              ? [error?.data?.message]
              : ["Something went wrong!Trying again."]
          );
        });
    }
  };
  // console.log("new", createMyPassword);
  // console.log("d", data);

  const columns = useMemo(() => {
    return [
      columnHelper.accessor("company", {
        header: "Company",
        cell: ({ getValue, cell }) => {
          const value = getValue();
          return (
            <div className="min-w-[140px] text-left">
              <EditableCell
                setData={setData}
                value={value}
                type="text"
                cell={cell}
              // customDesign="tooltip"
              // customDesign="tooltip"
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("project", {
        header: "Project",
        id: "project",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const dptment = getValue();
          var departView = departmentD;
          var departmentD = projects?.find((x) => x.project_id == dptment);
          if (departmentD == undefined) {
            departView = projects?.find((x) => x.project_name == dptment);
          } else {
            departView = departmentD;
          }
          if (typeof (dptment) == "object") {
            departView = dptment;
          }
          const originalValues = cell.row.original?.role?.map(
            (role: baseObj) => role.id
          );
          const isNew = cell.row.original?.isNew;
          return (
            <div
              className={classNames(
                "  text-left h-full font-normal",
                // max-w-full min-w-[180px]
                styles.customEmployeeDropdown
              )}
            >
              <EditableCell
                className="py-0"
                hasPadding={true}
                setData={setData}
                value={departView ? departView.project_name : "Select"}
                type="custom"
                cell={cell}
                customDesign=""
                CustomComponent={
                  <CustomDropdownWithAddButton
                    isShowAdd={false}
                    isShowDelete={false}
                    addNewItemData={() => 1}
                    initialValues={originalValues}
                    dataList={projects ? projects?.map((project) => {
                      return {
                        id: project.project_id,
                        name: project.project_name
                      }
                    }) : []}
                    setDataList={() => 1}
                    title="Project"
                    cell={cell}
                    data={data}
                    setData={setData}
                    setDeleteID={() => 1}
                    setOpenDeleteopup={setOpenDeleteopup}
                    setSelectedData={() => 1}
                    selectedData={departView ? departView.project_name : "Select"}
                    addTitle=""
                    isEditable={isNew}
                  />
                }
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("platform", {
        header: "Platform",
        cell: ({ getValue, cell }) => {
          const value = getValue();
          return (
            <div className="min-w-[140px] text-left">
              <EditableCell
                setData={setData}
                value={value}
                type="text"
                cell={cell}
              // customDesign="tooltip"
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("links", {
        header: "Links",
        cell: ({ getValue, cell }) => {
          const value = getValue();
          return (
            <div className="flex justify-between items-center  min-w-full max-w-full ">
              <EditableCell
                setData={setData}
                value={value}
                type="text"
                cell={cell}
                customDesign="link"
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("tags", {
        header: "Tags",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const tags = getValue();
          var tagArr = tags?.map((dep: any) => dep?.name);
          const isEditable = cell?.row?.original?.isNew;
          return (
            <>
              {!isEditable ? (
                tagArr?.length > 0 ? (
                  <div className=" overflow-hidden px-2">
                    <div className="w-[97%] overflow-hidden flex items-center">
                      {tags?.map((x: any, index: number) => {
                        var idx = index;
                        if (index >= 3) {
                          idx = index % 3;
                        }
                        return (
                          <div
                            key={index}
                            className="font-normal px-[5px] mr-[3px] rounded-[5px]"
                            style={{
                              backgroundColor: tagsColor[idx],
                              height: "auto",
                            }}
                          >
                            {x.name}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  // <Tooltip
                  //   arrow
                  //   classes={{ tooltip: classes.tooltip }}
                  //   enterTouchDelay={0}
                  //   leaveTouchDelay={5000}
                  //   placement="left"
                  //   title={
                  //     <>
                  //       <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                  //         <span className="text-[#fff] block">
                  //           {tagArr?.join(", ")}
                  //         </span>
                  //       </div>
                  //     </>
                  //   }
                  // >
                  //   <div className="max-w-[300px] overflow-hidden px-2">
                  //     <div className="w-[97%] overflow-hidden flex items-center">
                  //       {tags?.map((x: any, index: number) => {
                  //         var idx = index;
                  //         if (index >= 3) {
                  //           idx = index % 3;
                  //         }
                  //         return (
                  //           <div
                  //             key={index}
                  //             className="font-normal px-[5px] mr-[3px] rounded-[5px]"
                  //             style={{
                  //               backgroundColor: tagsColor[idx],
                  //               height: "auto",
                  //             }}
                  //           >
                  //             {x.name}
                  //           </div>
                  //         );
                  //       })}
                  //     </div>
                  //   </div>
                  // </Tooltip>
                  <span className="px-2">---</span>
                )
              ) : (
                <div className="min-w-full w-full flex justify-between items-center font-normal">
                  <EditableCell
                    customDesign="tooltip"
                    hasPadding={true}
                    setData={setData}
                    value={tagArr?.join(",")}
                    type="custom"
                    cell={cell}
                    CustomComponent={
                      <>
                        <button
                          onClick={() => {
                            setIsShow(true);
                            setCellData(cell);
                            setEditedTagData(tags);
                          }}
                          className="max-w-[200px] h-full font-poppins w-[90%] text-12 lg:text-14 px-5 overflow-hidden whitespace-nowrap text-ellipsis bg-vorpmyanmar text-black2"
                        >
                          {tagArr?.length > 0 ? (
                            tagArr?.join(", ")
                          ) : (
                            <span className="px-2">---</span>
                          )}
                        </button>
                      </>
                      // <Tooltip
                      //   arrow
                      //   classes={{ tooltip: classes.tooltip }}
                      //   enterTouchDelay={0}
                      //   leaveTouchDelay={5000}
                      //   placement="left"
                      //   title={
                      //     <>
                      //       <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                      //         <span className="text-[#fff] block">
                      //           {tagArr?.join(", ")}
                      //         </span>
                      //       </div>
                      //     </>
                      //   }
                      // >
                      //   <button
                      //     onClick={() => {
                      //       setIsShow(true);
                      //       setCellData(cell);
                      //       setEditedTagData(tags);
                      //     }}
                      //     className="max-w-[200px] h-full font-poppins w-[90%] text-12 lg:text-14 px-5 overflow-hidden whitespace-nowrap text-ellipsis bg-vorpmyanmar text-black2"
                      //   >
                      //     {tagArr?.length > 0 ? (
                      //       tagArr?.join(", ")
                      //     ) : (
                      //       <span className="px-2">---</span>
                      //     )}
                      //   </button>
                      // </Tooltip>
                    }
                  />
                </div>
              )}
            </>
          );
        },
      }),
      columnHelper.accessor("account", {
        header: "Account",
        cell: ({ getValue, cell }) => {
          const value = getValue();
          const isNew: boolean = cell?.row?.original?.isNew;
          return (
            <div className="relative flex justify-center items-center min-w-[160px] max-w-full pr-10">
              <EditableCell
                setData={setData}
                value={value}
                type="text"
                cell={cell}
                customDesign="text"
              />
              <div className={isNew ? "hidden" : "absolute right-2 "}>
                <CopyToClipboardBtn value={value} isAccount={true} />
              </div>
            </div>
          );
        },
      }),
      columnHelper.accessor("password", {
        header: "Password",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const password = getValue();
          const rowId = cell?.row?.original?.id;
          const isNew = cell?.row?.original?.isNew;

          const isShow: number = cell?.row?.original?.is_show;

          return (
            <div className="min-w-[230px]">
              {isSuccess ? (
                <div className="flex justify-between items-center text-center">
                  <p className="text-black2 font-normal lg:text-14 text-12">
                    {password}{" "}
                  </p>
                  {Number(id) === 0 && isShow ? (
                    <div
                      className={
                        isNew
                          ? "hidden"
                          : " absolute right-2 top-[60%] -translate-y-1/2"
                      }
                    >
                      <CopyToClipboardBtn value={password} isAccount={true} />
                    </div>
                  ) : (
                    <img
                      src={isShow === 0 ? eyeshow : eyehide}
                      alt="eye-open"
                      className={classNames(
                        isShow === 1 ? " pointer-events-none" : "",
                        "relative cursor-pointer w-[22px] h-[16px] object-cover"
                      )}
                      onClick={() => {
                        if (setModalRow) {
                          setModalRow(rowId);
                        }
                        handleOneGetKey(rowId);
                        setOnePwd(false);
                        setEyeClick(false);
                        setShowAllPassword(false);
                        setIsSuccess(false);
                      }}
                    />
                  )}
                </div>
              ) : (
                <div>
                  {isNew ? (
                    <EditableCell
                      setData={setData}
                      value={password}
                      type="password"
                      cell={cell}
                      customDesign="tooltip"
                    />
                  ) : (
                    <div className="flex w-full justify-between items-center">
                      <p>{"********"}</p>
                      {Number(id) === 0 ? (
                        <div className="relative">
                          <img
                            src={eyeshow}
                            alt="eye-open"
                            className={classNames(
                              " right-0 cursor-pointer w-[22px] h-[16px] object-cover"
                            )}
                            onClick={() => {
                              if (setModalRow) {
                                setModalRow(rowId);
                              }
                              handleOneGetKey(rowId);

                              setOnePwd(true);
                              modalStateHandler(MODALS.SHOW_PWD);
                              setIsSuccess(false);
                            }}
                          />
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        },
      }),
      columnHelper.display({
        header: "Action",
        // id: "actions",
        id: `${data?.find((x) => x.isNew == true) || id == 0 ? "action" : "none-actions"
          }`,
        // id: `actions`,
        cell: ({ cell }) => {
          var isHaveNew: any = data?.find((x) => x.isNew == true);
          var disabledClass = isHaveNew ? "opacity-50 pointer-events-none" : "";
          const isNew: boolean = cell?.row?.original?.isNew;
          const is_own: number = cell?.row?.original?.is_own;
          return (
            <div
              className={classNames(
                // stylesEmp.actionedBtn,
                "flex justify-center h-full items-center min-w-max max-w-[98px]"
              )}
            >
              {id == 0 ? isNew ? (
                <>
                  <button
                    type="button"
                    onClick={() =>
                      savePasswordForm(Number(cell?.row?.original?.id))
                    }
                    className="w-6 h-6 flex justify-center items-center bg-vorpgreen rounded-md"
                  >
                    <TableSaveIcon width={18} height={18} />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      cancelRow(Number(cell?.row?.original?.id));
                      // setIsCreated(false)
                    }}
                    className={classNames(
                      styles.customCloseIcon,
                      "w-8 h-8 ml-2 mr-2 flex justify-center items-center group rounded-md  bg-[#71717a] hover:bg-[#71717a] transition-all duration-300"
                    )}
                  >
                    <CancelIcon width={18} height={18} />
                  </button>
                </>
              ) : (
                <>
                  {Number(id) === 0 && is_own === 1 ? (
                    <>
                      <button
                        disabled={isHaveNew ? true : false}
                        type="button"
                        onClick={() => {
                          editRow(cell?.row?.original?.id);
                          if (setIsEdit) {
                            setIsEdit(true);
                            setIsSuccess(false);
                          }
                        }}
                        className={classNames(
                          disabledClass,
                          "w-6 h-[35px] mr-2 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
                        )}
                      >
                        <EditIcon width={18} height={18} />
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          deletePwdFun(Number(cell?.row?.original?.id));
                        }}
                        className={classNames(
                          disabledClass,
                          "w-6 h-6 flex justify-center items-center group rounded-md bg-redPink hover:bg-redPinkLight transition-all duration-300"
                        )}
                      >
                        <TrashIcon width={18} height={18} />
                      </button>
                    </>
                  ) : (
                    <span>---</span>
                  )}
                </>
              ) : <span>---</span>}
            </div>
          );
        },
      }),

      // columnHelper.display({
      //   // header: "Action",
      //   header: () => (
      //     <span className="text-center lg:text-[13px] text-12 leading-5 tracking-[0.03em] block w-full">
      //       Action
      //     </span>
      //   ),
      //   // id: `${
      //   //   data?.find((x) => x.isNew == true) ? "actions" : "none-actions"
      //   // }`,
      //   id: "actions",
      //   cell: ({ cell }) => {
      //     var isHaveNew = data?.find((x) => x.isNew == true);
      //     var disabledClass = isHaveNew ? "opacity-50 pointer-events-none" : "";
      //     const isNew: boolean = cell?.row?.original?.isNew;
      //     const is_own: number = cell?.row?.original?.is_own;
      //     return (
      //       <div
      //         className={classNames(
      //           // stylesEmp.actionedBtn,
      //           "flex justify-center h-full items-center min-w-max max-w-[98px]"
      //         )}
      //       >
      //         {isNew ? (
      //           <>
      //             <button
      //               type="button"
      //               onClick={() =>
      //                 savePasswordForm(Number(cell?.row?.original?.id))
      //               }
      //               className="w-8 h-8 flex justify-center items-center bg-vorpgreen rounded-md"
      //             >
      //               <TableSaveIcon width={18} height={18} />
      //             </button>
      //             <button
      //               type="button"
      //               onClick={() => {
      //                 cancelRow(Number(cell?.row?.original?.id));
      //                 // setIsCreated(false)
      //               }}
      //               className={classNames(
      //                 styles.customCloseIcon,
      //                 "w-8 h-8 ml-2 mr-2 flex justify-center items-center group rounded-md  bg-[#71717a] hover:bg-[#71717a] transition-all duration-300"
      //               )}
      //             >
      //               <CancelIcon width={18} height={18} />
      //             </button>
      //           </>
      //         ) : (
      //           <>
      //             {Number(id) === 0 && is_own === 1 ? (
      //               <>
      //                 <button
      //                   disabled={isHaveNew ? true : false}
      //                   type="button"
      //                   onClick={() => {
      //                     editRow(cell?.row?.original?.id);
      //                     if (setIsEdit) {
      //                       setIsEdit(true);
      //                       setIsSuccess(false);
      //                     }
      //                   }}
      //                   className={classNames(
      //                     disabledClass,
      //                     "w-8 h-[35px] mr-2 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
      //                   )}
      //                 >
      //                   <EditIcon width={18} height={18} />
      //                 </button>
      //                 <button
      //                   type="button"
      //                   onClick={() => {
      //                     deletePwdFun(Number(cell?.row?.original?.id));
      //                   }}
      //                   className={classNames(
      //                     disabledClass,
      //                     "w-8 h-8 flex justify-center items-center group rounded-md bg-redPink hover:bg-redPinkLight transition-all duration-300"
      //                   )}
      //                 >
      //                   <TrashIcon width={18} height={18} />
      //                 </button>
      //               </>
      //             ) : (
      //               <span>---</span>
      //             )}
      //           </>
      //         )}
      //       </div>
      //     );
      //   },
      // }),
    ];
  }, [columnHelper, data]);


  const [columnOrder, setColumnOrder] = useState<string[]>(
    columns.map((column: ColumnDef<any, any>) => column.id as string)
  );

  // document.querySelectorAll("th,td").forEach((x) => {
  //   if (x.id.includes("none")) {
  //     x.classList.add("hidden");
  //   }
  //   var div = document.getElementById(x.id);
  //   if (div) {
  //     div.style.setProperty("left", "");
  //   }
  //   if (x.id.includes("actions")) {
  //     x.classList.add("sticky");
  //     x.classList.remove("relative");
  //     x.classList.add("right-0");
  //     x.classList.remove("left-0");
  //   }
  // });

  const visibleColumns: { [key: string]: boolean } = {};

  hiddenFields?.map((x) => {
    visibleColumns[x] = true;
  });

  if (managePassword) {
    visibleColumns["assignUsers"] = true;
  } else {
    visibleColumns["assignUsers"] = false;
  }

  // if (hiddenFields.length > 0) {
  //   columns.map((x: any) => {
  //     if (x.id == "actions" || x.id == "lasted" || x.id == "selection") {
  //       visibleColumns[x.id ? x.id : ""] = true;
  //     } else if (x.id == "uniqeID") {
  //       visibleColumns[x.id ? x.id : ""] = false;
  //     } else if (x.id?.includes("none")) {
  //       visibleColumns[x.id ? x.id : ""] = true;
  //     } else {
  //       var isHidden = hiddenFields.find((h) => h == x.id);
  //       if (isHidden) {
  //         visibleColumns[x.id ? x.id : ""] = true;
  //       } else {
  //         visibleColumns[x.id ? x.id : ""] = false;
  //       }
  //     }
  //   });
  // }

  return (
    <div className={classNames(styles1.passwordTable, "relative")}>
      <DeletePopup
        show={openDeletePopup}
        setShow={setOpenDeleteopup}
        confirmDeleteData={confirmDeleteData}
        descText="Are You Sure To Delete"
      />
      <div className={Style_Css.personalLeaveTable}>
        <LayoutAdjustableTable
          columns={columns ?? []}
          data={data ?? []}
          sorting={sorting}
          setSortingState={setSorting}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          columnOrder={columnOrder}
          setColumnOrder={setColumnOrder}
          unDraggableFields={["actions"]}
          theaderColor="text-graydark"
          // isLayoutControlOpen={isLayoutControlOpen}
          // setIsLayoutControlOpen={setIsLayoutControlOpen}
          label="User List"
          setItemCount={setItemCount}
          pagination={pagination}
          setPagination={setPagination}
          noPaddingCells={['project']}
        // globalFilter={globalSearch}
        // setGlobalFilter={setGlobalSearch}
        // columnFilters={columnFilters}
        //  setColumnFilters={setColumnFilters}
        //  localModuleId={localModuleId}
        //  visArray={visArray}
        //   setCols={setCols}
        //  setIsTemplate={setIsTemplate}
        //   hideColumn={visibleColumns}
        />
      </div>
      <Modal
        open={isShow}
        style={{
          background: "rgb(0 0 0 / 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={() => setIsShow(false)}
        aria-labelledby="Password Add Modal"
        aria-describedby="Add New Password Form Pop up"
      >
        <div className=" max-w-[558px] w-full m-auto">
          <AssignPopup
            createTagData={createTagData}
            editRowData={editedTagData}
            showTagPopup={showTagPopup}
            setIsOpen={setIsShow}
            modalType="assign"
            options={filterTagData}
            cell={cellData}
            setTags={() => 1}
            data={data}
            setData={setData}
          />
        </div>
      </Modal>
      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      <div className="pt-6">
        <TablePaginator
          total={itemCount}
          pagination={pagination}
          setPagination={setPagination}
          itemCount={total}
          changePage={changePage}
          setCurrentPage={setCurrentPage}
          currentPageDefault={currentPage}
        />
      </div>
    </div>
  );
};

export default PasswordsTable;
