import { endpointUrl } from '../app/services/api';
import { ID } from '../types/utils';

export const courseRootPath = '/courses';

export const dashboardPath = '/dashboard';

export const courseSection = '/courses/*';

export const lessonRootPath = '/lessons';

export const quizRootPath = '/quizzes';

export const assignmentRootPath = '/assignments';

export const instructorRootPath = `/instructor`;
export const instructorSection = `/instructor/*`;
export const instructorCoursesPath = `/instructor${courseRootPath}`;
export const instructorDashboardPath = `/instructor${dashboardPath}`;
export const instructorCourseAddPath = `/courses/add`;
export const completeInstructorCourseAddPath = `/instructor/courses/add`;

export const courseListPath = '/';
export const courseDetailsPath = `/:id`;

export const courseLessonDetailsPath = '/:id/lessons/:lessonId';
export const courseQuizDetailsPath = '/:id/quizzes/:quizId';
export const courseAssignmentDetailsPath = '/:id/assignments/:assignmentId';

export const courseAddPath = '/add';
export const courseAddUrl = courseAddPath;

export const coursePath = (id: ID) => `${courseRootPath}/${id}`;

export const instructorCourseDetailsPath = (id: ID) =>
  `${instructorRootPath}${courseRootPath}/${id}`;

export const lessonDetailsPath = (id: ID, courseID: ID) =>
  `${courseRootPath}/${courseID}${lessonRootPath}/${id}`;

export const quizDetailsPath = (id: ID, courseID: ID) => {
  return `${courseRootPath}/${courseID}${quizRootPath}/${id}`;
};

export const assignmentDetailsPath = (id: ID, courseID: ID) => {
  return `${courseRootPath}/${courseID}${assignmentRootPath}/${id}`;
};

export type CourseParams = {
  id: string;
  lessonId: string;
  quizId: string;
  assignmentId: string;
};
