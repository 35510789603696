import { Modal } from "@mui/material";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import download_icon from "./img/pdf_download.svg";
import pdf_icon from "./img/pdf-icon.svg";
import { twJoin } from "tailwind-merge";
import { useGetCMSDataQuery } from "../../app/services/cms";
import { withNamespaces } from "react-i18next";
interface INoticePopup {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  data: any;
}
const annoucement_tags = [
  {
    id: 1,
    name: "Company",
  },
  {
    id: 2,
    name: "Announcement",
  },
  {
    id: 3,
    name: "Employee",
  },
];
const DisclaimerPopup = (props:any) => {
  
  const {isOpen,setIsOpen,t,disclaimer}=props;  
  const [checkAcknowledge, setCheckAcknowledge] = useState<boolean>(false);
  
  return (
    <Modal
      className="notice_popup"
      open={isOpen}
      style={{
        background: "rgb(0 0 0 / 0.3)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          setIsOpen(false);
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modalContent rounded-xl annoucement-modal overflow-y-auto max-h-[90vh] w-full focus-visible:outline-none">
        <div className="bg-white lg:max-w-[360px] max-w-[85%] mx-auto rounded-2xl overflow-hidden">
          <div className="bg-sat_orange p-4 rounded-tl-2xl rounded-tr-2xl">
            <h1 className="heading2 font-bold text-white text-center">
              {disclaimer?.title}
            </h1>
          </div>
          <div className="bg-white p-4 text-body-md text-sat_darkgray font-secondary">
            <div className="max-h-[300px] overflow-auto custom_scrollbar_popup">
              <p
                className="text-body-md text-sat_darkgray"
                dangerouslySetInnerHTML={{ __html: disclaimer?.description }}
              ></p>
            </div>

            <div className="mt-4">
              <label
                htmlFor="acknowledge"
                className="relative flex items-center"
              >
                <div className="relative flex">
                  <input
                    onChange={(e) => {
                      setCheckAcknowledge(e.target.checked);
                    }}
                    type="checkbox"
                    id="acknowledge"
                    className="mr-2 w-5 h-5 bg-sat_smoke rounded-4 checked:before:bg-sat_primary checked:after:w-3 checked:after:h-[0.45rem] checked:after:border-l-2 checked:after:border-l-white checked:after:border-b-2 checked:after:border-b-white checked:after:content-normal checked:before:content-normal checked:before:absolute checked:after:absolute checked:after:rotate-[-45deg] checked:before:left-0 checked:before:top-0 checked:before:rounded-4 checked:before:w-5 checked:before:h-5 checked:after:left-[35%] checked:after:top-[40%] checked:after:-translate-x-1/2 checked:after:-translate-y-1/2"
                  />
                </div>
                <span className="text-body-md">
                  {t('agreeText')}
                </span>
              </label>
            </div>
            <div className="mt-4">
              <button
                onClick={() => {
                  setIsOpen(false);
                  window.localStorage.setItem("disclaimer", "true");
                }}
                className={twJoin(
                  checkAcknowledge == true
                    ? "opacity-100"
                    : "opacity-50 pointer-events-none",
                  "w-full py-3 px-5 bg-sat_primary text-white text-body-md text-center rounded-lg"
                )}
              >
                Agree
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default withNamespaces((props) => props.namespaces)(DisclaimerPopup);
