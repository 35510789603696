import { twJoin } from "tailwind-merge";
import CustomDropdownListForFilter from "../EmployeeTable/CustomDropdownListForFilter";
import { FilterIcon } from "./Icons";
import { FC, useState } from "react";
export interface IDateFilter{
    filterData:any,
    filter:string,
    setFilter:any;
    filterYear:string;
    setFilterYear: React.Dispatch<React.SetStateAction<string>>;
    years:any,
    filterByDate?:any;
}
const DateFilter:FC<IDateFilter> = ({
    filterData,
    filter,
    setFilter,
    filterYear,
    setFilterYear,
    years,
    filterByDate
}) => {
  const [isDesc,setIsDesc]=useState<boolean>(false);
  return (
    <div className="flex justify-between items-center mt-5">
      <div className="flex items-center">
        <div className={twJoin("flex items-center mr-[10px] w-auto my-1")}>
          <CustomDropdownListForFilter
            placeholder="Filter"
            state={filter}
            setState={setFilter}
            dropdownItems={
              filterData
                ? [
                    ...filterData.map((st: any) => ({
                      value: st.id,
                      label: st.name,
                    })),
                  ]
                : []
            }
            textAlign="text-left"
            classNameCus="max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full"
          />
        </div>
        <div className={twJoin("flex items-center mr-[10px] w-auto my-1")}>
          <CustomDropdownListForFilter
            placeholder="Year"
            state={filterYear}
            setState={setFilterYear}
            dropdownItems={
              years
                ? [
                    ...years.map((st: any) => ({
                      value: st.toString(),
                      label: st.toString(),
                    })),
                  ]
                : []
            }
            textAlign="text-left"
            classNameCus="max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full"
          />
        </div>
      </div>
      <div>
        <button onClick={()=>{
          setIsDesc(!isDesc)
          filterByDate(isDesc)}}>
          <FilterIcon />
        </button>
      </div>
    </div>
  );
};
export default DateFilter;
