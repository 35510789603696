import authHeader from "./auth-header";
import { api, endpointUrl } from "./api";
import { Employee } from "./employee";
import { EmployeeListData } from "../../components/EmployeeTable/EmployeeTable";
import { baseObj } from "../../utils/constants";
import { redirectToLogin } from "./annoucement-sheet";

export type PermissionResponse = {
  data: EmployeeList[];
};

export type EmployeeList = {
  id: number;
  name: string;
  icon: string;
  email: string;
};
export type createdBy = {
  name: string;
  createdAt: Date;
};
export type AssignData = {
  id: number;
  name: string;
  email: string;
  profileImage: string;
};

export type PasswordResponse = {
  module_id: number;
  passwords: Password;
  passwordUserList: PasswordUserList[];
  // show_password: boolean
};
export type PasswordFilterResponse = {
  projects: PasswordProject[],
  users: PasswordUser[],
  departments: PasswordKeyValue[],
  tagsData: PasswordKeyValue[],
  public_key: string,
};
export type PasswordProject = {
  project_id: number,
  project_code: string,
  project_name: string
}
export type PasswordUser = {
  "id": number,
  "name": string,
  "email": string,
  "profile_image": string,
  "photo": string,
}
export type PasswordKeyValue = {
  "id": number,
  "name": string,
}
export type PasswordResponseMutation = {
  data: DD;
};

export type DD = {
  data: Datas;
};

export type Datas = {
  module_id: number;
  passwords: Password;
  passwordUserList: PasswordUserList[];
  // show_password: boolean
};

export type Password = {
  data: Pwd[];
  total: number;
  limit?: number | string;
  page?: number | string;
  tag?: number | string;
  share_to?: number | string;
  search?: number | string;
  created_by?: string;
  sort?: string;
  sortBy?: any;
  project_id:string;
};

export type Pwd = {
  id: number;
  platform: string;
  account: string;
  password: string;
  links: string;
  department: string;
  createdBy: createdBy;
  assignUsers: AssignData[];
};

export type PasswordData = {
  platform: string;
  account: string;
  password: string;
  links: string;
  department: string;
  createdBy: createdBy;
  assignUsers: AssignData[];
  tags?: number[];
  id?: number;
};
export type PasswordDataHR = {
  platform: string;
  company: string;
  account: string;
  password: string;
  links: string;
  tags: number[];
  user_ids?: number[];
  project_id?:number;
};
export type DepartmentResponse = { departmentList: KeyValueLabel[] };

export type KeyValueLabel = {
  id: number;
  name: string;
  label?: string;
};

export type PasswordUserList = {
  id: number;
  name: string;
  email: string;
  profile_image: string;
  photo: string;
};
export type AssignDataList = {
  password_ids: number[];
  user_ids: number[];
};
export type AssignDataSingle = {
  password_ids: number;
  user_ids: number[];
  employees?: number[];
  ids?: number;
};

export interface IOnePasswordResponse {
  platform: string;
  account: string;
  password: string;
  links: string;
  createdBy: createdBy;
  assignUsers: AssignData[];
  tags?: number[];
  id?: number;
}

export type ResponseOnePassword = {
  password: IOnePasswordResponse;
};
export interface IOnePasswordRequest {
  id: number;
}

export type ResponseKeyPassword = {
  publicKey: string;
};

export type ResponseIsPwdKey = {
  can_see: boolean;
};

export type RequestUserList = {
  search?: string;
  department_id: number | string;
};
export interface assignUsers {
  id: number;
  name: string;
  email: string;
  profile_image: string;
  photo: string;
  label?: string | number;
}
export type ResponseUserList = {
  users: assignUsers[];
};
export type ITagsData = {
  id: number,
  name: string,
  flag: string
}
export type PassowrdInformation = {
  public_key: string,
  tagsData: ITagsData[],
  projects:PasswordProject[]
}
export const passwordApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEmployeeList: build.query<EmployeeListData[], void>({
      query: () => ({ url: `employeeListData`, headers: authHeader() }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: any) => {
        return res?.employeeList;
      },
      // extraOptions: { maxRetries: 2}
    }),
    getPasswordInformation: build.query<PassowrdInformation, void>({
      query: () => ({ url: `profile/password-information`, headers: authHeader() }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: any) => {
        return res;
      },
      // extraOptions: { maxRetries: 2}
    }),
    getPasswordData: build.mutation<
      PasswordResponseMutation,
      Partial<Password>
    >({
      query: ({ limit }) => ({
        url: `${endpointUrl}passwordsListData?limit=${limit}`,
        headers: authHeader(),
        method: "GET",
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      invalidatesTags: [{ type: "PasswordList", id: "LIST" }],
    }),

    getPasswords: build.query<PasswordResponse, Partial<Password>>({
      query: ({ page, limit, tag, share_to, search, sort, sortBy, created_by,project_id }) =>
      // console.log("s_name", sortBy),
      ({
        url: `${endpointUrl}passwordsListData?page=${page}&limit=${limit}&tag=${tag}&share_to=${share_to}&search=${search}&sort=${sort}&created_by=${created_by}&project_id=${project_id}`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "PasswordList" as const, id: "LIST" }],
    }),
    getPasswordsFilterData: build.query<PasswordFilterResponse, void>({
      query: () =>
      // console.log("s_name", sortBy),
      ({
        url: `${endpointUrl}passwords-filter-data`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    getSeePassword: build.mutation<PasswordResponse, any>({
      query: (data) => ({
        url: `${endpointUrl}seeAllPasswords?page=${data?.page}&limit=${data?.limit}&tag=${data?.tag}&share_to=${data?.share_to}&search=${data?.search}&created_by=${data?.created_by}`,
        headers: authHeader(),
        method: "POST",
        body: data,
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: any) => {
        return res;
      },
      invalidatesTags: [{ type: "PasswordList", id: "LIST" }],
    }),

    getSeeMyPassword: build.mutation<PasswordResponse, any>({
      query: (data) => ({
        url: `${endpointUrl}profile/passwords/see-all?page=${data?.page}&limit=${data?.limit}&search=${data?.search}`,
        headers: authHeader(),
        method: "POST",
        body: data,
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: any) => {
        // console.log("r", res)
        return res;
      },
      invalidatesTags: [{ type: "MyPasswordList", id: "LIST" }],
    }),

    getOnePassword: build.query<ResponseOnePassword, IOnePasswordRequest>({
      query: ({ id }) => ({
        url: `${endpointUrl}get-password/${id}`,
        headers: authHeader(),
        method: "GET",
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "OnePasswordList" as const, id: "LIST" }],
    }),

    getPasswordKey: build.query<ResponseKeyPassword, void>({
      query: () => ({
        url: `${endpointUrl}system-public-key`,
        headers: authHeader(),
        method: "GET",
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "KeyPasswordList" as const, id: "LIST" }],
    }),

    getIsPwdKey: build.mutation<ResponseIsPwdKey, void>({
      query: () => ({
        url: `${endpointUrl}can-see-password`,
        headers: authHeader(),
        method: "GET",
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      invalidatesTags: [{ type: "IsKeyPwd", id: "LIST" }],
    }),

    getSeeOnePassword: build.mutation<PasswordResponse, any>({
      query: (data) => ({
        url: `${endpointUrl}passwords/${data?.id}/see`,
        headers: authHeader(),
        method: "POST",
        body: data,
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: any) => {
        return res;
      },
      invalidatesTags: [{ type: "PasswordList", id: "LIST" }],
    }),

    getDepartments: build.query<DepartmentResponse, void>({
      query: () => ({
        url: `${endpointUrl}departments`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: any) => {
        return res;
      },
    }),
    //get assign pwdd

    getUserListAssign: build.query<ResponseUserList, RequestUserList>({
      query: ({ search, department_id }) => ({
        url: `${endpointUrl}user-list?department_id=${department_id}&search=${search}`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: any) => {
        return res;
      },
      providesTags: () => [{ type: "UserAssign" }],
    }),

    createPassword: build.mutation<PasswordData, Partial<PasswordData>>({
      query: (data) => ({
        url: `${endpointUrl}passwords`,
        headers: authHeader(),
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "PasswordList", id: "LIST" }],
    }),

    createHRPassword: build.mutation<PasswordData, Partial<PasswordDataHR>>({
      query: (data) => ({
        url: `${endpointUrl}passwords`,
        headers: authHeader(),
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "PasswordList", id: "LIST" }],
    }),

    createAuthPassword: build.mutation<PasswordData, Partial<PasswordDataHR>>({
      query: (data) => ({
        url: `${endpointUrl}profile/passwords`,
        headers: authHeader(),
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "MyPasswordList", id: "LIST" }],
    }),

    updatedPassword: build.mutation<PasswordData, Partial<PasswordData>>({
      query: (data) => ({
        url: `${endpointUrl}profile/passwords/${data.id}`,
        headers: authHeader(),
        method: "PUT",
        body: data,
      }),

      invalidatesTags: [{ type: "PasswordList", id: "LIST" }],
    }),

    deletePwd: build.mutation<{ id: number }, number>({
      query: (id) => ({
        url: `${endpointUrl}deletePassword/${id}`,
        method: "DELETE",
        headers: authHeader(),
      }),
      invalidatesTags: ["PasswordList"],
    }),

    deleteMyPwd: build.mutation<{ id: number }, number>({
      query: (id) => ({
        url: `${endpointUrl}profile/passwords/${id}`,
        method: "DELETE",
        headers: authHeader(),
      }),
      // invalidatesTags: ["PasswordList"],
      invalidatesTags: [{ type: "MyPasswordList", id: "LIST" }],
    }),

    multiDeletePassword: build.mutation<
      { success: boolean; ids: number[] },
      number[]
    >({
      query: (ids) => ({
        url: `${endpointUrl}deletePasswords`,
        headers: authHeader(),
        method: "DELETE",
        body: { ids },
      }),
      invalidatesTags: (Password) => [{ type: "PasswordList" }],
    }),

    assignPasswords: build.mutation<
      { success: boolean; data: AssignDataList },
      number
    >({
      query: (data) => ({
        url: `${endpointUrl}assignPasswords`,
        headers: authHeader(),
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, id) => [
        { type: "PasswordList", id: "LIST" },
      ],
    }),

    assignPassword: build.mutation<Password, Partial<AssignDataSingle>>({
      query: (body) => ({
        url: `${endpointUrl}assignPassword/${body.password_ids}`,
        method: "POST",
        body,
        headers: authHeader(),
      }),
      invalidatesTags: (permissions) => [{ type: "PasswordList", id: "LIST" }],
    }),
  }),
});

export const {
  useGetEmployeeListQuery,
  useGetPasswordsQuery,
  useGetPasswordKeyQuery,
  useGetUserListAssignQuery,
  useGetOnePasswordQuery,
  useGetDepartmentsQuery,
  useCreatePasswordMutation,
  useGetSeePasswordMutation,
  useGetSeeOnePasswordMutation,
  useGetPasswordDataMutation,
  useDeletePwdMutation,
  useDeleteMyPwdMutation,
  useMultiDeletePasswordMutation,
  useCreateHRPasswordMutation,
  useCreateAuthPasswordMutation,
  useAssignPasswordsMutation,
  useAssignPasswordMutation,
  useUpdatedPasswordMutation,
  useGetSeeMyPasswordMutation,
  useGetIsPwdKeyMutation,
  useGetPasswordInformationQuery,
  useGetPasswordsFilterDataQuery

} = passwordApi;

export const {
  endpoints: {
    getEmployeeList,
    getPasswords,
    getDepartments,
    createPassword,
    multiDeletePassword,
    deletePwd,
    deleteMyPwd,
    createHRPassword,
    assignPasswords,
    assignPassword,
    getSeePassword,
    updatedPassword,
    getSeeOnePassword,
    createAuthPassword,
    getSeeMyPassword,
    getOnePassword,
    getPasswordKey,
    getPasswordData,
    getIsPwdKey,
    getUserListAssign,
    getPasswordInformation,
    getPasswordsFilterData
  },
} = passwordApi;
