import { twJoin, twMerge } from "tailwind-merge";
import bg from "../img/sat-bg.png";
import logo from "../img/sat-logo.svg";
import styles from "./LogInComponent.module.scss";
import { switchEyeHandler } from "../../reset-password/components";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLoginMutation } from "../../app/services/login";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
// import { requestPermission } from "../../app/services/firebase";
import { checkAllPersmissions } from "../../auth/components/SectionRoute";
import { useNavigate } from "react-router-dom";
import DisclaimerPopup from "../../components/NoticePopup/DisclaimerPopup";
import { requestPermission } from "../../app/services/firebase";
import { useGetCMSDataQuery, useGetLogoDataQuery } from "../../app/services/cms";
const LogInComponent = (props:any) => {
  const {logoData}=props;

  const { data: cmsData, isLoading } = useGetCMSDataQuery("disclaimer");  
  const navigate = useNavigate();  
  const cookie = window.localStorage.getItem("disclaimer") ? false : true;  
  const [customLoading, setCustomLoading] = useState<boolean>(false);
  const [ssoLoading, setSSOLoading] = useState<boolean>(false);
  const [open,setIsOpen]=useState<boolean>(false);
  const [disclaimer, setDisclaimer] = useState<any>();
  const [loginError, setLoginError] = useState("");
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    isRemember: false,
  });
  useEffect(() => {
    if (cmsData) {
      setDisclaimer(cmsData.cms);
      setIsOpen(cookie);
    }
  }, [cmsData]);
  useEffect(() => {
    const username = localStorage.getItem("rememberedUsername");
    const password = localStorage.getItem("rememberedPassword");
    if (username) {
      setFormData({
        username: username,
        password: password ?? "",
        isRemember: false,
      });
    }
  }, []);
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name == "isRemember" ? (value == "on" ? true : false) : value,
    }));
  };
  const login = (event: any) => {
    if (formData.isRemember == true) {
      localStorage.setItem("rememberedUsername", formData.username);
      localStorage.setItem("rememberedPassword", formData.password);
    } else {
      localStorage.removeItem("rememberedUsername");
      localStorage.removeItem("rememberedPassword");
    }
    handleSubmit(formData);
  };

  const handleSubmit = (formData:any) => {
    setCustomLoading(true);
    axios
      .post(endpointUrl + "admin/login", {
        email: formData.username,
        password: formData.password ?? "",
        
      })
      .then(function (response) {
        localStorage.clear();
        var data = response.data;
        var userData = JSON.stringify(data);

        localStorage.setItem("userData", userData);

        if (response.data.privateKey) {
          const data = { privateKey: response.data.privateKey };
          const request = indexedDB.open("myDatabase", 1);

          request.onsuccess = (event) => {
            const db = (event.target as IDBOpenDBRequest).result;
            const transaction = db.transaction(["myStore"], "readwrite");
            const store = transaction.objectStore("myStore");
            store.clear();
            const addRequest = store.add(data);

            addRequest.onsuccess = () => {
              //console.log('Data added successfully');
            };

            addRequest.onerror = (event) => {
              console.error(
                "Error adding data",
                (event.target as IDBOpenDBRequest).error
              );
            };
          };
        }
         requestPermission();
        if (response?.data?.status == true) {
          if (
            data?.permissions?.find(
              (x: any) => x.name == "View_Admin_Dashboard"
            )
          ) {
            // console.log("dashboard", data?.permissions.find((x: any) => x.name == "View_Admin_Dashboard"))
            navigate("/");
          } else if (
            data?.permissions?.find((x: any) => x.name == "View_My_Profile")
          ) {
            navigate("/profile");
          } else {
            checkAllPersmissions(navigate, userData);
          }
        } else {
          setLoginError(response?.data?.message);
          setCustomLoading(false);
        }

        setCustomLoading(false);
      })
      .catch((reason: any) => {
        setCustomLoading(false);
        if (reason.response) {
          var error = reason.response.data.message;
          console.log("error ",error)
          // var error=reason.response.data.output.error.ErrorMessage;
          setLoginError(error);
          setCustomLoading(false);
        } else if (reason.message) {
          setCustomLoading(false);
          setLoginError(reason.message);
        }
      });
  };
  return (
    <div className="max-w-full">
      <div className="max-w-[390px] mx-auto">
        <div>
          <div className="relative">
            <img
              src={logoData?.banner?logoData?.banner:bg}
              alt="bg"
              className={twJoin(
                styles.bg_image,
                "w-full max-w-full object-cover aspect-square object-bottom"
              )}
            />
            <img
              src={logoData?.logo?logoData?.logo:logo}
              alt="logo"
              className="max-w-full object-contain mx-auto absolute left-1/2 bottom-[-2rem] -translate-x-1/2"
            />
          </div>
          <div className="px-9 mt-[68px] pb-[30px]">
            <form action="" className="w-full">
              <div className="mb-3">
                <input
                  onChange={handleChange}
                  type="text"
                  name="username"
                  placeholder="Username"
                  className="text-body-md w-full font-secondary px-3 py-[10px] bg-sat_lightgray border border-sat_lightgray rounded-lg focus-within:outline-none focus:outline-none focus-visible:outline-none placeholder:text-sat_grayHint"
                />
              </div>
              <div className="password-div mb-5 flex justify-between items-center overflow-hidden bg-sat_lightgray border border-sat_lightgray rounded-lg relative">
                <input
                  onChange={handleChange}
                  type="password"
                  name="password"
                  placeholder="Password"
                  className="text-body-md w-full font-secondary px-3 py-[10px] focus-within:outline-none focus:outline-none focus-visible:outline-none placeholder:text-sat_grayHint"
                />
                <button
                  onClick={(event) => switchEyeHandler(event)}
                  type="button"
                  className="text-body-sm text-sat_darkgray font-secondary px-3 absolute right-0 top-1/2 -translate-y-1/2"
                >
                  Show
                </button>
              </div>
              <div className="mb-5">
                <label
                  htmlFor="remember_me"
                  className="text-body-md text-sat_darkgray font-secondary flex items-center"
                >
                  <input
                    onChange={handleChange}
                    type="checkbox"
                    id="remember_me"
                    name="isRemember"
                    className="overflow-hidden border border-sat_primary relative mr-2 w-6 h-6 accent-white rounded-4 before:rounded-4 before:content-normal before:block before:w-6 before:h-6 before:bg-transparent checked:before:bg-sat_primary checked:after:content-normal checked:after:block checked:after:w-4 checked:after:h-2 checked:after:bg-transparent checked:after:border-l-[3px] checked:after:border-l-white checked:after:border-b-[3px] checked:after:border-b-white checked:after:absolute checked:after:left-1/2 checked:after:top-[42%] checked:after:-translate-x-1/2 checked:after:-translate-y-1/2 checked:after:rotate-[-45deg] checked:after:rounded-[1px]"
                  />
                  <span>Remember Me</span>
                </label>
              </div>
              <p className="text-body-sm font-normal text-center text-sat_primary my-2">{loginError}</p>
              <div>
                <button
                  type="button"
                  onClick={(event) => login(event)}
                  className={twJoin(customLoading?'opacity-50 pointer-events-none':'',"rounded-lg py-3 px-3 text-white text-center text-body-md font-bold font-secondary w-full bg-sat_primary")}
                >
                  Login
                </button>
              </div>
            </form>
            <div className="mt-5">
              <a
                href="/forgetpassword"
                className="underline text-sat_primary text-body-sm font-secondary font-normal w-full block text-center"
              >
                Forgot Password?
              </a>
            </div>
            <p className="text-center mt-8 text-caption text-sat_darkblackv2 font-secondary font-normal">
              v1.0.1.1
            </p>
          </div>
        </div>
      </div>
      <DisclaimerPopup isOpen={open} setIsOpen={setIsOpen} disclaimer={disclaimer}/>
    </div>
  );
};

export default LogInComponent;
