import { FC } from 'react';
import { StringDropdownList } from '../../../components/common/DropdownList';
import { UserByDepartment } from '../../../types/user';
import { DeleteIcon } from '../../../components/common/Icons';
import clsx from 'clsx';

interface ICourseInstructorCardWithDelete {
  index: number;
  selected: boolean;
  person: UserByDepartment;
  removeInstructor: (id: number) => void;
}
export const employeeColors = [
  '#959CBD',
  '#3699FF',
  '#959CB6',
  '#FFB423',
  '#0BB783',
  '#8950FC',
  '#1BC5BD',
];

const CourseInstructorCardWithDelete: FC<ICourseInstructorCardWithDelete> = ({
  index,
  person,
  removeInstructor,
}) => {
  return (
    <div className="flex items-center w-full">
      <div className="w-full flex items-center">
        <div className="flex flex-row items-center gap-[16px] ">
          <div
            style={{ backgroundColor: employeeColors[index ? index : 0] }}
            className={clsx(
              'rounded-full min-w-[34px] min-h-[34px] flex justify-center items-center body-13 font-medium mr-2 text-white'
            )}
          >
            {person.name?.substring(0, 1)}
          </div>

          <span className="w-4/5 cursor-pointer">{person.name}</span>
        </div>

        <button
          className={clsx(
            'cust z-[100] cursor-pointer absolute right-2 -translate-y-1/2 w-5 h-5 mt-5'
          )}
          onClick={() => {}}
        >
          <DeleteIcon color="#fff" />
        </button>
      </div>

      <div className="ml-3">
        <button
          // @ts-ignore
          onClick={() => removeInstructor(person.id)}
          className="w-12 h-12 bg-redPink rounded-md flex items-center justify-center mr-2 group hover:bg-white border border-redPink"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 16 17"
            fill="#fff"
          >
            <path
              d="M2.5 1.5C1.94772 1.5 1.5 1.94772 1.5 2.5V3.5C1.5 4.05228 1.94772 4.5 2.5 4.5H3V13.5C3 14.6046 3.89543 15.5 5 15.5H11C12.1046 15.5 13 14.6046 13 13.5V4.5H13.5C14.0523 4.5 14.5 4.05228 14.5 3.5V2.5C14.5 1.94772 14.0523 1.5 13.5 1.5H10C10 0.947715 9.55229 0.5 9 0.5H7C6.44772 0.5 6 0.947715 6 1.5H2.5ZM5.5 5.5C5.77614 5.5 6 5.72386 6 6V13C6 13.2761 5.77614 13.5 5.5 13.5C5.22386 13.5 5 13.2761 5 13L5 6C5 5.72386 5.22386 5.5 5.5 5.5ZM8 5.5C8.27614 5.5 8.5 5.72386 8.5 6V13C8.5 13.2761 8.27614 13.5 8 13.5C7.72386 13.5 7.5 13.2761 7.5 13V6C7.5 5.72386 7.72386 5.5 8 5.5ZM11 6V13C11 13.2761 10.7761 13.5 10.5 13.5C10.2239 13.5 10 13.2761 10 13V6C10 5.72386 10.2239 5.5 10.5 5.5C10.7761 5.5 11 5.72386 11 6Z"
              fill="#fff"
              className="group-hover:fill-redPink"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  );
};
export default CourseInstructorCardWithDelete;
