import React, { useEffect, useRef, useState } from "react";

const CustomOTPTimer = ({ duration, onTimeout, resetTimer }: any) => {
  const [timeLeft, setTimeLeft] = useState<Number>(duration);
  const timerRef: any = useRef();
  useEffect(() => {
    if (resetTimer == true) {
      clearInterval(timerRef.current);
      setTimeLeft(duration);
    }
  }, [resetTimer, duration]);
  useEffect(() => {
    if (timeLeft === 0) {
      onTimeout();
    } else {
      timerRef.current = setInterval(() => {
        setTimeLeft((prevTimeLeft: any) => prevTimeLeft - 1);
      }, 1000);

      return () => clearInterval(timerRef.current);
    }
  }, [timeLeft, onTimeout]);
  const formatTime = (seconds: any) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `0${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };
  return (
    <div className="otp-timer">
      <span className="valid-timer text-sat_primary ml-1 min-w-[24px]">
        {formatTime(timeLeft)}
      </span>
    </div>
  );
};

export default CustomOTPTimer;
