import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useGetEventRemainderQuery } from "../../app/services/event-remainder";
import { useEffect, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";
import { renderDateFormat, renderDateFormatDMY } from "../DashboardCalendarView/DashboardAddEventPopup";
var date = new Date();
const CalendarEvent = (props: any) => {
  const [month, setMonth] = useState<number>(date.getMonth() + 1);
  const [year, setYear] = useState<number>(date.getFullYear());
  const calendarRef = useRef<any>(null!);
  const { data: events, isLoading } = useGetEventRemainderQuery({
    month: month,
    year: year,
  });
  const [eventsDataList, setEventsDataList] = useState([]);
  const [eventData, setEventData] = useState<any>();
  useEffect(() => {
    const eventCountData: any = [];
    if (events) {
      const eventsListData: any = events?.data;
      const groups = eventsListData?.reduce((groups: any, game: any) => {
        const date = game.date;
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(game);
        return groups;
      }, {});

      // Edit: to add it in the array format instead
      const groupArrays = Object.keys(groups).map((date) => {
        return {
          date,
          events: groups[date],
        };
      });

      const refineEvents = [...eventsListData]?.map(
        (item: any, key: number) => {
          const eventLstArr = groupArrays.find((x) => {
            if (x.date == item.date) {
              return x.events;
            }
          });
          eventCountData.push(eventLstArr);

          const lastEventData = eventLstArr?.events.find(
            (x: any) => x.uniqueID == item.uniqueID && x.date == item.date
          );
          const currentData = eventsListData?.filter(
            (x: any) => x.date == item.date
          );
          return {
            classNames: currentData.length > 1 ? "" : "only-one-event-data",
            uniqueID: item.uniqueID,
            title: item.title,
            description: item.descripton,
            date: item.date,
            start: item.date,
            end: item.date,
            color: item.eventType.color,
            eventType: item.eventType,
          };
        }
      );
      var result = eventCountData.reduce((unique: any, o: any) => {
        if (!unique.some((obj: any) => obj.date === o.date)) {
          unique.push(o);
        }
        return unique;
      }, []);
      // const lastEvents=[...refineEvents];
      // const eventFinal = [...lastEvents].map((item: any) => {
      //   const dup = result.find(
      //     (x: any) => x.date == item.date && item.classNames == ""
      //   )?.events;
      //   return {
      //     classNames:(item.classNames==''?dup?.length > 1
      //     ? "more-events-list"
      //     : dup?.length == 1
      //     ? "only-one-event-data"
      //     : item.classNames:''),
            
      //     uniqueID: item.uniqueID,
      //     title: item.title,
      //     description: item.descripton,
      //     date: item.date,
      //     start: item.date,
      //     end: item.date,
      //     color: item.eventType.color,
      //     eventType: item.eventType,
      //   };
      // });
      
      const dd:any=[];
      [...refineEvents]?.map((item:any)=>{
        console.log('have ',[...dd].find(x=>(x.date==item.date && x.classNames=='more-events-list')),item.date,item.classNames)
        if([...dd].find(x=>(x.date==item.date && x.classNames=='more-events-list'))){
          dd.push( {
            classNames:'',              
            uniqueID: item.uniqueID,
            title: item.title,
            description: item.descripton,
            date: item.date,
            start: item.date,
            end: item.date,
            color: item.eventType.color,
            eventType: item.eventType,
          });
        }else{
          dd.push( {
            classNames:item.classNames?item.classNames:"more-events-list",              
            uniqueID: item.uniqueID,
            title: item.title,
            description: item.descripton,
            date: item.date,
            start: item.date,
            end: item.date,
            color: item.eventType.color,
            eventType: item.eventType,
          });
        }
      })
      const sorting=[...dd].sort(function(a:any,b:any) {
        if(a.classNames === "" || a.classNames === null) return -1;
        if(b.classNames === "" || b.classNames === null) return 1;
        if(a.classNames === b.classNames) return 0;
        return a > b ? -1 : 1;
    });
      console.log("dd ",sorting)
      setEventData([...sorting]);
    }
  }, [events]);

  const changeToPrevMonth = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.prev();
    var calendarData = new Date(calendarApi.currentData.currentDate);
    var month = calendarData.getMonth() + 1;
    var year = calendarData.getFullYear();
    setMonth(month);
    setYear(year);
    calendarApi.refetchEvents();
    //getEventsDataByDate(month, year);
  };
  const changeToToday = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.today();
    var calendarData = new Date(calendarApi.currentData.currentDate);
    var month = calendarData.getMonth() + 1;
    var year = calendarData.getFullYear();
    setMonth(month);
    setYear(year);
    calendarApi.refetchEvents();
  };
  const changeToNextMonth = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.next();
    var calendarData = new Date(calendarApi.currentData.currentDate);
    var month = calendarData.getMonth() + 1;
    var year = calendarData.getFullYear();
    setMonth(month);
    setYear(year);
    calendarApi.refetchEvents();
  };
  const ref = useRef(null);
  const renderEventContent = (eventInfo: any) => {
    const currentData = eventData?.filter(
      (x: any) => x.date == eventInfo.event.extendedProps.startDate
    );
    const lastEvent = currentData[currentData.length - 1];

    return (
      <div
        className={twJoin("event_id_" + eventInfo.event.extendedProps.uniqueID)}
      >
        <li
          className={twJoin(
            currentData.length == 1 ? "only-one-event" : "",
            "w-[6px] h-[6px]"
          )}
        ></li>
      </div>
    );
  };
  useEffect(() => {
    document
      .querySelectorAll(".fc-daygrid-day-events .fc-daygrid-event-harness")
      .forEach(function (item) {
        const eventDiv = item.querySelectorAll(".fc-daygrid-event-harness");
        eventDiv.forEach(function (itm, key) {
          console.log("key ", key, eventDiv.length, itm);
          if (eventDiv.length > 1) {
            itm.classList.add("event-count-more");
          } else if (key == eventDiv.length - 1) {
            itm.classList.add("event-count-last-child");
          } else {
            itm.classList.add("event-count-one");
          }
        });
      });
  }, [props]);
  return (
    <div className="profile_calendar">
      <FullCalendar
        ref={calendarRef}
        customButtons={{
          prevCustomButton: {
            icon: "chevron-left",
            click: function () {
              changeToPrevMonth();
            },
          },
          nextCustomButton: {
            icon: "chevron-right",
            click: function () {
              changeToNextMonth();
            },
          },
        }}
        headerToolbar={{
          left: "title",
          center: "prevCustomButton,nextCustomButton",
          right: "",
        }}
        dayHeaderFormat={{
          weekday: "narrow",
        }}
        events={eventData?.map((event: any) => {
          return {
            ...event,
            startDate: event.start,
            eventTitle: event.title,
          };
        })}
        eventOrder='classNames'
        // events={eventData}
        eventContent={renderEventContent}
        // eventColor="#378006"
        height="auto"
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        fixedWeekCount={false}
        dateClick={(info: any) => {
          var currentDate = renderDateFormat(new Date(info.dateStr), "-");
          console.log("click ",currentDate)
          var filterData: any = eventData?.filter((item: any, key: number) => {
            const startDate = new Date(item.start);
            const endDate = new Date(item.end);
            const curDate = new Date(new Date(info.dateStr));
            if (item.date == currentDate) {
              return item;
            } else if (startDate <= curDate && curDate <= endDate) {
              return item;
            }
          });
          console.log("filterData ",filterData)
          setEventsDataList(filterData);
        }}
      />
      <div className="event-list mt-4 md:max-h-[40vh] max-h-[28vh] pr-2 overflow-auto custom-scrollbar">
        <ul>
          {eventsDataList?.map((item: any, key: number) => {
            return (
              <li
                key={key}
                className="pb-4 mb-4 border-b border-b-sat_borderv2 text-left"
              >
                <div className="flex justify-between items-center">
                  <div className="mr-5">
                    <p className="text-body-sm text-sat_darkgray400 font-normal ">
                      {item.start?renderDateFormatDMY(new Date(item.start),'-'):''} {item.end?renderDateFormatDMY(new Date(item.end),'-'):''}
                    </p>
                    <p className="heading3 text-sat_darkblackv2 font-normal ">
                      {item.title}
                    </p>
                  </div>
                  <div>
                    <p
                      className={twJoin(
                        `bg-[${item.eventType?.color}] bg-opacity-30`,
                        "text-text-body-sm font-normal rounded-[13px] py-1 px-2 bg-opacity-30 text-body-sm"
                      )}
                      style={{
                        color: item.eventType?.color,
                        backgroundColor: item.eventType?.color + "1A",
                      }}
                    >
                      {item.eventType?.typeName}
                    </p>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default CalendarEvent;
