import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import {
  authenticationReducer,
  contextReducer,
  dashboardReducer,
  permissionReducer,
} from "../reducers";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { NotificationReducer } from "../Notification/notificationSlice";
import { alertReducer } from "../claim-new/alertClaimSlice";

import { api } from "./services/api";
import loginApi from "./services/login";

export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    context: contextReducer,
    dashboard: dashboardReducer,
    permission: permissionReducer,
    [api.reducerPath]: api.reducer,
    notification: NotificationReducer,
    alertClaim: alertReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(api.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
