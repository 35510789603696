import { ToastPosition, toast } from 'react-toastify';

export type ToastProps = {
  message: string;
  position?: ToastPosition;
  type: 'info' | 'success' | 'warning' | 'error' | 'default';
};

const ResuableToasty = ({ message, position, type }: ToastProps) => {
  toast(`${message}`, {
    position,
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type,
    theme: 'light',
  });
};

export default ResuableToasty;
