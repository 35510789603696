import {
  ColumnDef,
  ColumnFiltersState,
  createColumnHelper,
  PaginationState,
  RowSelectionState,
  SortingState,
} from "@tanstack/react-table";
import {
  Dispatch,
  FC,
  SetStateAction,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from "react";
import LayoutAdjustableTable from "../Tables/LayoutAdjustableTable";
import { baseObj } from "../../utils/constants";
import {
  AssignIcon,
  CancelIcon,
  EditIcon,
  TableSaveIcon,
  TrashIcon,
} from "../common/Icons";
import TablePaginator from "../Tables/TablePaginator";
import { QuotationNumModal } from "../Projects/Modals";
import {
  assignUsers,
  PasswordDataHR,
  PasswordProject,
  PasswordUserList,
  useCreateHRPasswordMutation,
  useDeletePwdMutation,
  useMultiDeletePasswordMutation,
  useUpdatedPasswordMutation,
} from "../../app/services/password";
import Styles from "./PasswordsTable.module.scss";
import AssignPasswordModal from "./EmployeeAssignment";
import { Modal, Tooltip } from "@mui/material";
import { JSEncrypt } from "jsencrypt";
// import { templateData } from "../../app/services/template";
import { makeStyles } from "@mui/styles";
import DeletePopup from "../../components/DeletePopup/DeletePopup";
import eyeshow from "../../login/img/eye-show.svg";
import eyehide from "../../login/img/eye-open.svg";
import styles from "../../login/components/LogInComponent.module.scss";
import { MODALS } from "../../passwords/views/PasswordList";
import ErrorPopup from "../ErrorPopup/ErrorPopup";
import stylesEmp from "../EmployeeTable/EmployeeTable.module.scss";
import { assignedColor } from "../Documents/EmployeeDocumentsTable";
import DOMPurify from "dompurify";
import { tagsColor } from "../Announcements/AnouncementsTable";
import { toast, ToastContainer } from "react-toastify";
import EditableCell from "../Tables/EditableCell";
import Table from "../Tables/Tables.module.scss";
import AssignPopup from "./AssignPopup";
import { EmployeeData } from "../EmployeeTable/EmployeeTable";
import CopyToClipboardBtn from "../DashboardProfile/MyPasswords/CopyToClipboardBtn";
import ResuableToasty from "../Toasty/ResuableToast";
import CustomDropdownWithAddButton from "../EmployeeTable/CustomDropdownWithAddButton";

var classNames = require("classnames");

interface filterPasswordData {
  filterPasswordData?: any;
}
interface IPasswordTable {
  sortDirection: string;
  setSortDirection: React.Dispatch<React.SetStateAction<string>>;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  filterPasswordData?: any;
  isLayoutControlOpen: boolean;
  setIsLayoutControlOpen: Dispatch<SetStateAction<boolean>>;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  globalSearch: string;
  setGlobalSearch: Dispatch<SetStateAction<string>>;
  columnFilters: ColumnFiltersState;
  setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
  showPasswords: boolean;
  data: baseObj[];
  setData: Dispatch<SetStateAction<filterPasswordData[]>>;
  localModuleId: number;
  hiddenFields: string[];
  assignPassword: any;
  assignPasswords: any;
  modalStateHandler?: any;
  setShowPasswords?: any;
  setModalRow?: Dispatch<React.SetStateAction<number>>;
  setOnePwd?: any;
  // onePwd?: boolean;
  show_one_pwd?: boolean;
  setEyeClick?: any;
  setIsSuccess?: any;
  isSuccess?: any;
  filterTagData?: any;
  total: number;
  changePage: any;
  setCurrentPage: any;
  currentPage: number;
  managePassword?: boolean;
  setCols?: Dispatch<any>;
  setIsTemplate?: Dispatch<React.SetStateAction<boolean>>;
  createTagData: any;
  isEditing?: boolean;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  publicKey?: string;
  handleOneGetKey: (id: number) => void;
  assignUserListData: assignUsers[];
  departmentList: EmployeeData[];
  setAssignDepartmentId: React.Dispatch<React.SetStateAction<string>>;
  setAssignKeword: React.Dispatch<React.SetStateAction<string>>;
  assignDepartmentId: string;
  checkNewPwd: boolean;
  projects: PasswordProject[];
}

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    margin: 0,
    marginRight: 8,
    padding: 0,
  },
}));

export type RowData = { [key: string]: any };
const PasswordsTable: FC<IPasswordTable> = ({
  setSortDirection,
  sortDirection,
  setSorting,
  sorting,
  isLayoutControlOpen,
  setIsLayoutControlOpen,
  pagination,
  setPagination,
  globalSearch,
  setGlobalSearch,
  columnFilters,
  setColumnFilters,
  showPasswords,
  data,
  setData,
  localModuleId,
  hiddenFields,
  assignPassword,
  assignPasswords,
  modalStateHandler,
  setModalRow,
  setShowPasswords,
  setOnePwd,
  // onePwd,
  show_one_pwd,
  setEyeClick,
  setIsSuccess,
  isSuccess,
  filterTagData,
  total,
  changePage,
  setCurrentPage,
  currentPage,
  setCols,
  setIsTemplate,
  managePassword,
  createTagData,
  isEditing,
  setIsEdit,
  publicKey,
  handleOneGetKey,
  assignUserListData,
  departmentList,
  setAssignDepartmentId,
  assignDepartmentId,
  setAssignKeword,
  checkNewPwd,
  projects
}) => {
  var encrypt: any = new JSEncrypt();
  const columnHelper = useMemo(() => createColumnHelper<any>(), []); // TODO add type definitions when api binding
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  const [itemCount, setItemCount] = useState<number>(total);
  const selectRowsCount = Object.keys(rowSelection).length;
  const [uploading, setUploading] = useState<boolean>(false);
  const [quotationModalState, setQuotationModalState] =
    useState<boolean>(false);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<"assign" | "remove">("assign");
  const [selectedUser, setSelectedUser] = useState<number>(0);
  const [existingMember, setExistingMember] = useState<PasswordUserList[]>([]);
  const [selectedMember, setSelectedMember] = useState<number[]>();
  const classes = useStyles();
  const [openDeletePopup, setOpenDeleteopup] = useState<boolean>(false);
  const [clickLoading, setClickLoading] = useState<boolean>(false);
  // const [multipleDeleteRoleFun] = useMultiDeletePasswordMutation();
  const [multiDeletePwd] = useMultiDeletePasswordMutation();
  const [deletePwds] = useDeletePwdMutation();
  const [updatedPwd] = useUpdatedPasswordMutation();
  // const [deleteRowID, setDeleteRowID] = useState<number | 0>();
  const [deleteID, setDeleteID] = useState<number | 0>();
  const [selectedPwID, setSelectedPwID] = useState<number[]>([]);
  const [selectedPwIDSingle, setSelectedSinglePwID] = useState<number>(0);
  // const [editRowData, setEditRowData] = useState<any>();
  // const [pwd, setPwd] = useState<string>();
  // const [isEditPasswordModalOpen, setIsEditPasswordModalOpen] =
  //   useState<boolean>(false);
  const [editedTagData, setEditedTagData] = useState<any>();
  const [showTagPopup, setShowTagPopup] = useState<boolean>(false);
  const [cellData, setCellData] = useState<any>();
  const [isShow, setIsShow] = useState<boolean>(false);
  const [createHRPassword] = useCreateHRPasswordMutation();
  // const pwdRef = useRef<any>(null);
  const assignedUsers = (selectedMember: number[]) => {
    var uniqueIds = selectedPwID.filter(
      (value, index, array) => array.indexOf(value) == index
    );
    if (uniqueIds.length <= 0) {
      var indexes = Object.keys(rowSelection);
      var ids = data
        .map((x, i: number) => {
          var id = indexes.includes(i.toString()) ? x.id : 0;
          return id;
        })
        .filter((x) => x != 0);
      uniqueIds = ids;
    }

    if (Number(selectedPwIDSingle) !== 0) {
      var assignedSingleData = {
        password_ids: selectedPwIDSingle,
        user_ids: selectedMember,
      };

      assignPassword(assignedSingleData)
        .unwrap()
        .then((payload: any) => {
          if (payload.status) {
            setSuccessPopup(true);
            setMessage(payload ? [payload?.message] : ["Asign Successfully!"]);
            setOpenDeleteopup(false);
            setIsAssignModalOpen(false);
            setSelectedPwID([]);
            setSelectedSinglePwID(0);
            ResuableToasty({
              message: "Asign Successfully!",
              position: "top-right",
              type: "success",
            });

            // toast("Asign Successfully!", {
            //   position: "top-right",
            //   autoClose: 2000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            //   theme: "light",
            //   type: "success",
            // });
          }
        })
        .catch((error: any) => {
          setErrorPopup(true);
          setMessage(
            error
              ? [error?.data?.message]
              : ["Something went wrong!Trying again."]
          );
        });
    } else {
      var assignedData = {
        password_ids: uniqueIds,
        user_ids: selectedMember,
      };
      assignPasswords(assignedData)
        .unwrap()
        .then((payload: any) => {
          if (payload.status) {
            setSuccessPopup(true);
            setMessage(payload ? [payload?.message] : ["Asign Successfully!"]);
            setOpenDeleteopup(false);
            setIsAssignModalOpen(false);
            setSelectedPwID([]);
            setSelectedSinglePwID(0);
            ResuableToasty({
              message: "Asign Successfully!",
              position: "top-right",
              type: "success",
            });

            // toast("Asign Successfully!", {
            //   position: "top-right",
            //   autoClose: 2000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            //   theme: "light",
            //   type: "success",
            // });
            setRowSelection({});
          }
        })
        .catch((error: any) => {
          setErrorPopup(true);
          setMessage(
            error
              ? [error?.data?.message]
              : ["Something went wrong!Trying again."]
          );
        });
    }
  };
  const canCeelRow = (id: number) => {
    if (isEditing) {
      setIsEdit(false);
      setData((prev: baseObj[]) => {
        return prev.map((data, index: number) => {
          if (data.id == id) {
            return { ...data, isNew: false };
          }
          return data;
        });
      });
    } else {
      const filterData = data?.filter((d: any) => d?.id !== id);
      setData(filterData);
    }
  };

  const editRow = useCallback(
    (id: number) => {
      setData((prev: baseObj[]) => {
        if (document.getElementsByClassName(Table.horizontalScrollBar)[0]) {
          document.getElementsByClassName(
            Table.horizontalScrollBar
          )[0].scrollLeft = 0;
        }
        return prev.map((data, index: number) => {
          if (data.id == id) {
            return { ...data, isNew: true, password: "" };
          } else {
            return { ...data, password: "********", is_show: 0 };
          }

          return data;
        });
      });
    },
    [setData]
  );

  // TODO add type definitions when api binding
  const columns = useMemo(
    () => [
      columnHelper.display({
        header: "",
        id: `${managePassword ? "selection" : "none-selection"}`,
      }),
      columnHelper.accessor("company", {
        header: "Company",
        id: "company",
        cell: ({ getValue, cell }) => {
          const platform = getValue();
          return (
            <div className="min-w-[150px] text-left">
              {/* min-w-[140px] */}
              <EditableCell
                setData={setData}
                value={platform}
                type="text"
                cell={cell}
                customDesign="text"
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("project", {
        header: "Project",
        id: "project",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const dptment = getValue();
          var departView = departmentD;
          var departmentD = projects?.find((x) => x.project_id == dptment);
          if (departmentD == undefined) {
            departView = projects?.find((x) => x.project_name == dptment);
          } else {
            departView = departmentD;
          }
          if(typeof(dptment)=="object"){
            departView=dptment;
          }
          const originalValues = cell.row.original?.role?.map(
            (role: baseObj) => role.id
          );
          const isNew = cell.row.original?.isNew;
          return (
            <div
              className={classNames(
                "  text-left h-full font-normal",
                // max-w-full min-w-[180px]
                styles.customEmployeeDropdown
              )}
            >
              <EditableCell
                className="py-0"
                hasPadding={true}
                setData={setData}
                value={departView ? departView.project_name : "Select"}
                type="custom"
                cell={cell}
                customDesign=""
                CustomComponent={
                  <CustomDropdownWithAddButton
                    isShowAdd={false}
                    isShowDelete={false}
                    addNewItemData={()=>1}
                    initialValues={originalValues}
                    dataList={projects ? projects?.map((project)=>{
                      return {
                        id:project.project_id,
                        name:project.project_name
                      }
                    }) : []}
                    setDataList={()=>1}
                    title="Project"
                    cell={cell}
                    data={data}
                    setData={setData}
                    setDeleteID={()=>1}
                    setOpenDeleteopup={setOpenDeleteopup}
                    setSelectedData={()=>1}
                    selectedData={departView ? departView.project_name : "Select"}
                    addTitle=""
                    isEditable={isNew}
                  />
                }
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("platform", {
        header: "Platform",
        id: "platform",
        size: 100,
        cell: ({ getValue, cell }) => {
          const platform = getValue();
          return (
            <div className="min-w-[150px] text-left">
              {/* min-w-[140px] */}
              <EditableCell
                setData={setData}
                value={platform}
                type="text"
                cell={cell}
                customDesign="text"
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("account", {
        header: "Account",
        id: "account",
        size: 100,
        cell: ({ getValue, cell }) => {
          const account = getValue();
          const isNew: boolean = cell?.row?.original?.isNew;
          return (
            <div className="relative min-w-[150px] text-left flex justify-start">
              {/* min-w-[220px] */}
              <div className="mr-1 w-full">
                <EditableCell
                  setData={setData}
                  value={account}
                  type="text"
                  cell={cell}
                  customDesign="text"
                />
              </div>
              <div className={isNew ? "hidden" : " absolute right-2 ml-3"}>
                <CopyToClipboardBtn value={account} isAccount={true} />
              </div>
            </div>
          );
        },
      }),
      columnHelper.accessor("password", {
        header: "Password",
        enableSorting: false,
        id: "password",
        cell: ({ getValue, cell }) => {
          const password = getValue();
          const rowId = Number(cell?.row?.original?.id);
          const isNew = cell.row.original?.isNew;
          const isShow: number = cell?.row?.original?.is_show;

          return (
            <div className=" flex justify-between items-center min-w-[150px]">
              {/* min-w-[200px] */}
              {isSuccess ? (
                <div className="min-w-[200px] relative">
                  <p className="text-black2 font-normal min-w-[150px] mr-2 lg:text-14 text-12 text-ellipsis overflow-hidden">
                    {password}{" "}
                  </p>
                  {isShow ? (
                    <div
                      className={
                        isNew
                          ? "hidden"
                          : " absolute right-2 -translate-y-1/2 top-[65%]"
                      }
                    >
                      <CopyToClipboardBtn value={password} isAccount={true} />
                    </div>
                  ) : (
                    <img
                      src={isShow === 0 ? eyeshow : eyehide}
                      alt="eye-open"
                      className={classNames(
                        isShow === 1 ? " pointer-events-none" : "",
                        styles.theeye,
                        "absolute top-1/2 right-0 cursor-pointer w-[22px] h-[16px] object-cover"
                      )}
                      onClick={() => {
                        if (setModalRow) {
                          setModalRow(rowId);
                        }
                        handleOneGetKey(rowId);
                        setOnePwd(false);
                        // setEyeClick(false);
                        setShowPasswords(false);
                        setIsSuccess(false);
                      }}
                    />
                  )}
                </div>
              ) : (
                <div>
                  {isNew ? (
                    <EditableCell
                      setData={setData}
                      // value={isChanged ? password : ""}
                      value={password}
                      type="password"
                      cell={cell}
                      customDesign="text"
                    />
                  ) : (
                    <div className="min-w-[100px]">
                      <p className="text-black2 font-normal mr-2 lg:text-14 text-12">
                        {"********"}
                      </p>
                      {managePassword ? (
                        <img
                          src={eyeshow}
                          alt="eye-open"
                          className={classNames(
                            styles.theeye,
                            "absolute top-1/2 right-0 cursor-pointer w-[22px] h-[16px] object-cover"
                          )}
                          onClick={() => {
                            if (setModalRow) {
                              setModalRow(rowId);
                            }
                            handleOneGetKey(rowId);
                            setOnePwd(true);
                            modalStateHandler(MODALS.SHOW_PWD);
                            setIsSuccess(false);
                          }}
                        />
                      ) : null}
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor("links", {
        header: "Links",
        id: "links",
        cell: ({ getValue, cell }) => {
          const links = getValue();
          return (
            <div className="  text-left overflow-hidden">
              {/* min-w-[150px] */}
              <EditableCell
                setData={setData}
                value={links}
                type="text"
                cell={cell}
                customDesign="link"
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("tags", {
        header: "Tags",
        id: "tags",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const department = getValue();
          var tagArr = department.map((dep: any) => dep.name);
          const isEditable = cell.row.original?.isNew;
          return (
            <>
              {!isEditable ? (
                tagArr?.length > 0 ? (
                  <div className=" overflow-hidden px-2 min-w-[150px]">
                    <div className="w-[97%] overflow-hidden text-ellipsis flex items-center">
                      {department?.map((x: any, index: number) => {
                        var idx = index;
                        if (index >= 3) {
                          idx = index % 3;
                        }
                        return (
                          <div
                            key={`${Math.random() * 100}`}
                            className="font-normal px-[5px] mr-[3px] rounded-[5px]"
                            style={{
                              backgroundColor: tagsColor[idx],
                              height: "auto",
                            }}
                          >
                            {x.name}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  // <Tooltip
                  //   arrow
                  //   classes={{ tooltip: classes.tooltip }}
                  //   enterTouchDelay={0}
                  //   leaveTouchDelay={5000}
                  //   placement="left"
                  //   title={
                  //     <>
                  //       <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                  //         <span className="text-[#fff] block">
                  //           {tagArr?.join(", ")}
                  //         </span>
                  //       </div>
                  //     </>
                  //   }
                  // >
                  //   <div className="min-w-[200px] overflow-hidden px-2">
                  //     <div className="w-[97%] overflow-hidden flex items-center">
                  //       {department?.map((x: any, index: number) => {
                  //         var idx = index;
                  //         if (index >= 3) {
                  //           idx = index % 3;
                  //         }
                  //         return (
                  //           <div
                  //             key={`${Math.random() * 100}`}
                  //             className="font-normal px-[5px] mr-[3px] rounded-[5px]"
                  //             style={{
                  //               backgroundColor: tagsColor[idx],
                  //               height: "auto",
                  //             }}
                  //           >
                  //             {x.name}
                  //           </div>
                  //         );
                  //       })}
                  //     </div>
                  //   </div>
                  // </Tooltip>
                  <span className="px-2">---</span>
                )
              ) : (
                <div className=" w-full flex justify-between items-center font-normal min-w-[150px]">
                  <EditableCell
                    customDesign="text"
                    hasPadding={true}
                    setData={setData}
                    value={tagArr?.join(",")}
                    type="custom"
                    cell={cell}
                    CustomComponent={
                      <Tooltip
                        arrow
                        classes={{ tooltip: classes.tooltip }}
                        enterTouchDelay={0}
                        leaveTouchDelay={5000}
                        placement="left"
                        title={
                          <>
                            <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                              <span className="text-[#fff] block">
                                {tagArr?.join(", ")}
                              </span>
                            </div>
                          </>
                        }
                      >
                        <button
                          onClick={() => {
                            setIsShow(true);
                            setCellData(cell);
                            setEditedTagData(department);
                          }}
                          className="min-w-[200px] h-full font-poppins w-[90%] text-12 lg:text-14 px-5 overflow-hidden whitespace-nowrap text-ellipsis bg-vorpmyanmar text-black2"
                        >
                          {tagArr?.length > 0 ? (
                            <div className=" overflow-hidden px-2">
                              {/* min-w-[200px] */}
                              <div className="w-[97%] overflow-hidden text-ellipsis flex items-center">
                                {tagArr?.map((tag: any, index: number) => {

                                  var idx = index;
                                  if (index >= 3) {
                                    idx = index % 3;
                                  }
                                  return (
                                    <div
                                      key={`${Math.random() * 100}`}
                                      className="font-normal px-[5px] mr-[3px] rounded-[5px]"
                                      style={{
                                        backgroundColor: tagsColor[idx],
                                        height: "auto",
                                      }}
                                    >
                                      {tag}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>

                          ) : (
                            <span className="px-2">---</span>
                          )}
                        </button>
                      </Tooltip>
                    }
                  />
                </div>
              )}
            </>
          );
        },
      }),
      columnHelper.accessor("createdBy", {
        header: "Created By",
        id: "createdBy",
        cell: ({ getValue }) => {
          const data = getValue();
          return (
            <div className=" min-w-[200px] text-left flex flex-col bg-white px-[10px]">
              {/* min-w-[160px] */}
              <span className="text-black2 font-normal lg:text-14 text-12">
                {data.name}
              </span>
              {data.createdAt ? (
                <span className="text-12 leading-[18px] text-graydark">
                  {data.createdAt}
                </span>
              ) : (
                ""
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor("assignUsers", {
        header: "Assign to",
        id: "assignUsers",
        enableSorting: false,
        cell: ({ cell }) => {
          const id = cell.row.original.id;
          const assigned = cell.row.original.assignUsers;
          return (
            <button
              onClick={() => {
                setModalType("assign");
                if (managePassword) {
                  setIsAssignModalOpen(!isAssignModalOpen);
                }
                setSelectedSinglePwID(
                  cell.row.original.id ? parseInt(cell.row.original.id) : 0
                );
                setSelectedUser(id);
                setExistingMember(assigned);
              }}
              type="button"
              className={classNames(
                managePassword ? "" : "pointer-events-none",
                Number(cell.row.original.id) === 0 || isEditing === true
                  ? "pointer-events-none"
                  : "",
                "font-normal flex justify-center items-center h-full min-w-[150px] ml-[10px]"
              )}
            >
              {assigned?.length == 0 && (
                <div className="flex justify-center items-center">---</div>
              )}
              {assigned?.map((x: any, index: number) => {
                if (index < 3) {
                  var idx = index;
                  if (idx > 4) {
                    idx = 0;
                  }
                  return x.profileImage ? (
                    <img
                      key={index}
                      src={x.profileImage}
                      alt="profileImage"
                      className="w-[35px] h-[35px] rounded-full object-cover ml-[-10px]"
                    />
                  ) : (
                    <div
                      key={index}
                      className={classNames(
                        "bg-[" + assignedColor[idx] + "]",
                        "ml-[-10px] w-[35px] h-[35px] rounded-full text-14 flex justify-center items-center text-white"
                      )}
                    >
                      {x.name?.substring(0, 1)}
                    </div>
                  );
                }
              })}
              {assigned?.length > 3 ? (
                <div className="ml-[-10px] w-[35px] h-[35px] rounded-full text-10 flex justify-center items-center bg-black2 text-white">
                  {assigned?.length - 3}+
                </div>
              ) : (
                ""
              )}
            </button>
          );
        },
      }),
      columnHelper.display({
        header: "Action",
        id: `${(data?.find((x) => x.isNew == true) && checkNewPwd) || managePassword
          ? "actions"
          : "none-actions"
          }`,
        cell: ({ cell }) => {
          var isHaveNew = data?.find((x) => x.isNew == true);
          var disabledClass = isHaveNew ? "opacity-50 pointer-events-none" : "";
          const isNew: boolean = cell.row.original?.isNew;
          return (
            <div
              className={classNames(
                stylesEmp.actionedBtn,
                "flex justify-center h-full items-center min-w-max " //max-w-[98px]
              )}
            >
              {isNew ? (
                <>
                  <button
                    type="button"
                    onClick={() =>
                      savePasswordData(Number(cell?.row?.original?.id))
                    }
                    className={`w-8 h-8 flex justify-center items-center bg-vorpgreen rounded-md ${uploading ? " pointer-events-none opacity-40" : ""
                      }`}
                  >
                    <TableSaveIcon />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      canCeelRow(Number(cell?.row?.original?.id));
                    }}
                    className={classNames(
                      styles.customCloseIcon,
                      "w-8 h-8 ml-2 mr-2 flex justify-center items-center group rounded-md  bg-[#71717a] hover:bg-[#71717a] transition-all duration-300"
                    )}
                  >
                    <CancelIcon width={18} height={18} />
                  </button>
                </>
              ) : (
                <>
                  {managePassword ? (
                    <>
                      <button
                        disabled={isHaveNew ? true : false}
                        type="button"
                        onClick={() => {
                          editRow(cell?.row?.original?.id);
                          if (setIsEdit) {
                            setIsEdit(true);
                            setIsSuccess(false);
                          }
                        }}
                        className={classNames(
                          disabledClass,
                          "w-8 h-[35px] mr-2 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
                        )}
                      >
                        <EditIcon />
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          deletePwdFun(Number(cell?.row?.original?.id));
                        }}
                        className={classNames(
                          disabledClass,
                          "w-8 h-8 flex justify-center items-center group rounded-md bg-redPink hover:bg-redPinkLight transition-all duration-300"
                        )}
                      >
                        <TrashIcon />
                      </button>
                    </>
                  ) : null}
                </>
              )}
            </div>
          );
        },
      }),
    ],
    [columnHelper, data, uploading]
  );

  const [columnOrder, setColumnOrder] = useState<string[]>(
    columns.map((column: ColumnDef<any, any>) => column.id as string)
  );

  document.querySelectorAll("th,td").forEach((x) => {
    if (x.id.includes("none")) {
      x.classList.add("hidden");
    }
    var div = document.getElementById(x.id);
    if (div) {
      div.style.setProperty("left", "");
    }
    if (x.id.includes("action")) {
      x.classList.add("absolute");
      x.classList.remove("relative");
      x.classList.add("right-0");
      x.classList.remove("left-0");
    }
  });

  const visibleColumns: { [key: string]: boolean } = {};

  hiddenFields?.map((x) => {
    visibleColumns[x] = true;
  });

  if (managePassword) {
    visibleColumns["assignUsers"] = true;
  } else {
    visibleColumns["assignUsers"] = false;
  }

  if (hiddenFields.length > 0) {
    columns.map((x: any) => {
      if (x.id == "actions" || x.id == "lasted" || x.id == "selection") {
        visibleColumns[x.id ? x.id : ""] = true;
      } else if (x.id == "uniqeID") {
        visibleColumns[x.id ? x.id : ""] = false;
      } else if (x.id?.includes("none")) {
        visibleColumns[x.id ? x.id : ""] = true;
      } else {
        var isHidden = hiddenFields.find((h) => h == x.id);
        if (isHidden) {
          visibleColumns[x.id ? x.id : ""] = true;
        } else {
          visibleColumns[x.id ? x.id : ""] = false;
        }
      }
    });
  }

  const deletePwdFun = useCallback(
    (id?: number) => {
      setDeleteID(id);
      setOpenDeleteopup(true);
    },
    [setData, rowSelection]
  );

  const confirmDeleteData = () => {
    var id = deleteID;
    const selectedRows = Object.keys(rowSelection);
    var selectionIds = data
      .filter((x, index: number) => {
        return selectedRows.find((s) => s == index.toString()) ? x.id : 0;
      })
      .map((x) => x.id);
    if (selectionIds.length > 0) {
      deleteProjectMultiple(selectionIds);
    } else {
      deleteItem(id);
      setDeleteID(-1);
    }
  };

  const deleteItem = (id?: number) => {
    setClickLoading(true);
    const selectedRows = Object.keys(rowSelection);
    setData((prev: baseObj[]) =>
      prev.filter((_, index) => {
        return id !== undefined
          ? id !== index
          : !selectedRows.includes(String(index));
      })
    );
    id == undefined && setRowSelection({});
    if (id !== undefined) {
      deletePwds(id)
        .unwrap()
        .then((payload: any) => {
          if (payload) {
            setClickLoading(false);
            setSuccessPopup(true);
            if (setIsSuccess) {
              setIsSuccess(false);
            }
            setMessage([payload?.message]);
            setOpenDeleteopup(false);

            ResuableToasty({
              message: "Delete Successfully!",
              position: "top-right",
              type: "success",
            });

            // toast("Delete Successfully!", {
            //   position: "top-right",
            //   autoClose: 2000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            //   theme: "light",
            //   type: "success",
            // });
          }
        })
        .catch((error: any) => {
          setClickLoading(false);
          setErrorPopup(true);
          setMessage(["Something went wrong!Trying again."]);
          setOpenDeleteopup(true);
        });
    }
  };

  const deleteProjectMultiple = (ids: number[]) => {
    setClickLoading(true);
    multiDeletePwd(ids)
      .unwrap()
      .then((payload: any) => {
        setClickLoading(false);

        setOpenDeleteopup(false);
        if (setIsSuccess) {
          setIsSuccess(false);
        }
        setSuccessPopup(true);
        setMessage([payload?.message]);
        ResuableToasty({
          message: "Delete Successfully!",
          position: "top-right",
          type: "success",
        });

        // toast("Delete Successfully!", {
        //   position: "top-right",
        //   autoClose: 2000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        //   type: "success",
        // });
      })
      .catch((error: any) => {
        setClickLoading(false);
        setErrorPopup(true);
        setMessage(error ? [error?.data?.message] : "Something went wrong!");
      })

      .then((res: any) => { });
    setRowSelection({});
  };

  const [isResponseSuccess, setResponseSuccess] = useState<boolean>(false);

  const savePassword = useCallback(
    (id: number) => {
      setData((prev: baseObj[]) => {
        return prev.map((data, index: number) => {
          if (data.id == id) {
            return {
              ...data,
              isNew: false,
            };
          }
          return data;
        });
      });
    },
    [setData]
  );

  const checkIsValidate = (editedData: any) => {
    var msg: any = [];

    if (editedData.account === "") {
      msg.push("The account field is required!");
    }
    // if (editedData.links === "") {
    //   msg.push("The links field is required!");
    // }
    if (editedData.password === "") {
      msg.push("The password field is required!");
    }
    if (editedData.platform === "") {
      msg.push("The platform field is required!");
    }

    return msg;
  };

  const savePasswordData = (id: number) => {
    var editedData = data.find((x) => x.id == id);
    console.log("editedData ",editedData,data)
    if (editedData) {
      if (editedData.id == id) {
        var message: any = checkIsValidate(editedData);
        if (message.length > 0 && !isEditing) {
          setErrorPopup(true);
          setMessage(message);
          return;
        } else {
          updatedNewPassword(editedData);
          if (isResponseSuccess) {
            savePassword(id);
          }
        }
      }
    }
  };

  const updatedNewPassword = (data: any) => {
    setUploading(true);

    const platform = data.platform ? DOMPurify.sanitize(data.platform) : "";
    const company = data.company ? DOMPurify.sanitize(data.company) : "";
    const account = data.account ? DOMPurify.sanitize(data.account) : "";
    const links = data.links ? DOMPurify.sanitize(data.links) : "";
    const project = data.project ? data.project : 0;
    const pwdData =
      data.isChanged == true
        ? data.password
        : !data.password?.includes("********") && data.isChanged == false
          ? data.password
          : "";

    if (publicKey !== undefined) {
      encrypt.setPublicKey(publicKey);
    }
    var encrypted = encrypt.encrypt(pwdData);

    if (Number(data.id) === 0) {
      setIsEdit(false);
      setResponseSuccess(false);
      var hrPassword: PasswordDataHR = {
        account: data.account,
        links: data.links,
        password:
          data.password != "********"
            ? data.password.length === 0
              ? null
              : encrypted
            : "",
        platform: data.platform,
        company: data.company,
        tags: data.tags?.map((t: any) => t.id),
        user_ids: [],
        project_id: typeof(data.project)=="object"?data.project?.project_id:data.project
      };
      createHRPassword(hrPassword)
        .unwrap()
        .then((payload: any) => {
          setUploading(false);

          const createdData = payload.password;
          if (payload.status) {
            setResponseSuccess(true);
            setData((prev: baseObj[]) => {
              return prev.map((data, index: number) => {
                return {
                  createdData,
                  ...data,
                  isNew: false,
                };
                return data;
              });
            });
            ResuableToasty({
              message: "Update Successfully!",
              position: "top-right",
              type: "success",
            });
            // toast("Update Successfully!", {
            //   position: "top-right",
            //   autoClose: 2000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            //   theme: "light",
            //   type: "success",
            // });
          }
        })
        .catch((error: any) => {
          setUploading(false);

          setIsSuccess(false);
          setErrorPopup(true);
          setResponseSuccess(false);
          setMessage(error ? [error?.data?.message] : "Something went wrong!");
        });
    } else {
      setResponseSuccess(false);
      let updatedData = {
        id: data.id,
        platform,
        company,
        account,
        links,
        tags: data.tags?.map((x: any) => x.id),
        user_ids: [],
        password: pwdData.length === 0 ? null : encrypted,
        project_id: typeof(data.project)=="object"?data.project?.project_id:data.project
      };

      updatedPwd(updatedData)
        .unwrap()
        .then((payload: any) => {
          setUploading(false);

          const updatedData = payload.password;
          if (payload.status) {
            setResponseSuccess(true);
            setIsEdit(false);
            // setIsEditPasswordModalOpen(false);
            setSuccessPopup(true);
            setMessage([payload?.message]);
            setData((prev: baseObj[]) => {
              return prev.map((data, index: number) => {
                return {
                  updatedData,
                  ...data,
                  isNew: false,
                };
                return data;
              });
            });
            ResuableToasty({
              message: "Update Successfully!",
              position: "top-right",
              type: "success",
            });

            // toast("Update Successfully!", {
            //   position: "top-right",
            //   autoClose: 2000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            //   theme: "light",
            //   type: "success",
            // });
          }
        })
        .catch((error: any) => {
          setIsSuccess(false);
          setUploading(false);

          // setIsEditPasswordModalOpen(true);
          setErrorPopup(true);
          setResponseSuccess(false);
          setMessage(error ? [error?.data?.message] : "Something went wrong!");
        });
      setResponseSuccess(false);
    }
  };

  useEffect(() => {
    if (hiddenFields.length > 0) {
      setColumnOrder(["selection", ...hiddenFields]);
    }
  }, [hiddenFields]);

  useEffect(() => {
    setTimeout(() => {
      setSuccessPopup(false);
    }, 1000);
  }, [successPopup]);

  return (
    <div className="text-primary text-left relative">
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />

      <DeletePopup
        show={openDeletePopup}
        setShow={setOpenDeleteopup}
        confirmDeleteData={confirmDeleteData}
        descText="Are You Sure To Delete"
        loading={clickLoading}
      />

      <Modal
        open={isAssignModalOpen}
        style={{
          background: "rgb(0 0 0 / 0.3)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setIsAssignModalOpen(false);
          }
        }}
        aria-labelledby="password-add-modal"
        aria-describedby="password-add-modal"
        disableEnforceFocus={true}
      >
        <>
          <AssignPasswordModal
            existingMember={existingMember}
            employeeList={assignUserListData}
            assignedUsers={assignedUsers}
            modalType={modalType}
            setIsOpen={setIsAssignModalOpen}
            setSelectedMemberData={setSelectedMember}
            departmentList={departmentList}
            setAssignDepartmentId={setAssignDepartmentId}
            assignDepartmentId={assignDepartmentId}
            setAssignKeword={setAssignKeword}
          />
        </>
      </Modal>

      <QuotationNumModal
        state={quotationModalState}
        setState={setQuotationModalState}
      />

      <div className={classNames(Styles.customTable, 'relative inline-block max-w-full')}>
        <LayoutAdjustableTable
          columns={columns}
          data={data}
          sorting={sorting}
          setSortingState={setSorting}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          columnOrder={columnOrder}
          setColumnOrder={setColumnOrder}
          unDraggableFields={[
            "actions",
            "selection",
            "assignTo",
            "department",
            "links",
            "createdBy",
            "password",
            "account",
            "platform",
          ]}
          noPaddingCells={["project"]}
          theaderColor="text-graydark"
          isLayoutControlOpen={isLayoutControlOpen}
          setIsLayoutControlOpen={setIsLayoutControlOpen}
          label="Password List"
          setItemCount={setItemCount}
          pagination={pagination}
          setPagination={setPagination}
          globalFilter={globalSearch}
          setGlobalFilter={setGlobalSearch}
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
          localModuleId={localModuleId}
          setCols={setCols}
          setIsTemplate={setIsTemplate}
          hideColumn={visibleColumns}
          setSortDirection={setSortDirection}
          sortDirection={sortDirection}
        />
      </div>

      {selectRowsCount > 0 && (
        <div className="flex items-center text-14 leading-5 font-semibold text-black2 mt-3 mb-3 lg:mt-3 lg:mb-3 ">
          <p>{selectRowsCount} selected.</p>
          {managePassword ? (
            <button
              type="button"
              onClick={() => deletePwdFun()}
              className=" flex items-center ml-5 text-12 leading-[18px] font-semibold text-redPink"
            >
              <div className="w-6 h-6 bg-redPink flex items-center group justify-center rounded-md mr-[10px] hover:bg-redPinkLight transition-all duration-300 ">
                <TrashIcon width={10} height={12} />
              </div>
              Delete
            </button>
          ) : null}
          <button
            type="button"
            onClick={() => setIsAssignModalOpen(true)}
            className=" flex items-center ml-5 text-12 leading-[18px] font-semibold text-vorpblue"
          >
            <div className="w-6 h-6 bg-vorpblue flex items-center group justify-center rounded-md mr-[10px] transition-all duration-300 ">
              <AssignIcon />
            </div>
            Assign
          </button>
        </div>
      )}

      <div className="pt-2">
        <TablePaginator
          total={itemCount}
          pagination={pagination}
          setPagination={setPagination}
          itemCount={total}
          changePage={changePage}
          setCurrentPage={setCurrentPage}
          currentPageDefault={currentPage}
        />
      </div>
      <Modal
        open={isShow}
        style={{
          background: "rgb(0 0 0 / 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={() => setIsShow(false)}
        aria-labelledby="Password Add Modal"
        aria-describedby="Add New Password Form Pop up"
        disableEnforceFocus={true}
      >
        <div className=" max-w-[558px] w-full m-auto">
          <AssignPopup
            createTagData={createTagData}
            editRowData={editedTagData}
            showTagPopup={showTagPopup}
            setIsOpen={setIsShow}
            modalType="assign"
            options={filterTagData}
            cell={cellData}
            setTags={() => 1}
            data={data}
            setData={setData}
          />
        </div>
      </Modal>
    </div>
  );
};

export default PasswordsTable;
