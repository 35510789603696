import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import persistState from './persistState';
import { User } from '../app/services/users';

export type ContextState = { user: User | null; loading: boolean };

const initialState: ContextState = { user: null, loading: false };

const slice = createSlice({
  name: 'context',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => ({
      ...state,
      user: action.payload,
    }),
    registerLoading: (state, action: PayloadAction) => ({
      ...state,
      loading: true,
    }),
    unRegisterLoading: (state, action: PayloadAction) => ({
      ...state,
      loading: false,
    }),
  },
});

export const { setUser, unRegisterLoading, registerLoading } = slice.actions;

export default persistState('context', slice.reducer);
