import { redirectToLogin } from "./annoucement-sheet";
import { api, getLanguageCode, getLanguageCodeForbackend } from "./api";
import authHeader from "./auth-header";
export type DropdownDataWithLanguage = {
    id: number;
    name_en: string;
    name_tc: string;
    name_sc: string;
  };
  type UsefulLinkResponse = {
    usefulLinkList: TData;
  };
  export type PaginationData = {
    category: string | number | undefined,
    year:string
}
  export type TData = {
    data: DepartmentInfo[];
    total: number;
  };
  export type DepartmentInfo = {
    id?: number;
    name: string;
  };

export const usefulLinesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUsefulLines: build.query<UsefulLinkResponse, void>({
      query: () => ({
        url: `${getLanguageCodeForbackend()}usefullinkList`,
        headers: authHeader(),
      }),
      keepUnusedDataFor:0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
  }),
});

export const {
    useGetUsefulLinesQuery
} = usefulLinesApi;

export const {
  endpoints: {
    getUsefulLines
  },
} = usefulLinesApi;
