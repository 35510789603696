import { redirectToLogin } from "./annoucement-sheet";
import { api, getLanguageCode, getLanguageCodeForbackend } from "./api";
import authHeader from "./auth-header";
export type DropdownDataWithLanguage = {
    id: number;
    name_en: string;
    name_tc: string;
    name_sc: string;
  };
  type FaqResponse = {
    module_id: number;
    status: boolean;
    faqData: TData;
  };
  
  export type TData = {
    data: DepartmentInfo[];
    total: number;
  };
  export type DepartmentInfo = {
    id?: number;
    name: string;
  };

export const faqApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFaqList: build.query<FaqResponse, void>({
      query: () => ({
        url: `${getLanguageCodeForbackend()}getfaqs`,
        headers: authHeader(),
      }),
      keepUnusedDataFor:0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
  }),
});

export const {
    useGetFaqListQuery
} = faqApi;

export const {
  endpoints: {
    getFaqList
  },
} = faqApi;
