import logo from '../../logo.svg';
import LogInComponent from '../components/LogInComponent';

function App(props:any) {
  return (
    <>      
      <LogInComponent logoData={props.logoData}/>
    </>
  );
}

export default App;
