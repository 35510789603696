import { uniqueId } from "lodash";
import {
  EmployeeData,
  EmployeeListData,
  RegionResponse,
  StoreEmployeeType,
  TSaveProject,
} from "../../components/EmployeeTable/EmployeeTable";
import { api, endpointUrl } from "./api";
import authHeader from "./auth-header";
import { redirectToLogin } from "./annoucement-sheet";
import {
  AssignDataSingle,
  RequestUserList,
  ResponseUserList,
} from "./password";
import authHeaderMultipart from "./auth-header-multipart";
import { NumberLiteralType } from "typescript";
export type EmployeeResponse = { data: Employee[] };

export type TAssignDataSingleInProject = {
  employees?: TEmployeeProject[];
  assign_to: TEmployeeProject[];
  ids?: number;
};

export type TEmployeeProject = {
  employee_id: number;
  roles: number[];
};

export type ProjectResponse = {
  module_id: number;
  data: ProjectInfo;
  total: number;
  employee_list: TEmployeeListForProject[];
};
export type ProjectInfo = {
  current_page: number;
  data: Project[];
  total: number;
  paidAmount?: any;
};
export type ProjectFilterResponse = {
  assign_project_role_lists: TRowData[];
  countries: [];
  currencies: CurrencyData[];
  departments: [];
  key_dates: [];
  payment_statuses: [];
  project_statuses: [];
  project_types: [];
  regions: [];
  tags: [];
};
export type TEmployeeListForProject = {
  id: number;
  preferred_name: string;
  year_w_vo: number;
  age: number;
};

export type Employee = {
  module_id: number;
  employeeList: EmployeeListData[];
  statusListData: string[];
  departmentData: EmployeeData[];
  designationData: EmployeeData[];
  nationalityListData: EmployeeData[];
  memberListData: EmployeeData[];
  genderListData: string[];
  currencyListData: CurrencyDataType[];
};

export type Project = {
  uniqeID: number;
  projectCode: string;
  projectName: string;
  date: string;
  duration: string;
  region: Region;
  projectType?: null;
  projectStatus?: null;
  quotationNumber: string;
  confirmOn: string;
  remarks?: null;
  projectScope?: null;
  paymentTerm: number;
  currency: Currency;
  projectAmount: number;
  paymentStatus?: null;
  endDate: string;
  projectCompleted: boolean;
  checkList: CheckList;
  keyDate: KeyDate;
  projectTags: ProjectTags[];
  employees: TEmployee[];
};

export type TEmployeeResponse = {
  employeeList: TEmployee[];
};

export type TRowProjectResponse = {
  status: boolean;
  data: TRowData[];
};

export type TRowData = {
  id: number;
  name: string;
  department_id: number;
  department_name: string;
  value?: string | number;
};

export type TEmployee = {
  id: number;
  code: string;
  preferred_name: string;
  year_w_vo: number;
  age: number;
  name?: string;
};

export type Region = {
  id: number;
  name: string;
  code: string;
  time_zone: string;
  created_at: string;
  updated_at: string;
  deleted_at?: null;
};

export type Currency = {
  uniqeID: number;
  label: string;
  sing: string;
};

export type CheckList = [];
export type KeyDate = [];

export type ProjectTags = {
  id: number;
  name: string;
  created_by: number;
  updated_by: number;
  created_at: string;
  updated_at: string;
  deleted_at?: null;
};

type EmployeeDataList = {
  employeeList: Employee;
};
export type TemplateData = {
  id: number;
  name: string;
  viewColumns: string[];
};
export type CurrencyDataType = {
  id: number;
  currency_name: string;
  currency_code: string;
};

export type DropdownData = {
  id: number;
  name: string;
  project_id?: number;
  quotationNo?: string;
};
export type QuotationData = {
  quotationNo: string;
  project_id: number;
};
export type CurrencyDropdownData = {
  id: number;
  currency_name: string;
  currency_code: string;
};
export type dropdownDesignationList = {
  designationList: EmployeeData[];
};
export type dropdownDepartmentList = {
  departmentList: EmployeeData[];
};
export type dropdownCurrencyList = {
  currencies: CurrencyData[];
};
export type currencyDataType = {
  currencies: CurrencyData[];
};
export type CurrencyData = {
  created_at: string;
  currency_code: string;
  currency_name: string;
  deleted_at: string;
  updated_at: string;
  id: number;
};
export type dropdownCountryList = {
  countryList: EmployeeData[];
  region: EmployeeData[];
};

export type ProjectType = {
  uniqeID: number;
  typeName: string;
};

export type dropdownProjectTypeList = {
  data: EmployeeData[];
};

export type KeyDateDataList = {
  data: KeyDateData[];
};

export type KeyDateData = {
  uniqeID: number;
  label: string;
  flag: string;
};

export type PaginationData = {
  page: number;
  limit: number;
  payment_status: number;
  term: number;
  region: number;
  type: number;
  completed: any;
  status: number;
  search: string;
  deleted_at: string;
  sort?: string;
  status_ids?: string;
};

export type CreateProjectTechT = {
  status: boolean;
  data: any[];
};

export type RBoardResponse = {
  data: TResponseData[];
};

export type TResponseData = {
  id: number;
  name: string;
  items: TItems[];
};

export type TItems = {
  client_review_in_progress: any[];
  client_uat: any[];
  design_done: any[];
  design_inprogress_data: any[];
  development: any[];
  internal_uat: any[];
  launch: any[];
  not_started: any[];
  pj_status: any[];
  planning: any[];
  scope_scrolling: any[];
};

type RequestBoardT = {
  search?: string;
  status_id?: number;
  project_type?: number;
  sort?: string;
  employee_ids?: number;
  key_date_id?: number;
  start?: string;
  end?: string;
  my_project?: number;
};

// const N = 16; // The length of our random boundry string
// const randomBoundryString =
//   "djmangoBoundry" +
//   Array(N + 1)
//     .join((Math.random().toString(36) + "00000000000000000").slice(2, 18))
//     .slice(0, N);

// Construct the form data payload as a string
// const pre_string = `------${randomBoundryString}\r\nContent-Disposition: form-data; name="audio_file"; filename="blob"\r\nContent-Type: "application/octet-stream"\r\n\r\n`;
// const post_string = `\r\n------${randomBoundryString}--`

// Convert the form data payload to a blob by concatenating the pre_string, the file data, and the post_string, and then return the blob as an array buffer
// const pre_string_encoded = new TextEncoder().encode(pre_string);
// const data = new Blob([await this.app.vault.adapter.readBinary(fileToTranscribe.path)]);
// const post_string_encoded = new TextEncoder().encode(post_string);
// const concatenated = await new Blob([pre_string_encoded, await getBlobArrayBuffer(data), post_string_encoded]).arrayBuffer()

export default function authHeaderExcel() {
  const userStr = localStorage.getItem("userData");
  let user = null;
  if (userStr) user = JSON.parse(userStr);
  if (user && user.accessToken) {
    return {
      Authorization: `Bearer ${user.accessToken}`,
      Accept: `application/json`,
      "Content-Type": `multipart/form-data;`, // boundary=----${randomBoundryString}
    };
  } else {
    return { Authorization: "" };
  }
}

export const projectApi = api.injectEndpoints({
  endpoints: (build) => ({
    // getProjectData: build.query<ProjectResponse, void>({
    //     query: () => ({
    //         url: `${endpointUrl}projects`,
    //         headers: authHeader(),
    //     }),
    //     providesTags: (result: any = {}) => [
    //         ...result.data.map(
    //             (id: number) => ({ type: "ProjectListData", id } as const)
    //         ),
    //         { type: "ProjectListData" as const, id: "LIST" },
    //     ],
    // }),

    getProjectData: build.query<ProjectResponse, PaginationData>({
      query: ({
        limit,
        page,
        payment_status,
        completed,
        term,
        region,
        type,
        status,
        search,
        sort,
        deleted_at,
        status_ids,
      }) => ({
        // queryKey: ['ProjectListData', limit, page, payment_status, completed, term, region, type, status],
        url: `${endpointUrl}projects?limit=${limit}&page=${page}&payment_status=${payment_status}&completed=${completed}&term=${term}&region=${region}&type=${type}&status=${status}&search=${search}&sort=${sort}&deleted_at=${deleted_at}&status_ids=${status_ids}`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "ProjectListData", id: "LIST" }],
    }),
    getProjectFilterData: build.query<ProjectFilterResponse, void>({
      query: () => ({
        url: `${endpointUrl}projects-filter-data`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "ProjectListData", id: "LIST" }],
    }),
    createProject: build.mutation<EmployeeListData, Partial<any>>({
      query: (body) => {
        return {
          url: `${endpointUrl}projects`,
          method: "POST",
          body,
          headers: authHeader(),
        };
      },
      invalidatesTags: (projects) => [
        { type: "ProjectListData", id: projects?.id },
      ],
    }),
    createProjectWithFormData: build.mutation<EmployeeListData, Partial<any>>({
      query: (body: any) => {
        var bodyFormData = new FormData();
        bodyFormData.append("project_name", body.project_name);
        bodyFormData.append("company_name", body.company_name);
        bodyFormData.append(
          "primary_contact_person_name",
          body.primary_contact_person_name
        );
        bodyFormData.append(
          "primary_contact_person_dial_code",
          body.primary_contact_person_dial_code
        );
        bodyFormData.append(
          "primary_contact_person_phone",
          body.primary_contact_person_phone
        );
        bodyFormData.append(
          "primary_contact_person_email",
          body.primary_contact_person_email
        );
        bodyFormData.append(
          "secondary_contact_person_name",
          body.secondary_contact_person_name
        );
        bodyFormData.append(
          "secondary_contact_person_dial_code",
          body.secondary_contact_person_dial_code
        );
        bodyFormData.append(
          "secondary_contact_person_phone",
          body.secondary_contact_person_phone
        );
        bodyFormData.append(
          "secondary_contact_person_email",
          body.secondary_contact_person_email
        );
        bodyFormData.append(
          "finance_contact_person_name",
          body.finance_contact_person_name
        );
        bodyFormData.append(
          "finance_contact_person_dial_code",
          body.finance_contact_person_dial_code
        );
        bodyFormData.append(
          "finance_contact_person_phone",
          body.finance_contact_person_phone
        );
        bodyFormData.append("team_leader_id", body.team_leader_id);
        bodyFormData.append("project_manager_id", body.project_manager_id);
        bodyFormData.append("region_id", body.region_id);
        bodyFormData.append("department_id", body.department_id);
        bodyFormData.append("duration_type", body.duration_type);
        bodyFormData.append("duration", body.duration);
        bodyFormData.append("scope", body.scope);
        bodyFormData.append("quotation_no", body.quotation_no);
        bodyFormData.append("brand_level", body.brand_level);
        bodyFormData.append("project_level", body.project_level);
        bodyFormData.append("project_type_ids", body.project_type_ids);
        bodyFormData.append("status_id", body.status_id);
        bodyFormData.append("currency_id", body.currency_id);
        bodyFormData.append("total_amount", body.total_amount);
        bodyFormData.append("payment_term", body.payment_term);
        bodyFormData.append("payment_status_id", body.payment_status_id);
        bodyFormData.append("confirm_date", body.confirm_date);
        bodyFormData.append("start_date", body.start_date);
        bodyFormData.append("end_date", body.end_date);
        bodyFormData.append("is_completed", body.is_completed);
        bodyFormData.append("remark", body.remark);
        bodyFormData.append("payments", body.payments);
        bodyFormData.append("check_lists", body.check_lists);
        bodyFormData.append("key_dates", body.key_dates);
        bodyFormData.append("launch_date", body.launch_date);
        bodyFormData.append("development_url", body.development_url);
        bodyFormData.append("uat_url", body.uat_url);
        bodyFormData.append("live_url", body.live_url);
        bodyFormData.append(
          "current_working_status",
          body.current_working_status
        );
        bodyFormData.append("logo", body.logo);
        // console.log("body ", body, bodyFormData);
        return {
          url: `${endpointUrl}projects`,
          method: "POST",
          body: bodyFormData,
          formData: true,
          headers: authHeaderMultipart(),
        };
      },
      invalidatesTags: (projects) => [
        { type: "ProjectListData", id: projects?.id },
      ],
    }),
    saveUpdatedProject: build.mutation<
      { status: boolean; message: string },
      Partial<TSaveProject>
    >({
      query: (body) => {
        return {
          url: `${endpointUrl}save-project`,
          method: "POST",
          body,
          headers: authHeader(),
        };
      },
      invalidatesTags: () => [{ type: "ProjectListData", id: "LIST" }],
    }),

    saveNewUpdatedProject: build.mutation<
      { status: boolean; message: string },
      Partial<TSaveProject>
    >({
      query: (body) => {
        return {
          url: `${endpointUrl}modified-save-project`,
          method: "POST",
          body,
          headers: authHeader(),
        };
      },
      invalidatesTags: () => [{ type: "ProjectListData", id: "LIST" }],
    }),
    newUpdatedProject: build.mutation<
      { status: boolean; message: string },
      Partial<TSaveProject>
    >({
      query: (body) => {
        // console.log("body", body)
        return {
          url: `${endpointUrl}update-project/${body.id}`,
          method: "PUT",
          body,
          headers: authHeader(),
        };
      },
      invalidatesTags: () => [{ type: "ProjectListData", id: "LIST" }],
    }),

    updateProject: build.mutation<EmployeeListData, Partial<StoreEmployeeType>>(
      {
        query: (body) => {
          return {
            url: `${endpointUrl}projects/${body.id}`,
            method: "PUT",
            body,
            headers: authHeader(),
          };
        },
        invalidatesTags: [{ type: "ProjectListData", id: "LIST" }],
        //invalidatesTags: (employees) => [{ type: 'EmployeeListData', id:employees?.id  }],
      }
    ),

    deleteProject: build.mutation<{ success: boolean; ids: number[] }, number>({
      query(ids) {
        return {
          headers: authHeader(),
          url: `/projects`,
          body: { ids },
          method: "DELETE",
        };
      },
      invalidatesTags: (projects) => [{ type: "ProjectListData", id: "LIST" }],
    }),

    deleteProj: build.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `/projects/${id}`,
          method: "DELETE",
          headers: authHeader(),
        };
      },
      invalidatesTags: (projects) => [{ type: "ProjectListData", id: "LIST" }],
    }),

    getRegionList: build.query<any, void>({
      query: () => ({
        url: `${endpointUrl}regions`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      // transformResponse: (res: dropdownCountryList) => res.region,
      // providesTags: (result = []) => [
      //     ...result.map(() => ({ type: "Regions" } as const)),
      //     { type: "RegionList" as const, id: "LIST" },
      // ],
      providesTags: () => [{ type: "RegionsList" as const, id: "LIST" }],
    }),

    createRegion: build.mutation<DropdownData, Partial<DropdownData>>({
      query: (body) => {
        return {
          url: `${endpointUrl}regions`,
          method: "POST",
          body,
          headers: authHeader(),
        };
      },
      invalidatesTags: (data) => [{ type: "RegionsList", id: "LIST" }],
    }),

    deleteRegion: build.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `/regions/${id}`,
          method: "DELETE",
          headers: authHeader(),
        };
      },
      invalidatesTags: (regions) => [{ type: "RegionsList", id: "LIST" }],
    }),

    getPaymentStatus: build.query<EmployeeData[], void>({
      query: () => ({
        url: `${endpointUrl}paymentStatus`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: dropdownProjectTypeList) => res.data,
      providesTags: (result = []) => [
        ...result.map(() => ({ type: "PaymentStatusList" } as const)),
        { type: "PaymentStatusList" as const, id: "LIST" },
      ],
    }),

    getProjectTypes: build.query<EmployeeData[], void>({
      query: () => ({
        url: `${endpointUrl}projectTypes`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: dropdownProjectTypeList) => res.data,
      // providesTags: (result = []) => [
      //     ...result.map(() => ({ type: "ProjectTypeList" } as const)),
      //     { type: "ProjectTypeList" as const, id: "LIST" },
      // ]
      providesTags: () => [{ type: "ProjectTypeList" as const, id: "LIST" }],
    }),
    createPJType: build.mutation<DropdownData, Partial<DropdownData>>({
      query: (body) => {
        return {
          url: `${endpointUrl}projectTypes`,
          method: "POST",
          body,
          headers: authHeader(),
        };
      },
      invalidatesTags: (types) => [
        { type: "ProjectTypeList" as const, id: "LIST" },
      ],
    }),
    deletePJType: build.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `/projectTypes/${id}`,
          method: "DELETE",
          headers: authHeader(),
        };
      },
      invalidatesTags: (types) => [
        { type: "ProjectTypeList" as const, id: "LIST" },
      ],
    }),
    getProjectStatus: build.query<EmployeeData[], void>({
      query: () => ({
        url: `${endpointUrl}projectStatuses`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: dropdownProjectTypeList) => res.data,
      providesTags: (result = []) => [
        ...result.map(() => ({ type: "ProjectStatusList" } as const)),
        { type: "ProjectStatusList" as const, id: "LIST" },
      ],
    }),
    createPJStatus: build.mutation<DropdownData, Partial<DropdownData>>({
      query: (body) => {
        return {
          url: `${endpointUrl}projectStatuses`,
          method: "POST",
          body,
          headers: authHeader(),
        };
      },
      invalidatesTags: (data) => [{ type: "ProjectStatusList", id: data?.id }],
    }),
    deletePJStatus: build.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `/projectStatuses/${id}`,
          method: "DELETE",
          headers: authHeader(),
        };
      },
      invalidatesTags: (status) => [{ type: "ProjectStatusList", id: "LIST" }],
    }),
    //for currency
    createCurrency: build.mutation<
      CurrencyDropdownData,
      Partial<CurrencyDropdownData>
    >({
      query: (body) => {
        return {
          url: `${endpointUrl}currencies`,
          method: "POST",
          body,
          headers: authHeader(),
        };
      },
      invalidatesTags: (data) => [{ type: "CurrencyList", id: data?.id }],
    }),
    deleteCurrency: build.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `/deleteCurrency/${id}`,
          method: "DELETE",
          headers: authHeader(),
        };
      },
      invalidatesTags: (currencies) => [{ type: "CurrencyList", id: "LIST" }],
    }),
    //for curency
    createPaymentStatus: build.mutation<DropdownData, Partial<DropdownData>>({
      query: (body) => {
        return {
          url: `${endpointUrl}paymentStatus`,
          method: "POST",
          body,
          headers: authHeader(),
        };
      },
      invalidatesTags: (data) => [{ type: "PaymentStatusList", id: "LIST" }],
    }),
    createQuotationNo: build.mutation<DropdownData, Partial<DropdownData>>({
      query: (body) => {
        return {
          url: `${endpointUrl}addQuotationNo`,
          method: "POST",
          body,
          headers: authHeader(),
        };
      },
      invalidatesTags: (data) => [{ type: "QuotationNoList", id: data?.id }],
    }),
    deleteQuotationNo: build.mutation<DropdownData, Partial<QuotationData>>({
      query: (body) => {
        return {
          url: `${endpointUrl}deletequotation`,
          method: "POST",
          body,
          headers: authHeader(),
        };
      },
      invalidatesTags: (data) => [{ type: "QuotationNoList", id: data?.id }],
    }),
    createCheckList: build.mutation<EmployeeData, Partial<EmployeeData>>({
      query: (body) => {
        return {
          url: `${endpointUrl}checklists`,
          method: "POST",
          body,
          headers: authHeader(),
        };
      },
      invalidatesTags: (data) => [{ type: "CheckList", id: data?.id }],
    }),
    getKeyDate: build.query<EmployeeData[], void>({
      
      query: () => ({
        url: `${endpointUrl}keydates`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: dropdownProjectTypeList) => res.data,
      providesTags: (result = []) => [
        ...result?.map(() => ({ type: "KeyDateList" } as const)),
        { type: "KeyDateList" as const, uniqeID: "LIST" },
      ],
    }),
    getKeyDateData: build.query<EmployeeData[], void>({
      query: () => ({
        url: `${endpointUrl}dashboard/keydates`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: dropdownProjectTypeList) => res.data,
      providesTags: (result = []) => [
        ...result.map(() => ({ type: "KeyDateDataList" } as const)),
        { type: "KeyDateDataList" as const, uniqeID: "LIST" },
      ],
    }),
    createKeyDate: build.mutation<EmployeeData, Partial<EmployeeData>>({
      query: (body) => {
        return {
          url: `${endpointUrl}keydates`,
          method: "POST",
          body,
          headers: authHeader(),
        };
      },
      invalidatesTags: (data) => [{ type: "KeyDateList", id: data?.id }],
    }),
    removeKeyDate: build.mutation<EmployeeData, number>({
      query: (id) => {
        return {
          url: `${endpointUrl}keydates/${id}`,
          method: "DELETE",
          headers: authHeader(),
        };
      },
      invalidatesTags: (data) => [{ type: "KeyDateList", id: data?.id }],
    }),
    createPayment: build.mutation<EmployeeData, Partial<EmployeeData>>({
      query: (body) => {
        return {
          url: `${endpointUrl}payments`,
          method: "POST",
          body,
          headers: authHeader(),
        };
      },
      invalidatesTags: (data) => [{ type: "PaymentList", id: data?.id }],
    }),
    getPaymentData: build.query<EmployeeData[], Partial<EmployeeData>>({
      query: ({ id }) => ({
        url: `${endpointUrl}payments?project=${id}`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: dropdownProjectTypeList) => res.data,
      providesTags: (result = []) => [
        ...result.map(() => ({ type: "PaymentList" } as const)),
        { type: "PaymentList" as const, id: "LIST" },
      ],
    }),
    multiDeleteProject: build.mutation<
      { success: boolean; ids: number[] },
      number[]
    >({
      query(ids) {
        return {
          headers: authHeader(),
          url: `/deleteProjects`,
          body: { ids },
          method: "DELETE",
        };
      },
      invalidatesTags: (projects) => [{ type: "ProjectListData", id: "LIST" }],
    }),

    deletePaymentStatus: build.mutation<
      { success: boolean; id: number },
      number
    >({
      query(id) {
        return {
          url: `/paymentStatus/${id}`,
          method: "DELETE",
          headers: authHeader(),
        };
      },
      invalidatesTags: (payment) => [{ type: "PaymentStatusList", id: "LIST" }],
    }),
    createImportProject: build.mutation<any, any>({
      query: (body) => {
        return {
          url: `${endpointUrl}projecstImport`,
          headers: authHeaderExcel(),
          method: "POST",
          body,
        };
      },
      invalidatesTags: (data) => [{ type: "ImportProjects" }],
    }),

    getEmployeeListAssign: build.query<TEmployeeResponse, RequestUserList>({
      query: ({ search, department_id }) => ({
        url: `${endpointUrl}employee-list?department_id=${department_id}&search=${search}`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: any) => {
        return res;
      },
      providesTags: () => [{ type: "EmployeeAssign" }],
    }),

    assignEmployee: build.mutation<
      { status: boolean },
      Partial<TAssignDataSingleInProject>
    >({
      query: (body) => ({
        url: `${endpointUrl}projects/${body.ids}/assign-employees`,
        method: "POST",
        body,
        headers: authHeader(),
      }),
      invalidatesTags: () => [{ type: "ProjectListData", id: "LIST" }],
    }),
    restoreProjects: build.mutation<{ status: boolean }, number>({
      query: (id) => ({
        url: `${endpointUrl}projects/${id}/restore`,
        method: "POST",
        headers: authHeader(),
      }),
      invalidatesTags: () => [{ type: "ProjectListData", id: "LIST" }],
    }),

    getAssignRowsProject: build.query<TRowProjectResponse, void>({
      query: () => ({
        url: `${endpointUrl}assign-project-roles-list`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: any) => {
        return res;
      },
      providesTags: () => [{ type: "EmployeeAssignRow" }],
    }),

    createProjectTech: build.mutation<any, any>({
      query: (body) => {
        return {
          url: `${endpointUrl}projectTypes`,
          method: "POST",
          body,
          headers: authHeader(),
        };
      },
      invalidatesTags: () => [{ type: "ProjectTech", id: "LIST" }],
    }),

    getProjectBoard: build.query<RBoardResponse, RequestBoardT>({
      query: ({
        search,
        status_id,
        project_type,
        sort,
        employee_ids,
        key_date_id,
        start,
        end,
        my_project,
      }) => ({
        url: `${endpointUrl}project-board?search=${search}&status_ids=${status_id}&project_type_ids=${project_type}&sort=${sort}&employee_ids=${employee_ids}&key_date_id=${key_date_id}&start=${start}&end=${end}&my_project=${my_project}`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: any) => {
        return res;
      },
      providesTags: () => [{ type: "ProjectListData", id: "LIST" }],
    }),

    //for deatila api route
    getProjectBoardDetail: build.query<any, any>({
      query: ({ id }) => ({
        url: `${endpointUrl}project-board-card-detail/${id}`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: any) => {
        return res;
      },
      providesTags: () => [{ type: "ProjectBoardFilter" }],
    }),
    //for detail route

    getProjectBoardStatus: build.query<any, void>({
      query: () => ({
        url: `${endpointUrl}project-board-filter-data`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: any) => {
        return res;
      },
      providesTags: () => [{ type: "ProjectBoardStatus" }],
    }),

    updateProjectBoard: build.mutation<any, Partial<any>>({
      query: (body) => {
        return {
          url: `${endpointUrl}project-board-draggable`,
          method: "PATCH",
          body: body,
          // body: {from: body.from, to: body.to, index: body.index, project_json: body.project_json},
          // body: {status_id: body.status_id, project_json: body.project_json},
          headers: authHeader(),
        };
      },
      invalidatesTags: [{ type: "ProjectListData", id: "LIST" }],
    }),

    getProjectBoardFilter: build.query<any, void>({
      query: () => ({
        url: `${endpointUrl}project-board-filter-data`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: any) => {
        return res;
      },
      providesTags: () => [{ type: "ProjectBoardFilter" }],
    }),

    updatedProjectPayment: build.mutation<any, any>({
      query: (body) => {
        return {
          url: `${endpointUrl}payments/${body.project_id}/receive`,
          method: "PATCH",
          body,
          headers: authHeader(),
        };
      },
      invalidatesTags: () => [{ type: "ProjectListData", id: "LIST" }],
    }),
  }),
});

export const {
  useUpdatedProjectPaymentMutation,
  useGetProjectBoardStatusQuery,
  useGetProjectBoardFilterQuery,
  useGetProjectBoardQuery,
  useGetProjectDataQuery,
  useCreateProjectMutation,
  useUpdateProjectBoardMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useDeleteProjMutation,
  // useGetCountryListQuery,
  useGetRegionListQuery,
  useGetProjectTypesQuery,
  useGetProjectStatusQuery,
  useGetPaymentStatusQuery,
  useCreateRegionMutation,
  useCreatePJTypeMutation,
  useCreatePJStatusMutation,
  useCreatePaymentStatusMutation,
  useCreateCurrencyMutation,
  useCreateCheckListMutation,
  useCreateQuotationNoMutation,
  useGetKeyDateQuery,
  useCreateKeyDateMutation,
  useCreatePaymentMutation,
  useGetPaymentDataQuery,
  useDeleteRegionMutation,
  useDeletePJTypeMutation,
  useDeletePJStatusMutation,
  useDeleteCurrencyMutation,
  useDeletePaymentStatusMutation,
  useMultiDeleteProjectMutation,
  useCreateImportProjectMutation,
  useDeleteQuotationNoMutation,
  useRemoveKeyDateMutation,
  useGetEmployeeListAssignQuery,
  useGetAssignRowsProjectQuery,
  useAssignEmployeeMutation,
  useRestoreProjectsMutation,
  useSaveUpdatedProjectMutation,
  useSaveNewUpdatedProjectMutation,
  useNewUpdatedProjectMutation,
  useGetProjectFilterDataQuery,
  useCreateProjectTechMutation,
  useCreateProjectWithFormDataMutation,
  useGetKeyDateDataQuery,
  useGetProjectBoardDetailQuery,
} = projectApi;

export const {
  endpoints: {
    getProjectBoardDetail,
    updatedProjectPayment,
    getProjectBoardFilter,
    getProjectBoardStatus,
    updateProjectBoard,
    getProjectBoard,
    createProjectTech,
    saveUpdatedProject,
    saveNewUpdatedProject,
    newUpdatedProject,
    assignEmployee,
    getProjectData,
    createProject,
    deleteProject,
    deleteProj,
    // getCountryList,
    getRegionList,
    getProjectTypes,
    getProjectStatus,
    getPaymentStatus,
    createRegion,
    createPJType,
    createPJStatus,
    createPaymentStatus,
    createCurrency,
    createCheckList,
    createQuotationNo,
    getKeyDate,
    createKeyDate,
    updateProject,
    createPayment,
    getPaymentData,
    deleteRegion,
    deletePJType,
    deletePJStatus,
    deleteCurrency,
    deletePaymentStatus,
    multiDeleteProject,
    createImportProject,
    deleteQuotationNo,
    removeKeyDate,
    getEmployeeListAssign,
    restoreProjects,
    getProjectFilterData,
    createProjectWithFormData,
    getKeyDateData,
    // deleteTemplateData,
    // getTemplateList,
    // updateEmployee,
    // // createDepartment,
    // createDesignation,
    // getDepartmentList,
    // getDesignationList,
    // // getCurrencyList,
    // createCountry
  },
} = projectApi;
