import { FC, useEffect, useState, useMemo, useCallback } from "react";
import Button from "../../components/common/Button";
// import { useCookies } from "react-cookie";
import {
  AddIcon,
  CustomizeLayoutIcon,
  // ExportIcon,
  ImportIcon,
  SearchIcon,
} from "../../components/common/Icons";
import { NumberDropdownList } from "../../components/common/DropdownList";
import { baseObj } from "../../utils/constants";
import ImportUsersModal from "../../components/BackendUsers/ImportUsersModal";
// import ExportUsersModal from "../../components/BackendUsers/ExportUsersTable";
import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import PasswordListStyles from "./PasswordList.module.scss";
import PasswordsTable from "../../components/Passwords/PasswordsTable";
import {
  KeyValueLabel,
  PasswordDataHR,
  PasswordUserList,
  useAssignPasswordsMutation,
  useAssignPasswordMutation,
  useCreateHRPasswordMutation,
  useGetPasswordsQuery,
  useGetSeePasswordMutation,
  useGetSeeOnePasswordMutation,
  // IOnePasswordResponse,
  useGetPasswordKeyQuery,
  useGetPasswordDataMutation,
  useGetIsPwdKeyMutation,
  useGetUserListAssignQuery,
  assignUsers,
  useGetPasswordInformationQuery,
  useGetPasswordsFilterDataQuery,
  PasswordProject,
} from "../../app/services/password";
import { StringDropdownListWithDeleteBtn } from "../../components/common/StringDropdownListWithDeleteBtn";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import { getAuthUser } from "../../app/services/dashboard";
import { useGetByModuleTemplateQuery } from "../../app/services/template";
import {
  useCreateTagMutation,
  useGetTagsDataQuery,
} from "../../app/services/annoucement-sheet";
import PasswordForm from "../../components/DashboardProfile/MyPasswords/PasswordForm";
// import { makeStyles } from '@mui/styles';
import { Modal } from "@mui/material";
// import { EmployeeListData } from "../../components/EmployeeTable/EmployeeTable";
import { TModalState } from "../../components/Projects/ProjectsTable";
// import { Modals } from "../../components/Projects/Modals";
import { ModalPwd } from "../../components/Passwords/ModalPwd";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
//import SuccessPopup from "../../components/SuccessPopup/SuccessPopup";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
// import EditPasswordForm from "../../components/DashboardProfile/MyPasswords/EditPasswordForm";
import { useNavigate } from "react-router-dom";
import { FetchBaseError } from "../../components/common/FetchBaseQueryError";
import classNames from "classnames";
import CustomScrollbar from "../../components/CustomScrollbar/CustomScrollbar";
import CustomDropdownListForFilter from "../../components/EmployeeTable/CustomDropdownListForFilter";
import { useSpring, animated } from "react-spring";
//import AddPasswordModal from "../../components/Passwords/AddPasswordModal";
// import Modal from "../../components/Passwords/Modal";
//import { AnimatePresence } from "framer-motion";
// import { Modal } from 'react-responsive-modal';
// import { Modal } from 'react-responsive-modal';
import "react-responsive-modal/styles.css";
import {
  checkedIsNew,
  useGetDepartmentListQuery,
} from "../../app/services/employee";
import CommonTableSkeleton from "../../components/TableSkeleton/CommonTableSkeleton";
import { ToastContainer, toast } from "react-toastify";
// import Astyles from '../../components/DashboardAnnoucement/Custom-animation.module.scss';
// import CustomModal from "../../components/DashboardAnnoucement/CustomModal";
// import { enc, AES } from 'crypto-js';
// var CryptoJS = require("crypto-js");
import { JSEncrypt } from "jsencrypt";
import { EmployeeData } from "../../components/EmployeeTable/EmployeeTable";
import ResuableToasty from "../../components/Toasty/ResuableToast";

interface IPasswordList { }

export const MODALS = {
  SHOW_PWD: "showPassword",
};

export const initialModalState = {
  [MODALS.SHOW_PWD]: false,
};

// const useStyles = makeStyles({
//   customTransition: {
//     transitionDuration: '2s',
//     transitionTimingFunction: 'ease-out',
//     // Add any other custom transition properties
//   },
// });
const PasswordList: FC<IPasswordList> = () => {
  //const classes = useStyles();
  // const [filterStates, setFilterStates] = useState<IFilterStates>({
  //   department: "All",
  //   assignTo: "All",
  // });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [sortDirection, setSortDirection] = useState<string>("asc");
  const [assignDepartmentId, setAssignDepartmentId] = useState<string>("all");
  const [assignKeword, setAssignKeword] = useState<string>("");
  const { data: assignUserList } = useGetUserListAssignQuery({
    search: assignKeword,
    department_id: assignDepartmentId,
  });
  const { data: departments } = useGetDepartmentListQuery();
  const [keyData, setKeyData] = useState<string>();
  const { data: keyDatas } = useGetPasswordInformationQuery();
  const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isAddPasswordModalOpen, setIsPasswordModalOpen] =
    useState<boolean>(false);
  const [isLayoutControlOpen, setIsLayoutControlOpen] =
    useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [itemCount, setItemCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const navigate = useNavigate();
  const [onePwd, setOnePwd] = useState<boolean>(false);
  const [show_one_pwd, setShow_One_Pwd] = useState<boolean>(false);
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [currentView, setCurrentView] = useState<any>(0);
  const [showPasswords, setShowPasswords] = useState<boolean>(false);
  const [projects, setProjects] = useState<PasswordProject[]>([]);
  const [departmentList, setDepartmentList] = useState<EmployeeData[]>([]);
  const [assignUserListData, setAssignUserListData] = useState<assignUsers[]>(
    []
  );
  const [shareToFilter, setShareToFilter] = useState<string>("");
  const [createdByFilter, setCreatedByFilter] = useState<string>("");
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  const [limit, setLimit] = useState<number>(20);
  const [filterTag, setFilterTag] = useState("");
  const [isEditing, setIsEdit] = useState<boolean>(false);
  const [privateKey, setPrivateKey] = useState<string | undefined>();
  const [sortData, setSortData] = useState<string>("");
  const [project, setProject] = useState<string>('All');
  // const updateFilterState = useCallback(
  //   (value: string, key: string) => {
  //     setFilterStates((prev) => ({ ...prev, [key]: value }));
  //   },
  //   [setFilterStates]
  // );
  const {
    data: passwordData,
    isLoading,
    error,
  } = useGetPasswordsQuery({
    page: currentPage,
    limit: limit,
    tag: filterTag,
    share_to: shareToFilter,
    search: globalSearch,
    created_by: createdByFilter,
    project_id:project,
    sort: sorting
      .map(
        (s) =>
          `${s.desc ? "-" : ""}${s.id == "createdBy" ? "created_by" : s.id}`
      )
      .join(","),
  });
  const { data: filterData } = useGetPasswordsFilterDataQuery();
  const [getClickassword] = useGetPasswordDataMutation();
  var encrypt: any = new JSEncrypt();
  const [isKey] = useGetIsPwdKeyMutation();
  const [data, setData] = useState<baseObj[]>([]);
  const [eyeClick, setEyeClick] = useState<boolean>(false);
  const [tempList, setTempList] = useState<any>(templateList);
  const [getKey, setGetKey] = useState<boolean>(false);
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const { data: templateData } = useGetByModuleTemplateQuery(localModuleId);
  const [moduleList, setModuleList] = useState<any>([]);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  // const [templatePlaceholder, setTemplatePlaceholder] = useState<string>("---");
  const [viewCols, setCols] = useState<any>([]);
  const [currentViewData, setCurrentViewData] = useState<number>(0);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [modalState, setModalState] = useState<TModalState>(initialModalState);
  const [modalRow, setModalRow] = useState<number>(0);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const defaultFields = useMemo(
    () => [
      "company",
      "project",
      "platform",
      "account",
      "password",
      "links",
      "createdBy",
      "tags",
      "assignUsers",
    ],
    []
  );
  const [checkNewPwd, setCheckNewPwd] = useState<boolean>(false);
  const [checkImport, setCheckImport] = useState<boolean>(false);
  const [managePassword, setManagePassword] = useState<boolean>(false);
  const [showCreatedByFilter, setShowCreatedByFilter] = useState<boolean>(false);
  const [pageOne, SetPageOne] = useState<boolean>(false);
  const [createHRPassword] = useCreateHRPasswordMutation();
  const [seePasswordData, { error: allError, isLoading: allLoading }] =
    useGetSeePasswordMutation();
  const [seeOnePasswordData, { error: oneError }] =
    useGetSeeOnePasswordMutation();
  const [assignPasswords] = useAssignPasswordsMutation();
  const [assignPassword] = useAssignPasswordMutation();
  var getUser = getAuthUser();
  const getAuthPermis: string[] = useMemo(() => [], []);

  if (getUser?.permissions) {
    getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }
  const [createTag] = useCreateTagMutation();
  const [filterTagData, setFilterTagData] = useState<KeyValueLabel[]>([]);
  const [customLoading, setCustomLoading] = useState<boolean>(false);

  // useEffect(() => {
  //   if (keyDatas) {
  //     setKeyData(keyDatas?.public_key);
  //     setFilterTagData([
  //       { name: "All", id: 0, label: "All" },
  //       ...keyDatas?.tagsData?.filter(
  //         (x: any) => x.flag === "pwd" || x.flag === null
  //       ),
  //     ]);
  //   }
  // }, [keyDatas]);

  useEffect(() => {
    const request = indexedDB.open("myDatabase", 1);
    request.onsuccess = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      const transaction = db.transaction(["myStore"], "readonly");
      const store = transaction.objectStore("myStore");
      const getRequest = store.getAll(); // Assuming the ID of the data you want to retrieve is 1
      getRequest.onsuccess = (event) => {
        const data = (event.target as IDBRequest).result;
        if (data !== undefined) {
          setPrivateKey(data[0].privateKey);
        } else {
          setErrorPopup(true);
          setMessage(["Your Key are missing!. Trying again."]);
        }
      };
      getRequest.onerror = (event) => {
        console.error(
          "Error retrieving data",
          (event.target as IDBOpenDBRequest).error
        );
        // setErrorPopup(true);
        // setMessage(["Your Key are missing!. Trying again."]);
      };
    };
  }, [getUser, getAuthPermis, modalRow]);

  var decrypt = new JSEncrypt();
  if (privateKey !== undefined) {
    decrypt.setPrivateKey(privateKey);
  }

  useEffect(() => {
    if (privateKey !== undefined) {
      decrypt.setPrivateKey(privateKey);
    }
  }, [privateKey])

  var publicKey: string | undefined = keyData;


  useEffect(() => {
    const Create_Password = getAuthPermis.filter(
      (f) => f === "Create_New_Password"
    );
    const filterImport = getAuthPermis.filter((f) => f === "Import_Password");
    const managePassword = getAuthPermis.filter((f) => f === "Manage_Password");
    const show_createdByFilter = getAuthPermis.find((f) => f === "View_Password");
    if (show_createdByFilter) {
      setShowCreatedByFilter(true)
    }

    if (managePassword.length > 0) {
      setManagePassword(true);
    }
    if (filterImport.length > 0) {
      setCheckImport(true);
    }
    if (Create_Password.length > 0) {
      setCheckNewPwd(true);
    }
  }, [getUser, getAuthPermis]);

  useEffect(() => {
    if (passwordData) {
      setData(passwordData?.passwords?.data);
      setLocalModuleId(passwordData?.module_id);
      setItemCount(passwordData?.passwords?.total);
      setCustomLoading(false);
    } else {
      setCustomLoading(true);
    }
  }, [passwordData, currentView, hiddenFields]);
  useEffect(() => {
    if (filterData) {
      setProjects(filterData?.projects);
      setDepartmentList(filterData.departments.map((dept) => {
        return {
          id: dept.id,
          name: dept.name,
          label: dept.name
        }
      }));
      setFilterTagData([
        { name: "All", id: 0, label: "All" },
        ...filterData?.tagsData
      ]);
      setKeyData(filterData?.public_key);
    }
  }, [filterData])

  useEffect(() => {
    if (templateData) {
      var updatedTemplate = templateData?.templates.map((x: any, i: any) => {
        return { ...x, index: i + 1 };
      });
      var defaultTemplate = {
        id: -1,
        index: 0,
        module: 0,
        name: "All",
      };
      setModuleList([defaultTemplate, ...updatedTemplate]);
    }
  }, [templateData]);

  useEffect(() => {
    if (assignUserList) {
      setAssignUserListData(assignUserList.users);
    }
    // if (departments) {
    //   setDepartmentList([
    //     { name: "All Departments", id: 0, label: "All Departments" },
    //     ...departments,
    //   ]);
    // }
  }, [assignUserList, departments]);

  const deleteTemplateData = (id: string) => {
    var filterTempList = templateList.filter((x) => x.id !== parseInt(id));
    setTempList(filterTempList);
  };

  useEffect(() => {
    if (templateList) {
      setTempList(templateList);
    }
    var viewData = templateData?.templates.find(
      (x: any) => x.id.toString() === currentView.toString()
    );
    setHiddenFields(viewData ? [...viewData.viewColumns] : defaultFields);
    // document.querySelectorAll("th,td").forEach((x) => {
    //   if (x.id.includes("none")) {
    //     x.classList.add("hidden");
    //   }
    //   if (x.id.includes("action")) {
    //     x.classList.add("absolute");
    //     x.classList.add("right-0");
    //   }
    //   x.classList.remove("absolute");
    //   x.classList.add("left-0");
    //   x.classList.add("relative");
    //   var div = document.getElementById(x.id);
    //   if (div) {
    //     div.style.setProperty("left", "");
    //   }
    // });
    // setTemplatePlaceholder(currentView);
  }, [currentView, defaultFields]); //templateList,

  useEffect(() => {
    if (viewCols?.view_columns?.length > 0) {
      setHiddenFields(viewCols?.view_columns);
    }
  }, [viewCols?.view_columns]);

  useEffect(() => {
    if (moduleList) {
      var lastesTemplate = moduleList[moduleList?.length - 1];
      setCurrentViewData(lastesTemplate ? Number(lastesTemplate?.id) : 0);
    }
  }, [moduleList]); //isTemplate &&

  const modalStateHandler = (target: string) => {
    setModalState((prev) => ({ ...prev, [target]: true }));
  };
  const saveNewPassword = (data: any) => {
    var hrPassword: PasswordDataHR = {
      account: data.account,
      links: data.links,
      password: data.password,
      platform: data.platform,
      company: data.company,
      tags: data.tags?.map((t: any) => t.id),
      user_ids: [],
      project_id: typeof(data.project)=="object"?data.project?.project_id:data.project
    };
    createHRPassword(hrPassword)
      .unwrap()
      .then((payload: any) => {
        if (payload.status) {
          setIsSuccess(true);
          setIsPasswordModalOpen(false);
          setSuccessPopup(true);
          setMessage([payload?.message]);
        }
      })
      .catch((error: any) => {
        setIsSuccess(false);
        setIsPasswordModalOpen(true);
        setErrorPopup(true);
        setMessage(error ? [error?.data?.message] : ["Something went wrong!"]);
      });
  };

  const handleGetAllKey = () => {
    isKey()
      .then((res: any) => {
        if (res.data.can_see) {
          setGetKey(false);
          seePasswordData({
            page: currentPage,
            limit: limit,
            tag: filterTag,
            share_to: shareToFilter,
            search: globalSearch,
          }).then((res: any) => {
            setGetKey(false);
            if (res?.data?.status) {
              let rest = res?.data?.passwords?.data;
              if (res) {
                const decodeData = rest.map((data: any, index: number) => ({
                  ...data,
                  password: decrypt.decrypt(data.password),
                }));
                setData(decodeData);
              }
              setEyeClick(true);
              setIsSuccess(true);
            } else if (Number(res?.error.status) === 403) {
              setIsError(true);
            }
          });
        } else {
          setGetKey(true);
        }
      })
      .catch((err: any) => { });
  };

  const handleOneGetKey = (id: number) => {
    isKey()
      .then((res: any) => {
        if (res.data.can_see) {
          setGetKey(false);
          seeOnePasswordData({ id: id }).then((res: any) => {
            if (res?.data?.status) {
              var rest = res?.data?.password;
              setData((prev: any) => {
                return prev.map((data: any, index: number) => {
                  if (Number(rest.id) === Number(data.id)) {
                    return {
                      ...data,
                      password: decrypt.decrypt(rest.password),
                      is_show: 1,
                    };
                  }
                  return data;
                });
              });
              setShow_One_Pwd(res?.data?.show_password);
              setOnePwd(false);
              setEyeClick(true);
              setShowPasswords(false);
              setIsSuccess(true);
            } else if (Number(res?.error.status) === 403) {
              setIsError(true);
            }
          });
        } else {
          setGetKey(true);
        }
      })
      .catch((err: any) => { });
  };

  const getAllSeePwd = async (values?: any) => {
    if (publicKey !== undefined) {
      encrypt.setPublicKey(publicKey);
    }
    var encrypted = encrypt.encrypt(values);

    if (onePwd) {
      await seeOnePasswordData({
        password: encrypted,
        id: modalRow,
        page: currentPage,
        limit: limit,
        tag: filterTag,
        share_to: shareToFilter,
        search: globalSearch,
      })
        .unwrap()
        .then((res: any) => {
          var rest = res.password;
          setShowPasswords(false);
          setIsError(true);
          setGetKey(false);
          setData((prev: any) => {
            return prev.map((data: any, index: number) => {
              if (Number(rest.id) === Number(data.id)) {
                return {
                  ...data,
                  password: decrypt.decrypt(rest.password),
                  is_show: 1,
                };
              }
              return data;
            });
          });
          setShow_One_Pwd(res?.data?.show_password);
          setOnePwd(false);
          setEyeClick(true);
          setShowPasswords(false);
          setIsSuccess(true);
        })
        .catch((error: any) => {
          setIsSuccess(false);
          setErrorPopup(true);
          setMessage(["Authentication Fail. Try Again!!"]);
        });
    } else if (showPasswords) {
      await seePasswordData({
        password: encrypted,
        page: currentPage,
        limit: limit,
        tag: filterTag,
        share_to: shareToFilter,
        search: globalSearch,
      })
        .unwrap()
        .then((res: any) => {
          setShowPasswords(false);
          let rest = res.passwords.data;
          setGetKey(false);
          if (rest) {
            const decodeData = rest.map((data: any, index: number) => ({
              ...data,
              password: decrypt.decrypt(data.password),
            }));
            setData(decodeData);
          }
          setEyeClick(true);
          setIsSuccess(true);
          setShowPasswords(false);
        })
        .catch((error: any) => {
          setIsSuccess(false);
          setErrorPopup(true);
          setMessage(["Authentication Fail. Try Again!!"]);
        });
    }
  };

  const handleClickBtn = () => {
    setGetKey(false);
    if (showPasswords === true || eyeClick === true) {
      getClickassword({ limit: limit })
        .then((data: any) => {
          setData(data.data.passwords.data);
          SetPageOne(true);
        })
        .catch((error) => { });
    }
    handleCloseEye();
    // setShowPasswordData(false);
    setOnePwd(false);
    setIsSuccess(false);
  };

  const handleCloseEye = useCallback(
    (id?: number) => {
      if (id !== 0) {
        setData((prev: any) => {
          return prev.map((data: any, index: number) => {
            if (Number(id) === Number(data.id)) {
              return {
                ...data,
                password: "***********",
                is_show: 0,
              };
            }
            return data;
          });
        });
      } else {
        setData((prev: any) =>
          prev.map((item: any) => {
            return { ...item, password: "***********", is_show: 0 };
          })
        );
      }
    },
    [setData]
  );

  const downloadSampleFile = () => {
    axios({
      url: endpointUrl + "downloadPasswordsSample",
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "samplePassword.xlsx");
      document.body.appendChild(link);
      link.click();
      setIsDownloading(false);
    });
  };

  const importPassword = async (formSaveData: any) => {
    // setFetch(true)
    await axios(endpointUrl + "importPasswords", {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getUser.token}`,
      },
      data: formSaveData,
    })
      .then((res) => {
        axios.get(`${endpointUrl}passwordsListData`, {}).then((result) => {
          var pwdUpdatedData = result?.data?.passwords?.data;
          setData(pwdUpdatedData);
          setIsImporting(true);
          setIsImportModalOpen(false);
          ResuableToasty({ message: "Successfully Import", position: "top-right", type: "success" });
        });
      })
      .catch((error: any) => {
        setErrorPopup(true);
        setMessage(
          error?.response
            ? [error?.response?.data?.message]
            : ["Something wrong!. Trying again."]
        );
        setIsImportModalOpen(false);
      });
    // setFetch(false)
  };

  // const exportPwdData = (format: string) => {
  //   axios({
  //     url: endpointUrl + "exportPasswords?format=" + format + "",
  //     method: "GET",
  //     responseType: "blob",
  //     headers: {
  //       Authorization: `Bearer ${getUser.token}`,
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then((response) => {
  //       const url = window.URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute(
  //         "download",
  //         "passwords." + (format === "excel" ? "xlsx" : format)
  //       );
  //       document.body.appendChild(link);
  //       link.click();
  //       setIsExporting(false);
  //       setIsExportModalOpen(false);
  //     })
  //     .catch((error: any) => {
  //       setErrorPopup(true);
  //       setMessage(
  //         error?.message
  //           ? [error?.message]
  //           : ["Something wrong!. Trying again."]
  //       );
  //       setIsImportModalOpen(false);
  //     });
  // };
  const changePage = (pageNo: number) => { };
  useEffect(() => {
    if (successPopup) {
      ResuableToasty({ message: "Done Successfully", position: "top-right", type: "success" });
    }

    setTimeout(() => {
      setSuccessPopup(false);
    }, 2000);
  }, [successPopup]);

  useEffect(() => {
    if (
      globalSearch.length > 0 ||
      filterTag ||
      shareToFilter ||
      limit ||
      pageOne
    ) {
      setCurrentPage(1);
    }

    if (!globalSearch.length) {
      setIsSuccess(false);
    }
  }, [globalSearch, filterTag, shareToFilter, limit, pageOne, createdByFilter]);

  useEffect(() => {
    setIsSuccess(false);
  }, [currentPage, limit]);

  const createTagData = (label: string) => {
    var data = {
      name: label,
      flag: "pwd",
    };
    return createTag(data);
  };

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 800,
  });

  useEffect(() => {
    if (allError || oneError) {
      setIsError(true);
    }
  }, [allError, oneError]);

  if (error) {
    if (FetchBaseError(error)) {
      if (Number(error?.status) === 400 || Number(error?.status) === 401) {
        localStorage.removeItem("userData");
        navigate("/login");
      } else {
        setErrorPopup(true);
        setMessage(
          error?.data?.message
            ? [error?.data?.message]
            : ["You are something wrong. Pls login Again!"]
        );
      }
    }
  }

  useEffect(() => {
    if (allLoading) {
      setGetKey(false);
    }
  }, [allLoading]);

  const addNewPassword = () => {
    return {
      id: 0,
      account: "",
      links: "",
      password: "",
      platform: "",
      isShow: 0,
      tags: [],
      user_ids: [],
      createdBy: {
        createdBy: null,
        name: "",
      },
      isNew: true,
    };
  };

  const handleAddNew = useCallback(() => {
    const datas = data?.map((d: any) => {
      return {
        ...d,
        password: "********",
        is_show: 0,
      };
    });
    setData([addNewPassword(), ...datas]);
  }, [setData, data]);

  var disabledData = checkedIsNew(data);
  return (
    <>
      {customLoading ? (
        <CommonTableSkeleton />
      ) : (
        <>
          <ImportUsersModal
            isOpen={isImportModalOpen}
            setIsOpen={setIsImportModalOpen}
            sampleCSV={downloadSampleFile}
            importEmployee={importPassword}
            isImporting={isImporting}
            setIsImporting={setIsImporting}
            setIsDownloading={setIsDownloading}
            isDownloading={isDownloading}
          />
          <ToastContainer className={"text-12 font-poppins font-normal"} />
          <ErrorPopup
            descText="Error Message!"
            confirmBtnText="Yes, Close."
            show={errorPopup}
            setShow={setErrorPopup}
            message={message}
            setMessage={setMessage}
          />
          {getKey && (
            <ModalPwd
              getAllSeePwd={getAllSeePwd}
              state={modalState}
              setState={setModalState}
              rowId={modalRow}
              data={data}
              setData={setData}
              setShowPasswords={setShowPasswords}
              showPasswords={showPasswords}
              isSuccess={isSuccess}
              setIsSuccess={setIsSuccess}
              isError={isError}
              setIsError={setIsError}
            />
          )}
          <Modal
            open={isAddPasswordModalOpen}
            style={{
              background: "rgb(0 0 0 / 0.5)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClose={(event, reason) => {
              if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                setIsPasswordModalOpen(false);
              }
            }}
            aria-labelledby="Password Add Modal"
            aria-describedby="Add New Password Form Pop up"
            disableEnforceFocus={true}
          >
            <div className=" max-w-[558px] w-full m-auto">
              <PasswordForm
                createTagData={createTagData}
                tagsLists={filterTagData}
                addNewPassword={saveNewPassword}
                setShowPasswordForm={setIsPasswordModalOpen}
              />
            </div>
          </Modal>

          <div className="px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9">
            <div className="flex flex-wrap justify-between items-center">
              <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] md:mb-1">
                <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
                  {(setState) => (
                    <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                      <input
                        id="new-data-message"
                        autoComplete="new-data-message"
                        // name="search"
                        type="text"
                        placeholder="Search"
                        className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                        onChange={(e) => setState(e.target.value)}
                      />
                      <SearchIcon className={classNames("mr-2")} />
                    </div>
                  )}
                </DebounceSearchInput>
              </div>
              <div className="flex items-center xs:justify-end md:justify-end justify-end">
                {checkImport ? (
                  <Button
                    label={
                      <span className="flex items-center">
                        <ImportIcon className="mr-[5px]" />
                        Import
                      </span>
                    }
                    onClick={() => {
                      setIsImportModalOpen(true);
                      setIsImporting(false);
                    }}
                    type="button"
                    variant="no-outline"
                    customClass="bg-transparent border-transparent sm:flex hover:border-vorpblue hover:text-vorpblue transition-all duration-1000 text-black2 xs:text-[12px] leading-[18px] font-medium py-[7px] px-[6px] mr-[10px]"
                    size="base"
                  />
                ) : null}
                {checkNewPwd ? (
                  <Button
                    disabled={disabledData?.disabled}
                    label={<span className="flex items-center">+ Add New</span>}
                    onClick={() => {
                      setIsSuccess(false);
                      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
                      handleAddNew();
                    }}
                    type="button"
                    variant="primary"
                    customClass={
                      disabledData?.disabledClass +
                      " py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                    }
                    size="base"
                  />
                ) : null}
              </div>
            </div>

            <div className="flex sm:flex-row flex-col justify-between items-center mb-1">
              <div className="flex w-full sm:mr-5 custom-scrollbar sm:mt-0 mt-1 sm:mb-0 mb-1">
                <CustomScrollbar isVertical={false} isHor={true}>
                  <div className="flex w-full items-center">
                    <div
                      className={`w-auto flex items-center mr-3 ${PasswordListStyles.customDropdown}`}
                    >
                      <p className="htzxs:w-auto w-1/2 text-left base-label xl:text-sm text-12 hidden">
                        TAG
                      </p>
                      <CustomDropdownListForFilter
                        isFilter={true}
                        state={filterTag}
                        setState={setFilterTag}
                        dropdownItems={
                          filterTagData
                            ? filterTagData.map((dp) => ({
                              value: dp.id,
                              label: dp.name,
                            }))
                            : []
                        }
                        className=" 2xl:w-32 w-28 min-w-[100px] 6xs:text-12"
                        placeholder="Tag"
                        textAlign="text-left py-2 pl-2 pr-3"
                      />
                    </div>
                    <div
                      className={`w-auto flex items-center mr-3 ${PasswordListStyles.customDropdown}`}
                    >
                      <p className="htzxs:w-auto w-1/2 text-left base-label xl:text-sm text-12 hidden">
                        Projects
                      </p>
                      <CustomDropdownListForFilter
                        isFilter={true}
                        state={project}
                        setState={setProject}
                        dropdownItems={
                          projects
                            ? [{ project_id: 'all', project_name: 'All', project_code: 'All' }, ...projects].map((dp) => ({
                              value: dp.project_id,
                              label: dp.project_name,
                            }))
                            : []
                        }
                        className=" 2xl:w-32 w-28 min-w-[100px] 6xs:text-12"
                        placeholder="Projects"
                        textAlign="text-left py-2 pl-2 pr-3"
                      />
                    </div>
                    <div
                      className={`w-auto flex items-center mr-3 ${PasswordListStyles.customDropdown}`}
                    >
                      <p className="hidden htzxs:w-auto w-1/2 text-left base-label xl:text-sm text-12 whitespace-nowrap">
                        SHARE TO
                      </p>
                      <CustomDropdownListForFilter
                        isFilter={true}
                        state={shareToFilter}
                        setState={setShareToFilter}
                        // dropdownItems={employeeList?.map((emp) => ({
                        dropdownItems={[{ value: "All", label: "All" }, ...assignUserListData?.map((emp) => ({
                          value: emp.id,
                          label: emp.name,
                        }))]}
                        className=" 2xl:w-32 w-28 min-w-[100px] 6xs:text-12"
                        placeholder="Share To"
                        textAlign="text-left py-2 pl-2 pr-3"
                      />
                    </div>
                    {showCreatedByFilter == true ? <div
                      className={`w-auto flex items-center mr-3 ${PasswordListStyles.customDropdown}`}
                    >
                      <p className="hidden htzxs:w-auto w-1/2 text-left base-label xl:text-sm text-12 whitespace-nowrap">
                        Created By
                      </p>
                      <CustomDropdownListForFilter
                        isFilter={true}
                        state={createdByFilter}
                        setState={setCreatedByFilter}
                        // dropdownItems={employeeList?.map((emp) => ({
                        dropdownItems={[{ value: "All", label: "All" }, ...assignUserListData?.map((emp) => ({
                          value: emp.id,
                          label: emp.name,
                        }))]}
                        className=" 2xl:w-32 w-28 min-w-[100px] 6xs:text-12"
                        placeholder="Created By"
                        textAlign="text-left py-2 pl-2 pr-3"
                      />
                    </div> : ''}
                    {managePassword ? (
                      isSuccess ? (
                        <button
                          type="button"
                          onClick={() => {
                            handleClickBtn();
                            setShowPasswords(false);
                          }}
                          className="mr-3 xl:text-sm text-12 leading-5 font-medium text-vorpblue whitespace-nowrap sm:block"
                        >
                          {"Hide Passwords"}
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => {
                            modalStateHandler(MODALS.SHOW_PWD);
                            setShowPasswords(true);
                            setOnePwd(false);
                            setIsSuccess(false);
                            handleGetAllKey();
                          }}
                          className="mr-3 xl:text-sm text-12 leading-5 font-medium text-vorpblue whitespace-nowrap sm:block"
                        >
                          {"Show Passwords"}
                        </button>
                      )
                    ) : null}
                  </div>
                </CustomScrollbar>
              </div>
              <div className="flex items-center justify-end md:justify-start sm:w-auto w-full">
                <div className="flex items-center mr-3">
                  <p className="base-label xl:text-sm text-12 hidden 3xs:block">
                    DISPLAY
                  </p>
                  <NumberDropdownList
                    state={pagination.pageSize}
                    setState={(value) => {
                      setPagination((prev) => ({
                        ...prev,
                        pageSize: value as number,
                      }));
                      setLimit(value);
                    }}
                    dropdownItems={new Array(5)
                      .fill(1)
                      .map((_, index: number) => {
                        let display = (index + 1) * 20;
                        return {
                          value: display,
                          label: String(display),
                        };
                      })}
                    className="max-w-[72px]"
                    textAlign="text-left pl-2 pr-3"
                    paddingClass="py-[2px]"
                    placeholder="20"
                  />
                </div>
                <StringDropdownListWithDeleteBtn
                  haveDeleteBtn={true}
                  setIsTemplate={setIsTemplate}
                  state={isTemplate ? currentViewData : currentView}
                  setState={setCurrentView}
                  deleteTemplate={deleteTemplateData}
                  dropdownItems={moduleList.map((x: any, index: number) => {
                    return {
                      label: x.id === -1 ? x.name : "View #" + x.index,
                      value: x.id,
                    };
                  })}
                  className="w-full min-w-[100px] 2xl:min-w-[137px] 6xs:text-12"
                  placeholder="View #1"
                  textAlign="text-left py-2 pl-2 pr-3"
                  custPadding="py-[2px]"
                  setErrorPopup={setErrorPopup}
                  setSuccessPopup={setSuccessPopup}
                  setMessage={setMessage}
                />
                <button
                  type="button"
                  onClick={() => setIsLayoutControlOpen(true)}
                  className="flex relative justify-center items-center rounded-lg bg-vorphongkongborder w-[29px] h-[29px] ml-2 group transition-all duration-300 "
                >
                  <CustomizeLayoutIcon pathClassName="group-hover:fill-white" />
                  <div className="absolute left-7 top-[-5px] border border-white w-4 h-4 rounded-full bg-vorphongkongborder hover:bg-vorphongkongborder flex justify-center items-center">
                    <AddIcon width={8} height={8} color="white" />
                  </div>
                </button>
              </div>
            </div>
            
            <animated.div style={props}>
              <PasswordsTable
                setSortDirection={setSortDirection}
                sortDirection={sortDirection}
                pagination={pagination}
                publicKey={keyData}
                setPagination={setPagination}
                isLayoutControlOpen={isLayoutControlOpen}
                setIsLayoutControlOpen={setIsLayoutControlOpen}
                globalSearch={globalSearch}
                setGlobalSearch={setGlobalSearch}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                showPasswords={showPasswords}
                data={data}
                setData={setData}
                localModuleId={localModuleId}
                // setHiddenFields={setHiddenFields}
                hiddenFields={hiddenFields}
                // templateList={tempList}
                // setCurrentView={setCurrentView}
                assignPassword={assignPassword}
                assignPasswords={assignPasswords}
                // employeeList={assignUserListData}
                modalStateHandler={modalStateHandler}
                setOnePwd={setOnePwd}
                // onePwd={onePwd}
                setModalRow={setModalRow}
                setShowPasswords={setShowPasswords}
                show_one_pwd={show_one_pwd}
                setEyeClick={setEyeClick}
                // eyeClick={eyeClick}
                setIsSuccess={setIsSuccess}
                isSuccess={isSuccess}
                filterTagData={filterTagData}
                total={itemCount}
                changePage={changePage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                setCols={setCols}
                setIsTemplate={setIsTemplate}
                createTagData={createTagData}
                managePassword={managePassword}
                isEditing={isEditing}
                setIsEdit={setIsEdit}
                // handleClickBtn={handleClickBtn}
                handleOneGetKey={handleOneGetKey}
                assignUserListData={assignUserListData}
                departmentList={departmentList}
                setAssignDepartmentId={setAssignDepartmentId}
                assignDepartmentId={assignDepartmentId}
                setAssignKeword={setAssignKeword}
                sorting={sorting}
                setSorting={setSorting}
                checkNewPwd={checkNewPwd}
                projects={projects}

              />
            </animated.div>
          </div>
        </>
      )}
    </>
  );
};
export default PasswordList;
