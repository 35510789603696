import { useEffect, useState } from "react";
import DateFilter from "../common/DateFilter";
import { PDFIcon } from "../common/Icons";
import { useGetManualsQuery } from "../../app/services/manuals";
import { CircularProgress } from "@mui/material";
import { useGetUsefulLinesQuery } from "../../app/services/usefullines";

const UsefulLines = () => {
  const [usefulLinesData, setUsefulLinesData] = useState<any>([]);
  const { data: usefulLines, isLoading } = useGetUsefulLinesQuery();
  useEffect(() => {
    if (usefulLines) {
      console.log("usefulLines ", usefulLines);
      setUsefulLinesData(usefulLines?.usefulLinkList);
    }
  }, [usefulLines]);
  return (
    <div className=" -mx-5">
      {isLoading == true ? (
        <CircularProgress />
      ) : (
        <ul>
          {usefulLinesData?.map((link: any, key: number) => {
            return (
              <li className="p-5 w-full text-left border-b border-b-sat_borderv2">
                <a target="_blank" href={link.link} className="text-sat_link underline underline-offset-2 text-body-md w-full">
                  {link.name}
                </a>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
export default UsefulLines;
