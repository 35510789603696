import { useNavigate } from "react-router-dom";
import back from "../img/back-page.svg";
import password_logo from "../img/password-logo.png";
import { createContext, useContext, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
export const ForgetPasswordContext = createContext({});
const ForgetPasswordComponent = (props:any) => {
  const {logoData}=props;  
  const [email, setEmail] = useState<string>("");
  const navigate = useNavigate();
  const sendEmail = () => {
    
    axios
      .post(endpointUrl + "password/forgot", {
        email: email,
      })
      .then(function (response) {
        localStorage.setItem('email',email);
        navigate("/emailsent");
      })
      .catch((reason) => {
        console.log("error", reason.response.data.message);
        // setLoginError(reason.response.data.message)
      });
    navigate("/emailsent");
  };
  const validateEmail = (email: any) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
  return (
    <div className="max-w-full">
    <div className="max-w-[360px] mx-auto px-5">
      <div className="py-5 relative">
        <button
          onClick={() => window.history.back()}
          className="absolute left-0 top-1/2 -translate-y-1/2"
        >
          <img src={back} alt="back" className="" />
        </button>
        <h3 className="heading3 font-secondary text-center text-sat_darkblackv2 w-full">
          Forgot Password
        </h3>
      </div>
      <div className="pt-[60px] pb-10">
        <img
          src={password_logo}
          alt="password_logo"
          className="mx-auto max-w-full"
        />
        <div className="mt-10">
          <p className="text-body-md font-secondary font-normal text-sat_darkgray leading-[150%] text-center">
            Enter the email address with your account and we’ll send you an
            email with confirmation to reset your password.
          </p>
          <div>
            <input
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              type="email"
              className="mt-5 px-3 py-2 leading-[150%] rounded-lg bg-sat_cream border border-sat_cream_dark text-sat_darkblackv2 text-body-md focus-within:outline-none focus:outline-none focus-visible:outline-none w-full"
            />
          </div>
          <button
            onClick={() => sendEmail()}
            className={twJoin(
              validateEmail(email) ? "" : "opacity-50 pointer-events-none",
              "rounded-lg py-3 px-3 leading-[normal] text-white text-center text-body-md font-bold font-secondary w-full bg-sat_primary mt-5"
            )}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  </div>
  );
};

export default ForgetPasswordComponent;
