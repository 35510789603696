import { redirectToLogin } from "./annoucement-sheet";
import { api, endpointUrl } from "./api";
import authHeader from "./auth-header";
export type TemplateResponse = { data: templateData[] };
export type templateData = {
  templates: TemplateList[];
};
export type templateSpecificData = {
  template: TemplateList;
};
export type TemplateList = {
  id: number;
  name: string;
  module_id: number;
  viewColumns: string[];
  view_columns?: string[];
};
export type addTempleData = {
  data: TemplateList;
};
export const templatesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSpecificTemplate: build.query<templateSpecificData, number>({
      query: (id) => ({
        headers: authHeader(),
        url: `${endpointUrl}getTemplate/${id}`,
        method: "GET",
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: [{ type: "templateSpecificData", id: "LIST" }],
    }),
    getByModuleTemplate: build.query<templateData, number>({
      query: (id) => ({
        headers: authHeader(),
        url: `${endpointUrl}getTemplateList?module=${id}`,
        method: "GET",
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: [{ type: "templateData", id: "LIST" }],
    }),
    addTemplate: build.mutation<TemplateList, Partial<TemplateList>>({
      query: (data) => ({
        url: `${endpointUrl}saveTemplate`,
        headers: authHeader(),
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, id) => [
        { type: "templateData", id: result?.id },
      ],
    }),
    destoryTemplate: build.mutation<{ success: boolean; id: number }, number>({
      query: (id) => ({
        url: `${endpointUrl}deleteTemplate/${id}`,
        headers: authHeader(),
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [
        { type: "templateData", id: result?.id },
      ],
    }),
    multiDeleteTemplate: build.mutation<TemplateList, Partial<TemplateList>>({
      query: ({ id, ...rest }) => ({
        url: `${endpointUrl}deleteTemplates`,
        headers: authHeader(),
        method: "DELETE",
        body: rest,
      }),
      invalidatesTags: (TemplateList) => [
        { type: "TemplateList", id: TemplateList?.id },
      ],
    }),
  }),
});

export const {
  useGetSpecificTemplateQuery,
  useGetByModuleTemplateQuery,
  useAddTemplateMutation,
  useDestoryTemplateMutation,
  useMultiDeleteTemplateMutation,
} = templatesApi;

export const {
  endpoints: {
    getSpecificTemplate,
    getByModuleTemplate,
    addTemplate,
    destoryTemplate,
    multiDeleteTemplate,
  },
} = templatesApi;
