import { PaginationState, SortingState } from "@tanstack/react-table";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import styles from "./ActiveTimeReport.module.scss";
// import pieChartIcon from "./img/teenyicons_pie-chart-outline.svg";
// import CustomDatePicker from "../../components/common/BaseDatePicker";
import {
  NumberDropdownList,
  // StringDropdownList,
} from "../../components/common/DropdownList";
import {
  AddIcon,
  CloseIcon,
  CustomizeLayoutIcon,
  ExportIcon,
  ImportIcon,
  SearchIcon,
} from "../../components/common/Icons";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import TimesheetTable from "../../components/TimesheetTable/TimesheetTable";
import Button from "../../components/common/Button";
import ExportUsersModal from "../../components/BackendUsers/ExportUsersTable";
import ImportUsersModal from "../../components/BackendUsers/ImportUsersModal";
import TimeTrackingPopup from "../../components/TimeTrackingPopup/TimeTrackingPopup";
import {
  EmpyTimeSheet,
  // TimeSheet,
  // TimeSheetDetail,
  // useGetActiveTimeTemplateListQuery,
  useGetEmpyTimesheetDataQuery,
  useGetTimesheetDataQuery,
  // useGetTimesheetDetailQuery,
} from "../../app/services/timesheet";
import { renderDateFormatYMD } from "../../components/DashboardCalendarView/DashboardAddEventPopup";
// import { exportToCSV } from "../../employee-list/views/EmployeeList";
import { StringDropdownListWithDeleteBtn } from "../../components/common/StringDropdownListWithDeleteBtn";
// import { TemplateData } from "../../app/services/employee";
import { useGetByModuleTemplateQuery } from "../../app/services/template";
// import { hideAllNoneField } from "../../components/Tables/TablePaginator";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import { baseObj } from "../../utils/constants";
// import SuccessPopup from "../../components/SuccessPopup/SuccessPopup";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
// import CustomCommonError from "../../components/common/CustomCommonError";
import { useNavigate } from "react-router-dom";
import { FetchBaseError } from "../../components/common/FetchBaseQueryError";
import { getAuthUser } from "../../app/services/dashboard";
import DatePopupFoFilter from "../../components/EmployeeTable/DatePopupFoFilter";
import CustomScrollbar from "../../components/CustomScrollbar/CustomScrollbar";
import { useSpring, animated } from "react-spring";
import CommonTableSkeleton from "../../components/TableSkeleton/CommonTableSkeleton";
import { ToastContainer, toast } from "react-toastify";
import ResuableToasty from "../../components/Toasty/ResuableToast";

export interface Employee {
  id: number;
  name: string;
  data: EmployeeData[];
}

export interface WorkingInfo {
  startWorkHr: string;
  endWorkHr: string;
  totalHr: string;
}

export interface WorkingDetailInfo {
  activeTime: string;
  downTime: string;
  lateness: string;
  overtime: string;
}

export interface EmployeeData {
  date: string;
  workingInfo: WorkingInfo;
  workingDetail: WorkingDetailInfo;
  productive: string;
  unproductive: string;
  neutral: string;
  productivePercent: string;
  unproductivePercent: string;
  neutralPercent: string;
}

export const dummyTimesheetData = [
  {
    id: 1,
    date: "2023-11-03",
    employee: "Visibee 1",
    clockIn1: "08:01:36",
    clockOut1: "12:57:54",
    location1: "--",
    clockIn2: "13:57:36",
    clockOut2: "18:57:45",
    location2: "--",
    clockIn3: "08:01:36",
    clockOut3: "12:57:54",
    location3: "--",
  },
  {
    id: 2,
    date: "2023-11-03",
    employee: "Visibee 2",
    clockIn1: "08:01:36",
    clockOut1: "12:57:54",
    location1: "--",
    clockIn2: "13:57:36",
    clockOut2: "18:57:45",
    location2: "--",
    clockIn3: "08:01:36",
    clockOut3: "12:57:54",
    location3: "--",
  },
  {
    id: 3,
    date: "2023-11-03",
    employee: "Visibee 3",
    clockIn1: "08:01:36",
    clockOut1: "12:57:54",
    location1: "--",
    clockIn2: "13:57:36",
    clockOut2: "18:57:45",
    location2: "--",
    clockIn3: "08:01:36",
    clockOut3: "12:57:54",
    location3: "--",
  },
  {
    id: 4,
    date: "2023-11-03",
    employee: "Visibee 4",
    clockIn1: "08:01:36",
    clockOut1: "12:57:54",
    location1: "--",
    clockIn2: "13:57:36",
    clockOut2: "18:57:45",
    location2: "--",
    clockIn3: "08:01:36",
    clockOut3: "12:57:54",
    location3: "--",
  },
  {
    id: 5,
    date: "2023-11-03",
    employee: "Visibee 1",
    clockIn1: "08:01:36",
    clockOut1: "12:57:54",
    location1: "--",
    clockIn2: "13:57:36",
    clockOut2: "18:57:45",
    location2: "--",
    clockIn3: "08:01:36",
    clockOut3: "12:57:54",
    location3: "--",
  },
];
export interface TimeSheetDataCap {
  Id: number;
  Date: string;
  Employee: string;
  ClockIn1: string;
  ClockOut1: string;
  Location1: string;
  ClockIn2: string;
  ClockOut2: string;
  Location2: string;
  ClockIn3: string;
  ClockOut3: string;
  Location3: string;
}
export type TTemplate = {
  id: number;
  label: string;
  value: string;
  viewsColumn: string[]
}
export const templateList = [
  { id: 1, label: "View #1", value: "view-i23vd", viewColumns: [] },
  { id: 2, label: "View #2", value: "view-i23vd", viewColumns: [] },
  { id: 3, label: "View #3", value: "view-i23vd", viewColumns: [] },
];

const ActiveTimeReport = () => {
  const navigate = useNavigate();

  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  // const [activeTimeData, setActiveTimeData] = useState<TimeSheet[]>([]);
  const [employeeListData, setEmployeeListData] = useState<EmpyTimeSheet[]>([]);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [currentView, setCurrentView] = useState<any>(0);
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);
  // const [templatePlaceholder, setTemplatePlaceholder] = useState<string>("---");
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const [itemCount, setItemCount] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [moduleList, setModuleList] = useState<any>([]);
  const [viewCols, setCols] = useState<any>([]);
  const [currentViewData, setCurrentViewData] = useState<number>(0);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const defaultFields = useMemo(
    () => [
      "date",
      "employee",
      "employee_id",
      "clockIn1",
      "clockOut1",
      "location1",
      "clockIn2",
      "clockOut2",
      "location2",
      "clockIn3",
      "clockOut3",
      "location3",
      "total_hours",
      "startOfWork",
      "endOfWork",
      "totalTime",
      "active",
      "verifiedDeducted",
      "verifiedActive",
      "downTime",
      "lateness",
      "overTime",
      "productive",
      "unproductive",
      "neutral",
      "remark",
    ],
    []
  );

  const [isTimeTrackingModalOpen, setIsTimeTrackingModalOpen] =
    useState<boolean>(false);
  const [isLayoutControlOpen, setIsLayoutControlOpen] =
    useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  const [checkExport, setCheckExport] = useState<boolean>(false);
  const [checkImport, setCheckImport] = useState<boolean>(false);
  const [viewTimeTracking, setViewTimeTracking] = useState<boolean>(false);
  const [tempList, setTempList] = useState<any>(templateList);
  const [hiddenFields, setHiddenFields] = useState<string[]>();
  const { data: templateData } = useGetByModuleTemplateQuery(localModuleId);
  const { data: EmpyTimeList } = useGetEmpyTimesheetDataQuery();
  const [showAbsent, setShowAbsent] = useState<boolean>(false);
  const [data, setData] = useState<baseObj[]>([]);
  const {
    data: activeTimeReport,
    error,
    isFetching,
    isLoading,
  } = useGetTimesheetDataQuery({
    limit: pagination.pageSize,
    page: currentPage,
    start: startDate ? renderDateFormatYMD(startDate, "-") : "",
    end: endDate ? renderDateFormatYMD(endDate, "-") : "",
    search: globalSearch,
    sort: sorting.map((s) => `${s.desc ? '-' : ''}${s.id}`).join(','),
  });
// console.log("c", currentPage);
  var getUser = getAuthUser();
  const getAuthPermis: string[] = useMemo(() => [], []);

  if (getUser?.permissions) {
    getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }

  useEffect(() => {
    const filterExport = getAuthPermis.filter(
      (f) => f === "Import_Active_Time_Report"
    );
    const filterImport = getAuthPermis.filter(
      (f) => f === "Export_Active_Time_Report"
    );
    const viewTimeTracking = getAuthPermis.filter(
      (f) => f === "View_Time_Tracking"
    );

    if (viewTimeTracking.length > 0) {
      setViewTimeTracking(true);
    }
    if (filterImport.length > 0) {
      setCheckImport(true);
    }
    if (filterExport.length > 0) {
      setCheckExport(true);
    }
  }, [getUser, getAuthPermis]);

  useEffect(() => {
    if (activeTimeReport) {
      if (!showAbsent) {
        // setActiveTimeData(activeTimeReport?.data?.data);
        setData(activeTimeReport?.data?.data);
        setItemCount(activeTimeReport?.data?.total);
      } else {
        // setActiveTimeData([]);
        setItemCount(0);
      }

      setLocalModuleId(activeTimeReport?.module_id);
      if (isFetching) {
        setData([]);
      } else {
        if (!showAbsent) {
          setData(activeTimeReport?.data?.data);
        } else {
          setData([]);
        }
      }
      setData(activeTimeReport?.data?.data);
    }
  }, [activeTimeReport, isFetching]); //showAbsent

  useEffect(() => {
    if (EmpyTimeList) {
      setEmployeeListData(EmpyTimeList?.employeeList);
    }
  }, [EmpyTimeList]);

  useEffect(() => {
    if (templateData) {
      var updatedTemplate = templateData?.templates.map((x: any, i) => {
        return { ...x, index: i + 1 };
      });
      var defaultTemplate = {
        id: 0,
        index: 0,
        module: 0,
        name: "All",
      };
      setModuleList([defaultTemplate, ...updatedTemplate]);
    }
  }, [templateData]);

  useEffect(() => {
    if (templateList) {
      setTempList(templateList);
    }
    var viewData = templateData?.templates.find(
      (x) => x.id.toString() === currentView.toString()
    );
    setHiddenFields(viewData ? [...viewData.viewColumns] : defaultFields);

    document.querySelectorAll("th,td").forEach((x) => {
      if (x.id.includes("none")) {
        x.classList.add("hidden");
      }
      if (x.id.includes("action")) {
        x.classList.add("absolute");
        x.classList.add("right-0");
      }
      x.classList.remove("absolute");
      x.classList.add("left-0");
      x.classList.add("relative");
      var div = document.getElementById(x.id);
      if (div) {
        div.style.setProperty("left", "");
      }
    });

    // setTemplatePlaceholder(currentView);
  }, [currentView, defaultFields]); //templateList,

  useEffect(() => {
    if (viewCols?.view_columns?.length > 0) {
      setHiddenFields(viewCols?.view_columns);
    }
  }, [viewCols?.view_columns]);

  useEffect(() => {
    if (moduleList) {
      var lastesTemplate = moduleList[moduleList?.length - 1];
      setCurrentViewData(lastesTemplate ? Number(lastesTemplate?.id) : 0);
    }
  }, [moduleList]); //isTemplate &&

  const exportEmployeeData = (format: string, isChecked: string) => {
    axios({
      url:
        endpointUrl +
        `timesheetsData?limit=${
          pagination.pageSize
        }&page=${currentPage}&globalSearch=${globalSearch}&start=${
          startDate ? renderDateFormatYMD(startDate, "/") : ""
        }&end=${
          endDate ? renderDateFormatYMD(endDate, "/") : ""
        }&format=${format}&export=true&only_this_page=${isChecked}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${getUser.token}`,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "TimeReport." + (format === "excel" ? "xlsx" : format)
        );
        document.body.appendChild(link);
        link.click();
        setIsExporting(false);
        setIsExportModalOpen(false);
      })
      .catch((error: any) => {
        setErrorPopup(true);
        setMessage(
          error?.message
            ? [error?.message]
            : ["Something wrong!. Trying again."]
        );
        setIsImportModalOpen(false);
      });
  };

  const downloadSampleFile = () => {
    axios({
      url: endpointUrl + "downloadActiveTimeSample",
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "ActiveTimesample.xlsx");
      document.body.appendChild(link);
      link.click();
      setIsDownloading(false);
    });
  };


  const importEmployee = async (formSaveData: any) => {
    await axios(endpointUrl + "importTimeSheet", {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getUser.token}`,
      },
      data: formSaveData,
    })
      .then((res) => {
        axios.get(`${endpointUrl}timesheetsData`, {}).then((result) => {
          // console.log("result", result?.data?.data?.data)
          var empUpdatedData = result?.data?.data?.data;
          setData(empUpdatedData);
          setIsImporting(true);
          setIsImportModalOpen(false);
          ResuableToasty({
            message: "Successfully Import.",
            position: "top-right",
            type: "success",
          });

          // toast("Successfully Import.", {
          //   position: "top-right",
          //   autoClose: 2000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "light",
          //   type: "success",
          // });
        });
      })
      .catch((error: any) => {
        // console.log("e", error)
        setErrorPopup(true);
        setMessage(
          error?.response
            ? [error?.response?.data?.message]
            : ["Something wrong!. Trying again."]
        );
        setIsImportModalOpen(false);
      });
  };

  const deleteTemplateData = (id: string) => {
    var filterTempList = templateList?.filter(
      (x) => Number(x.id) !== parseInt(id)
    );
    setTempList(filterTempList ? filterTempList : []);
  };

  // const changePage = (pageNo: number) => {
  //   var pageData = {
  //     limit: pagination.pageSize,
  //     currentPage: pagination.pageIndex + 1,
  //   };
  //   // getPermissions(pageData)
  // };

  
  useEffect(() => {
    setCurrentPage(1);
  }, [globalSearch, startDate, endDate, pagination.pageSize]);

  useEffect(() => {
    if (successPopup) {
      ResuableToasty({
        message: "Done Successfully.",
        position: "top-right",
        type: "success",
      });

      // toast("Done Successfully.", {
      //   position: "top-right",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   type: "success",
      // });
    }

    setTimeout(() => {
      setSuccessPopup(false);
    }, 2000);
  }, [successPopup]);

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 1200,
  });

  if (error) {
    if (FetchBaseError(error)) {
      if (error?.status === 400) {
        localStorage.removeItem("userData");
        navigate("/login");
      } else {
        setErrorPopup(true);
        setMessage(
          error?.data?.message
            ? [error?.data?.message]
            : ["You are something wrong. Pls login Again!"]
        );
      }
    }
  }

  return (
    <>
      {isLoading ? (
        <CommonTableSkeleton rows={12} />
      ) : (
        <>
          <ImportUsersModal
            isOpen={isImportModalOpen}
            setIsOpen={setIsImportModalOpen}
            sampleCSV={downloadSampleFile}
            importEmployee={importEmployee}
            isImporting={isImporting}
            setIsImporting={setIsImporting}
            setIsDownloading={setIsDownloading}
            isDownloading={isDownloading}
          />
          <ExportUsersModal
            isOpen={isExportModalOpen}
            setIsOpen={setIsExportModalOpen}
            exportToCSV={exportEmployeeData}
            exportToExcel={exportEmployeeData}
            exportToPDF={exportEmployeeData}
            exportToZip={exportEmployeeData}
            isExporting={isExporting}
            setIsExporting={setIsExporting}
            isShowOnlyCheckbox={true}
          />
          <TimeTrackingPopup
            employeeList={employeeListData}
            isOpen={isTimeTrackingModalOpen}
            setIsOpen={setIsTimeTrackingModalOpen}
          />

          <ToastContainer className={"text-12 font-poppins font-normal"} />
          <ErrorPopup
            descText="Error Message!"
            confirmBtnText="Yes, Close."
            show={errorPopup}
            setShow={setErrorPopup}
            message={message}
            setMessage={setMessage}
          />
          <div
            className={classNames(
              "px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
            )}
          >
            <div className="flex flex-wrap justify-between items-center">
              <div className="max-w-[200px] mb-1 mr-[10px]">
                <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] mb-1">
                  <DebounceSearchInput
                    setState={setGlobalSearch}
                    debounce={800}
                  >
                    {(setState) => (
                      <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                        <input
                          type="text"
                          placeholder="Search"
                          className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                          onChange={(e) => setState(e.target.value)}
                        />
                        <SearchIcon className={classNames("mr-2")} />
                      </div>
                    )}
                  </DebounceSearchInput>
                </div>
              </div>
              <div className="flex justify-end mb-1 sm:mt-0 items-center">
                {/* <Button
                    label={
                      <span className="flex items-center">
                        {!showAbsent ? "Absent List" : "Active List"}
                      </span>
                    }
                    onClick={() => { setShowAbsent(!showAbsent) }}
                    type="button"
                    variant="no-outline"
                    customClass="border-1 border-[#CCDAE4] bg-transparent hover:border-vorpblue hover:text-vorpblue transition-all duration-300 text-black2 xs:text-[12px] leading-[18px] font-medium py-[7px] px-[6px] mr-[10px]"
                    size="base"
                  /> */}
                {checkImport ? (
                  <Button
                    label={
                      <span className="flex items-center">
                        <ImportIcon className="mr-[5px]" /> Import
                      </span>
                    }
                    onClick={() => {
                      setIsImportModalOpen(true);
                      setIsImporting(false);
                    }}
                    type="button"
                    variant="no-outline"
                    customClass="bg-transparent border-transparent hover:border-vorpblue hover:text-vorpblue transition-all duration-300 text-black2 xs:text-[12px] leading-[18px] font-medium py-[7px] px-[6px] mr-[10px]"
                    size="base"
                  />
                ) : null}
                {checkExport ? (
                  <Button
                    label={
                      <span className="flex items-center">
                        <ExportIcon className="mr-[5px]" /> Export
                      </span>
                    }
                    onClick={() => {
                      setIsExportModalOpen(true);
                      setIsExporting(false);
                    }}
                    type="button"
                    variant="no-outline"
                    customClass="my-1 bg-vorpmyanmar border-vorpmyanmar hover:bg-vorpblue hover:text-white transition-all duration-300 text-vorpblue xs:text-[12px] leading-[18px] font-medium py-1 px-[6px] sm:mr-[10px] mr-[5px]"
                    size="base"
                  />
                ) : null}
              </div>
            </div>

            <div className="flex sm:flex-row flex-col justify-between mb-1">
              <div className="flex w-full sm:mr-5 custom-scrollbar">
                <CustomScrollbar isVertical={false} isHor={true}>
                  <div className="flex w-full items-center">
                    <p className="hidden text-left uppercase mb-3 xs:mb-0 xl:text-sm text-12 leading-5 font-medium text-black2 mr-3">
                      Date
                    </p>
                    <div className="flex">
                      {startDate && endDate && (
                        <button
                          type="button"
                          onClick={() => {
                            setStartDate(undefined);
                            setEndDate(undefined);
                          }}
                          className="text-12 mr-2"
                        >
                          <CloseIcon color="#EE436D" width={10} height={10} />
                        </button>
                      )}
                      <div className={`mb-1 xs:mb-0 mr-[10px]`}>
                        <DatePopupFoFilter
                          placeholder="Start Date"
                          date={startDate}
                          setDate={(value) => setStartDate(value)}
                        />
                      </div>
                      <div className={`mr-[10px]`}>
                        <DatePopupFoFilter
                          placeholder="End Date"
                          date={endDate}
                          setDate={(value) => setEndDate(value)}
                        />
                      </div>
                    </div>
                  </div>
                </CustomScrollbar>
              </div>
              <div className="flex sm:flex-nowrap flex-wrap items-center sm:justify-start justify-end">
                <div className=" flex items-center md:ml-3 mr-[10px]">
                  <p className="base-label xl:text-sm text-12">DISPLAY</p>
                  <NumberDropdownList
                    state={pagination.pageSize}
                    setState={(value) =>
                      setPagination((prev) => ({
                        ...prev,
                        pageSize: value as number,
                      }))
                    }
                    dropdownItems={new Array(5)
                      .fill(1)
                      .map((_, index: number) => {
                        let display = (index + 1) * 20;
                        return {
                          value: display,
                          label: String(display),
                        };
                      })}
                    className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
                    textAlign="text-left pl-3"
                    paddingClass="py-[2px]"
                    placeholder="20"
                  />
                </div>
                {viewTimeTracking ? (
                  <div
                    onClick={() => setIsTimeTrackingModalOpen(true)}
                    className={classNames(styles.activeReportChart, "relative")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_3225_5177)">
                        <path
                          d="M8 8H7.46667C7.46654 8.07009 7.48023 8.13951 7.50695 8.2043C7.53368 8.26909 7.57291 8.32798 7.6224 8.3776L8 8ZM8 14.9333C6.16117 14.9333 4.39765 14.2029 3.09739 12.9026C1.79714 11.6024 1.06667 9.83883 1.06667 8H0C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16V14.9333ZM14.9333 8C14.9333 8.9105 14.754 9.81208 14.4056 10.6533C14.0571 11.4945 13.5464 12.2588 12.9026 12.9026C12.2588 13.5464 11.4945 14.0571 10.6533 14.4056C9.81208 14.754 8.9105 14.9333 8 14.9333V16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8H14.9333ZM8 1.06667C8.9105 1.06667 9.81208 1.246 10.6533 1.59444C11.4945 1.94287 12.2588 2.45357 12.9026 3.09739C13.5464 3.74121 14.0571 4.50554 14.4056 5.34673C14.754 6.18792 14.9333 7.0895 14.9333 8H16C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0V1.06667ZM8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8H1.06667C1.06667 6.16117 1.79714 4.39765 3.09739 3.09739C4.39765 1.79714 6.16117 1.06667 8 1.06667V0ZM7.46667 0V8H8.53333V0H7.46667ZM8.23893 8.4768L14.6389 5.2768L14.1611 4.3232L7.76107 7.5232L8.23893 8.4768ZM7.6224 8.3776L12.9557 13.7109L13.7109 12.9557L8.3776 7.6224L7.6224 8.3776Z"
                          fill="#E5D8FF"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3225_5177">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                ) : null}

                <div className="">
                  <StringDropdownListWithDeleteBtn
                    haveDeleteBtn={true}
                    setIsTemplate={setIsTemplate}
                    state={isTemplate ? currentViewData : currentView}
                    setState={setCurrentView}
                    deleteTemplate={deleteTemplateData}
                    dropdownItems={moduleList.map((x: any, index: number) => {
                      return {
                        value: x.id,
                        // label: "View #" + ++index,
                        label: Number(x.id) === 0 ? x.name : "View #" + x.index,
                      };
                    })}
                    className="w-full min-w-[100px] 2xl:min-w-[128px] 6xs:text-12"
                    // placeholder={templatePlaceholder}
                    placeholder="View#1"
                    textAlign="text-left py-2 pl-2 pr-3"
                    custPadding="py-[2px]"
                    setErrorPopup={setErrorPopup}
                    setSuccessPopup={setSuccessPopup}
                    setMessage={setMessage}
                  />
                </div>
                <button
                  type="button"
                  onClick={() => setIsLayoutControlOpen(true)}
                  className="flex justify-center relative items-center rounded-lg bg-vorphongkongborder hover:bg-[#FFF0D2] w-[29px] h-[29px] ml-2 group transition-all duration-300 "
                >
                  <CustomizeLayoutIcon />
                  <div className="absolute left-7 top-[-5px] border border-white w-4 h-4 rounded-full bg-vorphongkongborder flex justify-center items-center">
                    <AddIcon width={8} height={8} color="white" />
                  </div>
                </button>
              </div>
            </div>

            <div className="text-left md:mt-0 mt-2">
              <animated.div style={props}>
                <TimesheetTable
                  data={data}
                  setData={setData}
                  globalSearch={globalSearch}
                  setGlobalSearch={setGlobalSearch}
                  isLayoutControlOpen={isLayoutControlOpen}
                  setIsLayoutControlOpen={setIsLayoutControlOpen}
                  hiddenFields={hiddenFields ? hiddenFields : []}
                  setHiddenFields={setHiddenFields}
                  templateList={tempList}
                  localModuleId={localModuleId}
                  // changePage={changePage}
                  total={itemCount}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  setCurrentView={setCurrentView}
                  pagination={pagination}
                  setPagination={setPagination}
                  setCols={setCols}
                  setIsTemplate={setIsTemplate}
                  sorting={sorting}
                  setSorting={setSorting}
                />
              </animated.div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default ActiveTimeReport;
