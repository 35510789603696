import ForgetPasswordComponent from '../components/ForgetPasswordComponent';
function App(props:any) {
  return (
    <>      
      <ForgetPasswordComponent loginData={props.loginData}/>
    </>
  );
}

export default App;
