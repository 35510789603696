import { withNamespaces } from "react-i18next";
import { LogoutIcon } from "../common/Icons";
import { logout } from "../../app/services/auth.service";
import { useNavigate } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../../app/services/firebase";
import { deleteToken, getMessaging } from "firebase/messaging";

const Logout = (props: any) => {
  const { t } = props;
  const navigate = useNavigate();
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);  
  const handleConfirm=()=>{  
    localStorage.setItem('userData','');
    deleteToken(messaging);
    navigate("/login");
  }
  return (
    <div>
      <div className="max-w-[360px] mx-auto pt-20">
        <div className="text-center flex justify-center">
          <LogoutIcon />
        </div>
        <h1 className="heading1 mt-7 text-sat_darkblackv2 font-normal leading-[150%] font-secondary">
          {t('logout_description')}          
        </h1>
        <button
          onClick={() => {
            handleConfirm();
            navigate("/login");
          }}
          className="mt-5 bg-sat_primary text-white text-body-sm font-bold font-secondary px-3 py-3 leading-[normal] rounded-lg w-full"
        >
          {t("logout")}
        </button>
      </div>
    </div>
  );
};
export default withNamespaces((props) => props.namespaces)(Logout);
