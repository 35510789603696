import React from "react";
import done_bg from "../../forgetpassword/img/done-bg.png";

const PasswordResetSuccessComponent = (props:any) => {
  const {logoData}=props;  
  return (
    <div className="max-w-full">
      <div className="max-w-[360px] mx-auto px-5">
        <div className="password-successful-container">
          <div className="pt-[124px] pb-10">
            <img
              src={done_bg}
              alt="done_bg"
              className="max-w-full mx-auto mt-10"
            />
            <div className="mt-5">
              <p className="heading1 font-secondary font-normal leading-[135%] text-sat_darkblackv2 text-center">
                You’re all set!
              </p>
              <p className="mt-3 text-body-3 font-secondary font-normal text-sat_darkblackv2 text-center">
                Your password has been reset successfully.
              </p>
              <a
                href="/login"
                className="block leading-[normal] mt-5 rounded-lg py-3 px-3 text-white text-center text-body-md font-bold font-secondary w-full bg-sat_primary"
              >
                Login Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetSuccessComponent;
