import { ReactElement, createContext } from "react";
import BasicLayout from "../layouts/BasicLayout";
import { Outlet } from "react-router-dom";
import UserLayout from "./views/UserLayout";
import {
  Documents,
  MyLeave,
  MyPasswords,
  PersonalDetails,
} from "../components/DashboardProfile";
import MyTimeSheet from "../components/DashboardProfile/MyTimeSheet/MyTimeSheet";
import ProfileCalendarViewContainer from "../components/DashboardCalendarView/ProfileCalendarViewContainer";
import EmployeeLeave from "../components/DashboardProfile/MyLeave/EmployeeLeave";
import MyClaim from "../components/DashboardProfile/MyClaim/MyClaim";
import DailyReport from "../components/DashboardProfile/DailyReport/DailyReport";
import MyProjects from "../components/MyProjects/MyProjects";
import DailyReportMemberApplication from "../components/DashboardProfile/DailyReport/DailyReportMemberApplication";
import MyRefillReport from "../components/DashboardProfile/DailyReport/MyRefillReport";
import MyEquipment from "../components/DashboardProfile/MyEquipment/MyEquipment";
import NoticeBoard from "../components/UserInfo/NoticeBoard";
import UserProfile from "../components/UserProfile/UserProfile";
import UserInfo from "../components/UserInfo/UserInfo";
import BasicInfoSection from "../components/UserInfo/BasicInfoSection";
import Setting from "../setting/components/Setting";
import Language from "../setting/components/Language";
import NoticePopupV2 from "../components/NoticePopup/NoticePopupV2";
export const userRoutes = [
  {
    id: 2,
    label: "Profile",
    path: "",
    profileID: 0,
    component: <BasicInfoSection />,
  },
  {
    id: 4,
    label: "Language",
    path: "language",
    profileID: 0,
    component: <Language />,
  },
  {
    id: 6,
    label: "My Password",
    path: "passwords",
    profileID: 0,
    component: <MyPasswords />,
  },
  {
    id: 7,
    label: "My Timesheet",
    path: "timesheet",
    profileID: 0,
    component: <MyTimeSheet />,
  },
  {
    id: 8,
    label: "My Claim",
    path: "myclaim",
    profileID: 0,
    component: <MyClaim />,
  },
  {
    id: 9,
    label: "Daily Report",
    path: "daily-report",
    profileID: 0,
    component: <DailyReport />,
  },
  {
    id: 11,
    label: "Daily Report Member",
    path: "member-daily-report",
    profileID: 0,
    component: <DailyReportMemberApplication />,
  },
  {
    id: 12,
    label: "Daily Report Member",
    path: "my-refill-report",
    profileID: 0,
    component: <MyRefillReport />,
  },
  {
    id: 10,
    label: "My Projects",
    path: "projects",
    profileID: 0,
    component: <MyProjects />,
  },
  {
    id: 1,
    label: "Basic Info",
    path: "basicinfo",
    profileID: 0,
    component: <PersonalDetails />,
  },
  {
    id: 13,
    label: "NoticeBoards",
    path: "notice_board",
    profileID: 0,
    component: <NoticeBoard />,
  },
  
];
export const empRoutes = [
  {
    id: 2,
    label: "Calendar",
    path: "",
    profileID: 0,
    component: <UserInfo />,
  },
  // { id: 2, label: "Calendar", path: "calendar", component: <DashboardCalendarView/> },
  {
    id: 3,
    label: "My Leave",
    path: "leave",
    profileID: 0,
    component: <EmployeeLeave />,
  },
  {
    id: 4,
    label: "Documents",
    path: "documents",
    profileID: 0,
    component: <Documents />,
  },
  // {
  //   id: 5,
  //   label: "Employee Evaluations",
  //   path: "evaluations", profileID: 0,
  //   // component: <Evaluations/>,
  //   component: <></>,
  // },
  // {
  //   id: 5,
  //   label: "",
  //   path: "evaluations-detail", profileID: 0,
  //   // component: <EvaluationDetail/>,
  //   component: <></>,
  // },
  {
    id: 6,
    label: "My Password",
    path: "passwords",
    profileID: 0,
    component: <MyPasswords />,
  },
  {
    id: 7,
    label: "My Timesheet",
    path: "timesheet",
    profileID: 0,
    component: <MyTimeSheet />,
  },
  {
    id: 8,
    label: "My Claim",
    path: "myclaim",
    profileID: 0,
    component: <MyClaim />,
  },
  {
    id: 1,
    label: "Basic Info",
    path: "basicinfo",
    profileID: 0,
    component: <PersonalDetails />,
  },
];
export const UserDataContext = createContext({});
const UserRoute = (props: any): ReactElement => {
  const data = props.loginData;  
  return (
    <UserDataContext.Provider value={data}>
    <UserProfile userData={data}  {...props}>
      <Outlet />      
    </UserProfile>
    </UserDataContext.Provider>
  );
};
export default UserRoute;
