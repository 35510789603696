import { baseObj } from "../../utils/constants";
import { redirectToLogin } from "./annoucement-sheet";
import { api, endpointUrl, getLanguageCode } from "./api";
import authHeader from "./auth-header";
import { DocumentTableData, documentProp, getAuthUser } from "./dashboard";
import { Password, Pwd } from "./password";
import { TDataList } from "./profile-daily-popup";
export type Profile = {
  id: number;
  name: string;
  preferredName: string;
  employeeID: string;
  nationality: string;
  dob: string;
  nationalityID: string;
  passportNo: string;
  gender: string;
  maritalStatus: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  mobile: string;
  workPhone: string;
  email: string;
  workEmail: string;
  emergencyContactName: string;
  relationship: string;
  emergencyContactPhone: string;
  user?: IUser;
  employee?: Profile;
};

export type ProfileData = {
  user: IUser;
  timeSheets: IUserTimeSheet[];
  goalData: any;
  clockStatus: string;
  todayEvents?: IEventList[]
};
export type ProfileDataInfo = {
  employee:ProfileDataInfoEmp
 
}
export type ProfileDataInfoEmp={
  "uniqeID": number,
  "employeeID":string,
  "name": string,
  "workEmail": string,
  "mobile": string,
  "jobTitle": string,
  "joinedDate": string,
  "department": any,
  "gender": string,
  "status": string,
}
export type myProfileData = {
  employee: Profile;
};

export interface IUserTimeSheet {
  id: number;
  employee_id: number;
  employeeName: string;
  checkIn: string;
  checkOut: string;
  hour: number;
  latitude: string;
  longitude: string;
}

export interface IUser {
  id: number;
  name: string;
  email: string;
  enable: boolean;
  employee_id: number;
  roles: baseObj[];
  createdBy: baseObj;
  updatedAt: string;
}

export interface ProfileInfo {
  id: number;
  name: string;
  position: string;
  totalHours: string;
}
export interface IEventList {
  "uniqueID": number,
  "title": string,
  "date": string,
  "start": string,
  "end": string,
  "note": string,
  "eventType": string,
  "eventTypeDescription": string,
  "eventTarget": string,
  "targetId": number,
  "eventNote": boolean,
  "fullHalfDay": null,
  "description": string,
  "show_note": boolean
}
export interface ProfileInfoData {
  timeSheets: any;
  user: any;
  clockStatus: string;
  totalHours: string;
  announcements: IAnnoucement;
  activeStatus: boolean;
  designationData: any;
  unreadCount?: any;
  todayEvents?: IEventList[];
  weather: IWeatherData[],
  departments: IDepartment[];
  dataForDailyReportForm:TDataList
}
export interface IDepartment {
  id: number,
  name: string
}
export interface IAnnoucement {
  "id": number,
  "title": string;
  "content": string;
  "postedAt": string;
  "tags": string[];
  "type": string;
  "viewers": [],
  "is_read": boolean
}
export interface IWeatherData {
  "id": number,
  "country_id": number,
  "name": string
  "description": string,
  "latitude": string,
  "longitude": string,
  "status": number,
  "weather": {
    "icon": string,
    "temp": number,
    "temp_max": number,
    "temp_min": number,
    "description": "few clouds"
  },
  "last_weather_updated": string,
  "created_at": string,
  "updated_at": string,
  "deleted_at": null,
  "is_default": number
}
export interface MyGoal {
  title: string;
  content: string;
  id?: number;
}

export interface ICheckInOut {
  latitude: any;
  longitude: any;
}

export type MyAuthLeaveResponse = {
  leaveData: IMyLeaves;
};
export type LeaveDataWithPaginationData = {
  leaveData: LeaveDataWithPagination;
  statusData: string[];
};
export type LeaveDataWithPagination = {
  current_page: number;
  from: number;
  to: number;
  total: number;
  per_page: number;
  data: MyAuthLeaveResponse;
  first_page_url: string;
  last_page_url: string;
  next_page_url: string;
  statusData: string[];
};
export interface IMyLeaves {
  id: number;
  appliedAt: string;
  employeeName: string;
  leaveType: string;
  from: string;
  to: string;
  fullHalfDay: string;
  total: number;
  reason: string;
  status: string;
  remark: string;
  end?: any;
  start?: any;
  editOption?: boolean;
  cancelOption?: boolean;
}
export interface ILeaveFilter {
  end?: any;
  start?: any;
  status: string;
  limit: number;
  page: number;
  id: number;
  sort?: string;
}

export type MyAuthLeaveBalanceResponse = {
  leavesInfo: IMyLeaveBalance;
  previousBalance: previousBalance;
  leaveTypes: IMyLeaveType[];
  statusData:string[];
  leaveFullHalf:string[];
  canGetLeave:boolean;
};

export interface previousBalance {
  available: number;
  expiredDate: string;
}
export interface IMyLeaveBalance {
  leave_type: string;
  description: string;
  total_allowance: number;
  actual_allowance: number;
  leave_taken: number;
  leave_balance: number;
}

export type MyAuthLeaveTypeResponse = {
  leaveTypes: IMyLeaveType[];
};

export type IMyLeaveType = {
  id: number;
  name: string;
  flag: string;
  description: string;
  available: number;
  created_at: string;
  updated_at: string;
  deleted_at: null;
};

export type myPwdsResponse = {
  data: Pwd[];
  current_page: number;
  from: number;
  to: number;
  total: number;
  per_page: number;
  first_page_url: string;
  last_page_url: string;
  next_page_url: string;
};

export type MyPwdResponse = {
  passwords: myPwdsResponse;
};

export type MyPwdRequest = {
  globalFilter?: string;
  id: number | 0;
  limit: number;
  page: number;
  sort?: string;
  project_id:string;
};

export interface IGetLeave {
  id: number;
}


export const profileApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProfile: build.query<ProfileData, number>({
      keepUnusedDataFor: 0,
      query: (id) => {
        var userID = getAuthUser()?.user?.employee_id;
        // console.log("id cur",id)
        return {
          url: id != 0 ? `profile/${id}` : `profile`,
          headers: authHeader(),
        };
      },
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: (user) => [{ type: "ProfileList" as const, id: "LIST" }],
    }),
    getProfileInfoData: build.query<ProfileDataInfo, void>({
      keepUnusedDataFor: 0,
      query: () => {
        return {
          url: `${getLanguageCode()}employeeprofile`,
          headers: authHeader(),
        };
      },
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: (user) => [{ type: "ProfileList" as const, id: "LIST" }],
    }),
    getMyInfo: build.query<myProfileData, void>({
      query: () => ({
        url: `${endpointUrl}profile/info`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: (myProfile) => [
        { type: "MyProfileList" as const, id: "LIST" },
      ],
    }),
    getProfileByCondition: build.query<ProfileData, number>({
      query: (id) => ({
        url: id != 0 ? `profile/${id}` : `profile`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: (user) => [{ type: "ProfileList" as const, id: "LIST" }],
    }),
    updateMyInfo: build.mutation<Profile, Partial<Profile>>({
      query: (body) => ({
        url: `${endpointUrl}profile/info`,
        method: "PUT",
        body,
        headers: authHeader(),
      }),
      invalidatesTags: [{ type: "MyProfileList", id: "LIST" }],
    }),

    createMyGoal: build.mutation<MyGoal, Partial<MyGoal>>({
      query: (body) => {
        return {
          url: `${endpointUrl}profile/goals`,
          method: "POST",
          body,
          headers: authHeader(),
        };
      },
      invalidatesTags: (goal) => [{ type: "MyGoal", id: goal?.id }],
    }),

    createCheckInOut: build.mutation<ICheckInOut, ICheckInOut>({
      query: (body) => {
        return {
          url: `${endpointUrl}profile/check-in-out`,
          method: "POST",
          body,
          headers: authHeader(),
        };
      },
      // invalidatesTags: (check) => [{ type: "ProfileList" }],
    }),

    getMyAuthLeave: build.query<
      LeaveDataWithPaginationData,
      Partial<ILeaveFilter>
    >({
      query: ({ status, start, end, id, page, limit, sort }) => {
        return {
          // queryKey: ['MyAuthLeaves', status],
          url: id
            ? `${endpointUrl}profile/${id}/leaves?status=${status}&page=${page}&limit=${limit}&start=${start}&end=${end}&sort=${sort}`
            : `${endpointUrl}profile/leave-taken?status=${status}&start=${start}&end=${end}&page=${page}&limit=${limit}&sort=${sort}`,
          headers: authHeader(),
        };
      },
      keepUnusedDataFor: 0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: (myLeaves) => [
        { type: "MyAuthLeaves" as const, id: "LIST" },
      ],
    }),

    createCancelLeave: build.mutation<any, any>({
      query: (id) => {
        return {
          url: `${endpointUrl}leaves/${id}/cancel`,
          method: "POST",
          // body,
          headers: authHeader(),
        };
      },
      invalidatesTags: (check) => [{ type: "MyAuthLeaveBalance" }, { type: "MyAuthLeaves" }],
    }),

    getMyAuthLeaveBalance: build.query<MyAuthLeaveBalanceResponse, number>({
      query: (id) => ({
        url: id
          ? `${endpointUrl}profile/${id}/leaveBalances`
          : `${endpointUrl}profile/leave-information`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: (myLeaves) => [
        { type: "MyAuthLeaveBalance" as const, id: "LIST" },
      ],
    }),

    getMyAuthLeaveAll: build.mutation<MyAuthLeaveBalanceResponse, Partial<IGetLeave>>({
      query: ({ id }) => {
        return {
          url: `${id}` ? `${endpointUrl}profile/${id}/leaveBalances` : `${endpointUrl}profile/leave-information`,
          method: "GET",
          headers: authHeader(),
        };
      },
      invalidatesTags: (check) => [{ type: "MyAuthLeaveBalance" }],
    }),


    getMyAuthLeaveTypes: build.query<MyAuthLeaveTypeResponse, void>({
      query: () => ({
        url: `${endpointUrl}leaveTypes`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: (leaveTypes) => [
        { type: "MyAuthLeaveTypes" as const, id: "LIST" },
      ],
    }),

    createLeaveData: build.mutation<any, any>({
      query: (body) => {
        return {
          url: `${endpointUrl}leaves`,
          method: "POST",
          body,
          headers: authHeader(),
        };
      },
      invalidatesTags: (check) => [{ type: "MyAuthLeaves" }, { type: "MyAuthLeaveBalance" }],
    }),

    getProfileData: build.query<Profile, number>({
      // query: (id) => ({ url: ((id && id != 0) ? `/profile/${id}` : `/profile`), headers: authHeader() }),
      query: (id) => {
        return {
          url: id && id != 0 ? `employeeInfo/${id}` : `profile/info`,
          headers: authHeader(),
        };
      },
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      // transformResponse: (res: Profile) => {
      //   return res;//res.user to response
      // },
      providesTags: (user) => [
        { type: "ProfileListData" as const, id: "LIST" },
      ],
    }),

    getProfileInfo: build.query<ProfileInfoData, number>({
      keepUnusedDataFor: 0,
      query: (id) => {
        // console.log("getProfileInfo ",id)
        return {
          url: id != 0 ? `/profile/${id}` : `/profile`,
          headers: authHeader(),
        };
      },
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      // query: (id) => ({ url: (id != 0 ? `/profile/${id}` : `/profile/info`), headers: authHeader() }),
      transformResponse: (res: any) => {
        var loginData = getAuthUser();
        if (loginData?.user?.id === res?.user?.id) {
          loginData.user.photo = res?.user?.photo;
        }
        localStorage.setItem("userData", JSON.stringify(loginData));
        return res;
      },
      providesTags: (user) => [{ type: "ProfileList" as const, id: "LIST" }],
    }),

    updateProfile: build.mutation<Profile, Partial<Profile>>({
      query: (body) => ({
        url: `/profile/${body.id}`,
        method: "PUT",
        body,
        headers: authHeader(),
      }),
    }),

    getProfilePasswords: build.query<MyPwdResponse, void>({
      query: () => ({
        url: `${endpointUrl}myPasswords`,
        headers: authHeader(),
      }),
      keepUnusedDataFor: 0,
      transformResponse: (res: any) => {
        return res;
      },
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: (result: any = []) => {
        if (result.length > 0) {
          return [
            ...result?.passwords?.map(
              (id: any) => ({ type: "MyPasswordList", id } as const)
            ),
            { type: "MyPasswordList" as const, id: "LIST" },
          ];
        } else {
          return [{ type: "MyPasswordList" as const, id: "LIST" }];
        }
      },
    }),
    getEmployeeProfilePasswords: build.query<
      MyPwdResponse,
      Partial<MyPwdRequest>
    >({
      query: ({ id, globalFilter, limit, page, sort,project_id }) => (
        {
          url:
            id !== 0
              ? `${endpointUrl}users/${id}/passwords`
              : `${endpointUrl}profile/passwords?search=${globalFilter}&limit=${limit}&page=${page}&sort=${sort}&project_id=${project_id}`,
          headers: authHeader(),
        }),
      keepUnusedDataFor: 0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: any) => {
        return res;
      },

      providesTags: (result: any = []) => {
        if (result?.passwords?.data.length > 0) {
          return [
            ...result?.passwords?.data?.map(
              (id: any) => ({ type: "MyPasswordList", id } as const)
            ),
            { type: "MyPasswordList" as const, id: "LIST" },
          ];
        } else {
          return [{ type: "MyPasswordList" as const, id: "LIST" }];
        }
      },
    }),

    getMyPasswordProfile: build.mutation<MyPwdResponse, Partial<MyPwdRequest>>({
      query: ({ id, limit }) => ({
        url:
          id !== 0
            ? `${endpointUrl}users/${id}/passwords`
            : `${endpointUrl}profile/passwords?limit=${limit}`,
        headers: authHeader(),
        method: "GET",
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: any) => {
        return res;
      },
      invalidatesTags: [{ type: 'MyPasswordList', id: "LIST" }],
    }),

    getProfileDocuments: build.query<DocumentTableData, documentProp>({
      query: ({ id, start, end, search, limit, type, tag, sort, page }) => {
        return {
          url:
            id && id != 0
              ? `profile/${id}/documents?start=${start ? start : ""}&end=${end ? end : ""
              }&limit=${limit}&search=${search}&type=${type}&tag=${tag}&sort=${sort}&page=${page}`
              : `profile/documents?start=${start ? start : ""}&end=${end ? end : ""
              }&limit=${limit}&search=${search}&type=${type}&tag=${tag}&sort=${sort}&page=${page}`,
          headers: authHeader(),
        };
      },
      keepUnusedDataFor: 0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: any) => {
        return res;
      },
      providesTags: (result: any = []) => {
        if (result.length > 0) {
          return [
            ...result?.documents?.data?.map(
              (id: any) => ({ type: "Documents", id } as const)
            ),
            { type: "Documents" as const, id: "LIST" },
          ];
        } else {
          return [{ type: "Documents" as const, id: "LIST" }];
        }
      },
    }),
    getWeatherData: build.query<any, void>({
      query: () => ({
        url: `${endpointUrl}weather`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
  }),
});

export const {
  useGetProfileQuery,
  useGetMyInfoQuery,
  useUpdateMyInfoMutation,
  useCreateMyGoalMutation,
  useCreateCheckInOutMutation,
  useGetProfileDataQuery,
  useGetProfileInfoQuery,
  useUpdateProfileMutation,
  useGetMyAuthLeaveQuery,
  useCreateCancelLeaveMutation,
  useGetMyAuthLeaveAllMutation,
  useGetMyAuthLeaveBalanceQuery,
  useGetMyAuthLeaveTypesQuery,
  useCreateLeaveDataMutation,
  useGetProfilePasswordsQuery,

  useGetEmployeeProfilePasswordsQuery,
  useGetMyPasswordProfileMutation,
  useGetProfileDocumentsQuery,
  useGetWeatherDataQuery,
  useGetProfileByConditionQuery,
  useGetProfileInfoDataQuery
} = profileApi;

export const {
  endpoints: {
    getProfile,
    getMyInfo,
    getProfileData,
    getProfileInfo,
    updateProfile,
    updateMyInfo,
    createMyGoal,
    createCheckInOut,
    getMyAuthLeave,
    createCancelLeave,
    getMyAuthLeaveBalance,
    getMyAuthLeaveAll,
    getMyAuthLeaveTypes,
    createLeaveData,
    getProfilePasswords,
    getEmployeeProfilePasswords,
    getProfileDocuments,
    getMyPasswordProfile,
    getWeatherData,
    getProfileByCondition,
    getProfileInfoData

  },
} = profileApi;
