import { redirectToLogin } from "./annoucement-sheet";
import { api, getLanguageCode, getLanguageCodeForbackend } from "./api";
import authHeader from "./auth-header";
export type DropdownDataWithLanguage = {
  id: number;
  name_en: string;
  name_tc: string;
  name_sc: string;
};
type FaqResponse = {
  module_id: number;
  status: boolean;
  cms: TData;
};

export type TData = {
  data: DepartmentInfo[];
  total: number;
};
export type DepartmentInfo = {
  id?: number;
  name: string;
};
export type LogoData = {
  logo: string;
  banner: string;
};

export const cmsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCMSData: build.query<FaqResponse, string>({
      query: (url) => ({
        url: `${getLanguageCodeForbackend()}cmspages/${url}`,
        headers: authHeader(),
      }),
      keepUnusedDataFor: 0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    getLogoData: build.query<LogoData, void>({
      query: (url) => ({
        url: `${getLanguageCodeForbackend()}mobilelogo`,
        headers: authHeader(),
      }),
      keepUnusedDataFor: 0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
  }),
});

export const { useGetCMSDataQuery, useGetLogoDataQuery } = cmsApi;

export const {
  endpoints: { getCMSData, getLogoData },
} = cmsApi;
