export default function authHeader() {
  const userStr = localStorage.getItem("userData");
  let user = null;
  if (userStr)
    user = JSON.parse(userStr);
  if (user && user.accessToken) {
    // document.cookie = `document_session=${user.accessToken};domain="vorpapidev.visibleone.io"`;
    return {
      'Authorization': `Bearer ${user.accessToken}`,
      'Accept': `application/json`,
      'Content-Type': `application/json`,
      // 'Cookie': `document_session=${user.accessToken}`,
      'type': 'application/octet-stream',
    }
  } else {
    return { 'Authorization': '' };
  }
}


