import { redirectToLogin } from "./annoucement-sheet";
import { api, getLanguageCodeForbackend } from "./api";
import authHeader from "./auth-header";
export type DropdownDataWithLanguage = {
  id: number;
  name_en: string;
  name_tc: string;
  name_sc: string;
};
type DepartmentsResponse = {
  module_id: number;
  status: boolean;
  categoryTableData: TData;
};

export type TData = {
  data: DepartmentInfo[];
  total: number;
};
export type DepartmentInfo = {
  id?: number;
  name: string;
};
type departmentInput = {
  // sort: string;
  // search: string;
};
export type TaskCategoriesList = {
  categoryList: TaskDepartmentListData[];
};
export type TaskDepartmentListData = {
  id: number;
  name: string;
};
export const generateYear = () => {
  const currentYear = new Date().getFullYear();
  const range = (start:number, stop:number, step:number) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );
  return range(currentYear, currentYear - 5, -1);
};
export const categoryApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCategoryList: build.query<DepartmentsResponse, departmentInput>({
      query: () => ({
        url: `categories`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    getCategoryAllList: build.query<TaskCategoriesList, void>({
      query: () => ({
        url: `${getLanguageCodeForbackend()}categoryList`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    createCategory: build.mutation<
      TaskCategoriesList,
      DropdownDataWithLanguage
    >({
      query(body) {
        return {
          headers: authHeader(),
          url: `categories`,
          body,
          method: "POST",
        };
      },
    }),
    updateCategory: build.mutation<
      TaskCategoriesList,
      DropdownDataWithLanguage
    >({
      query(body) {
        return {
          headers: authHeader(),
          url: `categories/${body.id}`,
          body,
          method: "PUT",
        };
      },
      // invalidatesTags: (employees) => {
      //   return [{ type: "Departments", id: "LIST" }];
      // },
    }),
    deleteCategory: build.mutation<TaskCategoriesList, number>({
      query(id) {
        return {
          headers: authHeader(),
          url: `/categories/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (employees) => {
        return [{ type: "Category", id: "LIST" }];
      },
    }),
  }),
});

export const {
  useGetCategoryAllListQuery,
  useGetCategoryListQuery,
  useUpdateCategoryMutation,
  useCreateCategoryMutation,
  useDeleteCategoryMutation,
} = categoryApi;

export const {
  endpoints: {
    getCategoryList,
    getCategoryAllList,
    createCategory,
    updateCategory,
    deleteCategory,
  },
} = categoryApi;
